import React from "react";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const RegistryList = ({ hotweeks, grouped, region }) => {
  function convertImgUrl(imgurl) {
    return imgurl.replace(
      "https://www.rci.com/static/Resorts/Assets",
      "/compass/resorts"
    );
  }
  if (hotweeks.length === 0) {
    return null;
  } else {
    // eslint-disable-next-line
    return grouped.map(locations => {
      for (let [, value] of Object.entries(locations)) {
        const hotweeks = value.map(registry => {
          return (
            <CSSTransition key={registry._id} timeout={500} classNames="fade">
              <div
                key={registry._id}
                className="media media-list-view-resorts media-border flex-column flex-md-row align-items-stretch mb-5 shadow-lg"
              >
                <div
                  className="media-img position-relative animated
              zoomIn"
                >
                  <img
                    width="75"
                    height="75"
                    className="img-fluid position-relative"
                    src={registry.img[0] && convertImgUrl(registry.img[0])}
                    alt="gallery-img"
                  ></img>
                  <Link
                    className="media-img-overlay"
                    data-fancybox="gallery"
                    //to={`/registry/resort/${registry._id}/?${qString}`}
                    to={`/registry/resort/${registry._id}?region=${region}`}
                  ></Link>
                </div>

                <div
                  className="media-body animated
              fadeIn"
                >
                  <div className="row">
                    <div className="col-md-7 col-xl-8">
                      <h4>
                        <Link
                          className="media-title"
                          to={`/registry/resort/${registry._id}?region=${region}`}
                        >
                          {registry.resortName}
                        </Link>
                      </h4>
                      <p className="mb-md-0">{`${
                        registry.area.region !== null
                          ? registry.area.region
                          : registry.area.district !== null
                          ? registry.area.district
                          : registry.area.country
                      }, ${registry.area.country}`}</p>

                      <div className="date-view"></div>
                    </div>

                    <div className="col-md-5 col-xl-4">
                      <div className="bg-primary text-center p-3 view-details">
                        <p className="text-white text-capitalize">7 Nights</p>
                        <h2 className="text-white font-weight-bold h1">
                          $699
                          {/*registry.availableUnits.length*/}
                        </h2>

                        <div>
                          <Link
                            to={`/registry/resort/${registry._id}?region=${region}`}
                            className="btn btn-xs btn-block btn-outline-white btn-booking text-uppercase mb-2"
                          >
                            Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          );
        });
        return (
          <React.Fragment>
            <TransitionGroup>
              <center>
                <h2 className="mb-5">{}</h2>
              </center>
              {hotweeks}
            </TransitionGroup>
          </React.Fragment>
        );
      }
    });
  }
};

export default RegistryList;
