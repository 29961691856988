import React from "react";
import { Card, CardBody } from "reactstrap";

import { Link } from "react-router-dom";

const InventoryRequestSent = ({ records, requestSent }) => {
  return (
    <React.Fragment>
      {records === 0 && requestSent === true && (
        <center>
          <div className="col-4">
            <Card className="shadow mb-5 mb-lg-0">
              <CardBody>
                <h5 className="card-title">Request Submited</h5>
                <p className="card-text">
                  A travel advocate will begin working on this immediately. You
                  can view the progress of your requests from your dashboard or
                  click the link below.{" "}
                </p>
                <p>
                  If you would like to continue searching, please modify your
                  search options above.
                </p>
              </CardBody>

              <CardBody className="text-center">
                <Link className="card-link" to="/dashboard">
                  Dashboard
                </Link>
              </CardBody>
            </Card>
          </div>
        </center>
      )}
    </React.Fragment>
  );
};

export default InventoryRequestSent;
