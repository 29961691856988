import React, { useRef, useState, useEffect } from "react";
import { getLogin, putLogin } from "../services/adminService";

const AdminSettingsApiSaver = ({ title, source }) => {
  const usernameRef = useRef(null);
  const [username, setUsername] = useState();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    getLogin(source)
      .then(result => {
        setUsername(result.username);
        //setPassword(result.password);
        return username;
      })
      .then(un => {
        console.log(`${un} Loaded`);
      });
    // eslint-disable-next-line
  }, [source]);

  const handleSubmit = event => {
    event.preventDefault();
    const newUsername = usernameRef.current.value;

    if (newUsername !== "") {
      console.log(newUsername);
      putLogin(source, { username: newUsername, password: "" }).then(() => {
        setUpdated(true);
      });
    }
  };

  return (
    <React.Fragment>
      <div className="mb-4">
        <p className="text-primary mb-0">
          {title}{" "}
          {updated === true && (
            <span className="badge badge-success">Updated</span>
          )}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-4">
          <input
            ref={usernameRef}
            type="text"
            className="form-control form-control-lg rounded border-0 shadow-lg"
            required=""
            placeholder={username}
            readOnly={updated}
          ></input>
        </div>

        <div className="pt-2">
          {updated === false && (
            <button
              type="submit"
              className="btn btn-primary text-uppercase font-size-15 px-6 px-md-7 shadow-lg"
            >
              Update API Key
            </button>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

export default AdminSettingsApiSaver;
