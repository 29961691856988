/* eslint-disable react/no-unused-prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import momentPropTypes from "react-moment-proptypes";
import { forbidExtraProps } from "airbnb-prop-types";
import omit from "lodash/omit";
import {
  DayPickerRangeController,
  ScrollableOrientationShape
} from "react-dates";

const START_DATE = "startDate";
const END_DATE = "endDate";
const HORIZONTAL_ORIENTATION = "horizontal";

const propTypes = forbidExtraProps({
  // Custom Prop Functions
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  // Main props
  autoFocusEndDate: PropTypes.bool,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,
  startDateOffset: PropTypes.func,
  endDateOffset: PropTypes.func,
  showInputs: PropTypes.bool,
  minDate: momentPropTypes.momentObj,
  maxDate: momentPropTypes.momentObj,

  keepOpenOnDateSelect: PropTypes.bool,
  minimumNights: PropTypes.number,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  isDayHighlighted: PropTypes.func,

  // DayPicker props
  enableOutsideDays: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  orientation: ScrollableOrientationShape,
  verticalHeight: PropTypes.number,
  withPortal: PropTypes.bool,
  initialVisibleMonth: PropTypes.func,
  renderCalendarInfo: PropTypes.func,
  renderMonthElement: PropTypes.func,
  renderMonthText: PropTypes.func,

  navPrev: PropTypes.node,
  navNext: PropTypes.node,
  renderNavPrevButton: PropTypes.func,
  renderNavNextButton: PropTypes.func,

  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  renderCalendarDay: PropTypes.func,
  renderDayContents: PropTypes.func,
  renderKeyboardShortcutsButton: PropTypes.func,
  renderKeyboardShortcutsPanel: PropTypes.func,

  // i18n
  monthFormat: PropTypes.string,

  isRTL: PropTypes.bool
});

const defaultProps = {
  // example props for the demo
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  startDateOffset: undefined,
  endDateOffset: undefined,
  showInputs: false,
  minDate: null,
  maxDate: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  isDayBlocked: () => false,
  //isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: true,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,
  renderMonthText: null,
  renderMonthElement: null,
  renderKeyboardShortcutsButton: undefined,
  renderKeyboardShortcutsPanel: undefined,

  // navigation related props
  navPrev: null,
  navNext: null,
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: "MMMM YYYY"
};

const DayPickerRangeControllerWrapper = props => {
  const [focusedInput, setFocusedInput] = useState(
    props.autoFocusEndDate ? END_DATE : START_DATE
  );
  const [sDate, sSDate] = useState(props.initialStartDate);
  const [eDate, sEDate] = useState(props.initialEndDate);

  function onDatesChange({ startDate, endDate }) {
    props.setStartDate(moment(startDate).format());
    props.setEndDate(moment(endDate).format());
    sSDate(startDate);
    sEDate(endDate);
    //this.setState({ startDate, endDate });
  }
  function onFocusChange(focusedInput) {
    // Force the focusedInput to always be truthy so that dates are always selectable
    setFocusedInput(!focusedInput ? START_DATE : focusedInput);
  }
  const { showInputs } = props;

  const oprops = omit(props, [
    "autoFocus",
    "autoFocusEndDate",
    "initialStartDate",
    "initialEndDate",
    "showInputs"
  ]);
  const startDateString = sDate && sDate.format("MM-DD-YYYY");
  const endDateString = eDate && eDate.format("MM-DD-YYYY");
  return (
    <div style={{ height: "100%" }}>
      {showInputs && (
        <div className="row mb-4 mt-4">
          <form>
            <div className="form-row">
              <div className="col-sm-5">
                <input
                  type="text"
                  name="start date"
                  value={startDateString}
                  className="form-control"
                  readOnly
                />
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-5">
                <input
                  type="text"
                  name="end date"
                  value={endDateString}
                  className="form-control"
                  readOnly
                />
              </div>
            </div>
          </form>
        </div>
      )}

      <DayPickerRangeController
        {...oprops}
        onDatesChange={onDatesChange}
        onFocusChange={onFocusChange}
        focusedInput={focusedInput}
        startDate={sDate}
        endDate={eDate}
      />
    </div>
  );
};
DayPickerRangeControllerWrapper.propTypes = propTypes;
DayPickerRangeControllerWrapper.defaultProps = defaultProps;
export default DayPickerRangeControllerWrapper;
