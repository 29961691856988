import React, { useState, useEffect } from "react";
import RegistryList from "../components/RegistryList";
import RegistryPageAd from "./RegistryPageAd";
import { Transition } from "react-transition-group";

const OurDestinations = ({ hotweeks, grouped, qString, onHotLink, region }) => {
  const [activeElement, setActiveElement] = useState("menu");
  const [menuOn, setMenuOn] = useState(true);
  const [listOn, setListOn] = useState(false);

  useEffect(() => {
    if (hotweeks.length > 0) {
      setMenuOn(false);
    } else {
      setListOn(false);
    }
  }, [hotweeks]);

  useEffect(() => {
    if (activeElement === "list") {
      setMenuOn(true);
    } else if (activeElement === "menu") {
      setListOn(true);
    }
  }, [activeElement]);

  return (
    <section className="py-8 py-lg-10 ">
      <div className="container ">
        <div className="text-center section-title">
          <h2 className="text-uppercase font-weight-bold position-relative">
            <span className="bg-white">Registry Collection</span>
          </h2>
          <p className="text-capitalize text-center">
            Prime Resorts in Prime Locations durring Prime Season
          </p>
        </div>
        {activeElement === "menu" && (
          <Transition
            in={menuOn}
            onExited={() => setActiveElement("list")}
            timeout={150}
          >
            {state => (
              <RegistryPageAd
                className={` fade-${state}`}
                dispatch={e => console.log(e)}
                onHotLink={onHotLink}
              />
            )}
          </Transition>
        )}

        {activeElement === "list" && (
          <Transition
            in={listOn}
            onExited={() => setActiveElement("menu")}
            timeout={150}
          >
            {state => (
              <RegistryList
                className={` fade-${state}`}
                hotweeks={hotweeks}
                grouped={grouped}
                qString={qString}
                region={region}
              />
            )}
          </Transition>
        )}
      </div>
    </section>
  );
};

export default OurDestinations;
