import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { pageLogger } from "../services/logService";
import UserHeader from "./UserHeader";
import UserProfile from "../views/UserProfile";
import Dashboard from "../views/Dashboard";
//import UserBooking from "../views/UserBooking";
import UserSettings from "../views/UserSettings";
import UserBookingMain from "../adminComponents/UserBookingDash/UserBookingMain";
import MailBox from "../userComponents/MailBox";

const UserDashboard = ({
  notifyMenu,
  handleNotifyMenu,
  userMenu,
  handleUserMenu,
  handleDropDownMenu,
  setBookingType,
  bookingType
}) => {
  const { pathname: path } = useLocation();
  //User Tracking
  pageLogger(path);
  return (
    <React.Fragment>
      <section className="py-2 mb-8"></section>
      <section className="py-2">
        <UserHeader
          notifyMenu={notifyMenu}
          handleNotifyMenu={handleNotifyMenu}
          userMenu={userMenu}
          handleUserMenu={handleUserMenu}
          handleDropDownMenu={handleDropDownMenu}
          classNames="mt-4"
        />
        <div className="container">
          <Switch>
            <Switch>
              <Route path={`${"/dashboard"}/settings`}>
                <UserSettings className="fade-in" />
              </Route>
              <Route path={`${"/dashboard"}/mail`}>
                <MailBox />
              </Route>
              <Route path={`${"/dashboard"}/booking`}>
                <UserBookingMain
                  setBookingType={setBookingType}
                  bookingType={bookingType}
                  className="fade-in"
                />
              </Route>
              <Route path={`${"/dashboard"}/profile`}>
                <UserProfile className="fade-in" />
              </Route>
              <Route path={"/dashboard"}>
                <Dashboard className="fade-in" />
              </Route>
            </Switch>
          </Switch>
        </div>
      </section>
    </React.Fragment>
  );
};

export default UserDashboard;
