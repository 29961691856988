import React, { useReducer } from "react";
import TravelCenterPlatform from "../components/TravelCenterPlatform";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import getTcPlatforms from "../dev/travelCenterData";
import PageTitle from "../components/PageTitle";

function init(initialCount) {
  return { filtered: "*", selected: getTcPlatforms() };
}

function reducer(state, action) {
  let items = getTcPlatforms();
  switch (action.type) {
    case "*":
      return { filtered: "*", selected: items };
    case "activities":
      return {
        filtered: "activities",
        selected: items.filter(select => select.filter === "activities")
      };
    case "lodging":
      return {
        filtered: "lodging",
        selected: items.filter(select => select.filter === "lodging")
      };
    case "other":
      return {
        filtered: "other",
        selected: items.filter(select => select.filter === "other")
      };
    case "reset":
      return { filtered: "reset", selected: items };
    default:
      throw new Error();
  }
}

const TravelCenter = props => {
  const [state, dispatch] = useReducer(reducer, "*", init);

  return (
    <div className="main-wrapper hotweeks fade-in">
      <PageTitle
        title="Travel Center"
        titleImg="assets/img/pages/page-title-bg2.jpg"
      ></PageTitle>
      <section className="py-10">
        <div className="container">
          <div className="text-center section-title">
            <h2 className="text-uppercase font-weight-bold position-relative">
              <span className="bg-white">Select a platform</span>
            </h2>
            <p className="text-capitalize text-center">
              Center your travel around the things you love to do
            </p>
          </div>
          <div id="filters" className="button-group">
            <button
              className={`button ${state.filtered === "*" && "is-checked"}`}
              onClick={() => dispatch({ type: "*" })}
            >
              All Platforms
            </button>
            <button
              className={`button ${state.filtered === "lodging" &&
                "is-checked"}`}
              onClick={() => dispatch({ type: "lodging" })}
            >
              Lodging
            </button>
            <button
              className={`button ${state.filtered === "activities" &&
                "is-checked"}`}
              onClick={() => dispatch({ type: "activities" })}
            >
              Activities
            </button>
            <button
              className={`button ${state.filtered === "other" && "is-checked"}`}
              onClick={() => dispatch({ type: "other" })}
            >
              Other
            </button>
          </div>
          <TransitionGroup className="travel-center-group">
            <div className="row grid">
              {state.selected.map(item => {
                return (
                  <CSSTransition key={item.name} timeout={500} className="fade">
                    <TravelCenterPlatform
                      name={item.name}
                      description={item.description}
                      img={item.img}
                      filter={item.filter}
                      selected={state.filtered}
                      handleClick={() => {
                        window.open(item.link, "travelcenterwindow");
                      }}
                    />
                  </CSSTransition>
                );
              })}
            </div>
          </TransitionGroup>
        </div>
      </section>
    </div>
  );
};

export default TravelCenter;
