import React from "react";

const VipPagination = ({ setPage, page, totalPages, records }) => {
  return (
    <section
      className={`pt-1 pt-md-2 ${records < 1 ? "invisible" : "visable"}`}
    >
      <div
        className="container animated
  fadeIn"
      >
        <nav aria-label="Page navigation">
          <ul className="pagination justify-content-center align-items-center">
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => setPage(page => (page < 2 ? 1 : page - 1))}
              >
                <i
                  className="fa fa-long-arrow-left d-none d-md-inline-block mr-md-1"
                  aria-hidden="true"
                ></i>{" "}
                Previous
              </button>
            </li>

            <li className="page-item">
              <button className="page-link active">{page}</button>
            </li>

            <li className="page-item">
              <button className="page-link">OF</button>
            </li>

            <li className="page-item">
              <button className="page-link" onClick={() => setPage(totalPages)}>
                {totalPages}
              </button>
            </li>

            <li className="page-item">
              <button
                className="page-link"
                onClick={() =>
                  setPage(page => (page < totalPages ? page + 1 : totalPages))
                }
              >
                Next
                <i
                  className="fa fa-long-arrow-right d-none d-md-inline-block ml-md-1"
                  aria-hidden="true"
                ></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default VipPagination;
