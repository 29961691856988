import { db_post } from "./dbService";
import jwtDecode from "jwt-decode";
import moment from "moment";

const tokenKey = "token";

function getCurrentUserForLogging() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const { _id, email } = jwtDecode(jwt);
    return {
      _id,
      email
    };
  } catch (ex) {
    return null;
  }
}

export async function pageLogger(activity) {
  const { _id, email } = getCurrentUserForLogging();
  try {
    return await db_post(`/activity/page`, { _id, email, activity });
  } catch (ex) {}
}

export async function signOutLogger() {
  const { _id, email } = getCurrentUserForLogging();
  try {
    return await db_post(`/activity/logout`, { _id, email });
  } catch (ex) {}
}

export async function getActivity(
  startDate = null,
  endDate = moment().format(),
  page,
  pageSize
) {
  try {
    const result = db_post(`/activity/users`, {
      page,
      pageSize,
      startDate,
      endDate
    });

    return result;
  } catch (ex) {}
}
