import React, { Component } from "react";
import { Container } from "reactstrap";
import moment from "moment";
import _ from "lodash";
import {
  //searchWeeks,
  getResortCountries,
  getResortRegions,
  searchRegistryResorts
} from "../services/serverCallsService";

import RegistrySearch from "../components/RegistrySearch";
import cookie from "react-cookies";
import OurDestinations from "../components/OurDestinations";
import { RegistryProvider } from "../data/RegistryContext";
import queryString from 'query-string'

class Registry extends Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(this.props.location.search)
    this.state = {
      countries: [],
      regions: [],
      resortName: "",
      bedrooms: "",
      destination: values.country ? values.country : "",
      region: values.region ? values.region : "",
      startDate: moment(),
      startFocused: null,
      endDate: moment().add(1, "y"),
      endFocused: null,
      byPriceShow: false,
      byMonthShow: true,
      byFacilityShow: false,
      byLocationShow: false,
      month: "",
      hotweeks: [],
      grouped: [],
      groupedDisplay: [],
      currentPage: 1,
      location: "",
      locationList: [],
      qString: ""
    };
  }

  setStartDate = date => {
    const startDate = date;
    const qString = `search=${cookie.load("search")}`;

    this.setState({ startDate, qString });
    cookie.save("_regst", moment(startDate).format("X"), { path: "/registry" });
  };
  setstartFocused = startFocused => {
    this.setState({ startFocused });
  };
  setEndDate = date => {
    const endDate = date;
    const qString = `search=${cookie.load("search")}`;

    this.setState({ endDate, qString });
    cookie.save("_regen", moment(endDate).format("X"), { path: "/registry" });
  };
  setEndFocused = endFocused => {
    this.setState({ endFocused });
  };

  setBedrooms = e => {
    const bedrooms = e.target.value;
    const qString = `search=${cookie.load("search")}`;

    this.setState({ bedrooms, qString });
    cookie.save("_reguni", bedrooms, { path: "/registry" });
  };
  setRegion = e => {
    const region = e;
    const qString = `search=${cookie.load("search")}`;

    this.setState({ region, qString });
    cookie.save("_regre", region, { path: "/registry" });
  };

  setDestination = async e => {
    const destination = e;
    const region = "";
    const regions = await getResortRegions(destination);

    this.setState({ destination, regions, region });
    //cookie.save("_regdest", destination, { path: "/registry" });
  };

  submitSearch = async () => {
    // const qString = `search=${cookie.load("search")}`;
    const { destination, region } = this.state;

    cookie.save("_lastregsearch", { destination, region });

    const hotweeks = await searchRegistryResorts(
      this.state.destination,
      this.state.region
    );
    //console.log(destination, region);

    const filteredByRegion = hotweeks.filter(resort => {
      return resort.area.region === this.state.region;
    });

    const filtered = this.state.region === "" ? hotweeks : filteredByRegion;

    const groupedObj = _.groupBy(filtered, "area.region");
    let grouped = [];

    for (let [key, value] of Object.entries(groupedObj)) {
      grouped.push({ [key]: value });
    }
    const groupedDisplay = grouped;

    this.setState({ hotweeks, grouped, groupedDisplay });
  };

  handleLocationChange = e => {
    const location = e.target.value;
    const region = "";

    const { hotweeks } = this.state;

    const groupedObj = _.groupBy(hotweeks, "area.country");
    let grouped = [];

    for (let [key, value] of Object.entries(groupedObj)) {
      if (key === location) grouped.push({ [key]: value });
    }
    const groupedDisplay = grouped;
    this.setState({ location, groupedDisplay, region });
  };
  handleSortBy = e => {
    //console.log(e);
  };
  handlePageChange = newPage => {
    if (newPage < 2) newPage = 1;

    this.setState({ currentPage: newPage });
  };

  loadCountries = async () => {
    const countries = await getResortCountries();
    this.setState({ countries });
  };
  loadReigons = async destination => {
    const dest = !destination ? this.state.destination : destination;

    const regions = await getResortRegions(dest);
    this.setState({ regions });
  };

  async componentDidMount() {
    this.loadCountries();
    if (this.props.location.search !== "") {
      this.submitSearch();
    } else {
      if (this.state.destination !== "") this.loadReigons();

      this.submitSearch();
    }
  }
 


  scrollHelper = () => {
    //console.log("scroll");
    //window.scrollTo(0, 300);
  };

  onHotLink = (destination, region) => {
    if (!region) {
      this.setState({ destination }, this.submitSearch);
      this.loadReigons(destination);
      //this.submitSearch();
      //console.log(destination);
    } else {
      this.setState({ destination, region }, this.submitSearch);
      this.loadReigons(destination);
      //this.submitSearch();
      //console.log("has region");
    }
  };

  render() {
    const destinationList = this.state.countries.map(country => {
      return (
        <option key={country} value={country}>
          {country}
        </option>
      );
    });
    const regionList = this.state.regions.map(region => {
      if (region === null)
        return (
          <option key={"other"} value={"other"}>
            {"Other"}
          </option>
        );
      return (
        <option key={region} value={region}>
          {region}
        </option>
      );
    });
    return (
      <RegistryProvider>
        <div className="main-wrapper fade-in">
          <div className="registry">
            <img
              src="/assets/img/registry/registry.jpg"
              className="registry-img"
              alt="Registry"
            ></img>
            <RegistrySearch
              className="shadow-lg"
              id="regSearch1"
              regionList={regionList}
              region={this.state.region}
              setRegion={this.setRegion}
              destinationList={destinationList}
              destination={this.state.destination}
              setDestination={this.setDestination}
              startDate={this.state.startDate}
              setStartDate={this.setStartDate}
              startFocused={this.state.startFocused}
              setstartFocused={this.setstartFocused}
              endDate={this.state.endDate}
              setEndDate={this.setEndDate}
              endFocused={this.state.endFocused}
              setEndFocused={this.setEndFocused}
              bedrooms={this.state.bedrooms}
              setBedrooms={this.setBedrooms}
              submitSearch={this.submitSearch}
              scrollHelper={this.scrollHelper}
            />
          </div>
          <OurDestinations
            hotweeks={this.state.hotweeks}
            grouped={this.state.grouped}
            qString={this.state.qString}
            onHotLink={this.onHotLink}
            region={this.state.region === "" ? false : true}
          />
          <section className="py-0 pt-7 pb-7">
            <Container></Container>
          </section>
        </div>
      </RegistryProvider>
    );
  }
}

export default Registry;
