import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from "reactstrap";
import { register } from "../services/userService";

const NewUserModal = ({ modal, toggle, addHandler, refreshHandler }) => {
  const [name, setName] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("New");
  const [level, setLevel] = useState("Gold");

  function clear() {
    setName("");
    setLast("");
    setEmail("");
    setStatus("New");
    setLevel("Gold");
  }

  async function saveEdit() {
    const user = {
      name,
      last,
      email,
      status,
      level
    };
    toggle();

    try {
      user.password = "TempTemp123$";
      addHandler(user);
      const response = await register(user);
      clear();

      toast.success(`${response.data}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (ex) {
      toast.error(`Error: ${ex.response.data}`, {
        position: toast.POSITION.TOP_CENTER
      });
      console.log(ex.response);
      refreshHandler();
      //window.location("/dashboard/users");
    }
  }

  const handleSubmit = event => {
    event.preventDefault();

    saveEdit();
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={() => toggle()}
        className={"modal-dialog modal-lg modal-dialog-centered"}
      >
        <ModalHeader
          className="modal-title text-uppercase font-weight-bold"
          toggle={() => toggle()}
        >
          Add User
        </ModalHeader>
        <ModalBody>
          <form className="" onSubmit={handleSubmit}>
            <div className="form-row form-group">
              <Col>
                <div className="form-group">
                  <label htmlFor="userName">First Name</label>
                  <input
                    id="userName"
                    value={name}
                    type="text"
                    className="form-control bg-smoke"
                    required=""
                    autoComplete="off"
                    onChange={e => setName(e.target.value)}
                  ></input>
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    id="lastName"
                    value={last}
                    type="text"
                    className="form-control bg-smoke"
                    required=""
                    autoComplete="off"
                    onChange={e => setLast(e.target.value)}
                  ></input>
                </div>
              </Col>
            </div>
            <div className="form-row">
              <Col className="form-group">
                <label htmlFor="userEmail">Email</label>
                <input
                  id="userEmail"
                  value={email}
                  type="text"
                  className="form-control bg-smoke"
                  required=""
                  autoComplete="off"
                  onChange={e => setEmail(e.target.value)}
                ></input>
              </Col>
              <div className="form-group col-md-3">
                <label htmlFor="userLevel">Level</label>
                <select
                  id="userLevel"
                  className="form-control"
                  value={level}
                  onChange={e => setLevel(e.target.value)}
                >
                  <option value="Gold">Gold</option>
                  <option value="Silver">Silver</option>
                  <option value="Sampler">Sampler</option>
                  <option value="Admin">Admin</option>
                  <option value="Agent">Agent</option>
                </select>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="userStatus">Status</label>
                <select
                  id="userStatus"
                  className="form-control"
                  value="New"
                  onChange={e => setStatus("New")}
                >
                  <option value="New">New</option>
                </select>
              </div>
            </div>

            <Row>
              <Col>
                <button className="btn btn-success btn-block text-uppercase">
                  Save
                </button>
              </Col>

              <Col>
                <button
                  onClick={() => toggle()}
                  className="btn btn-danger btn-block text-uppercase"
                >
                  Close
                </button>
              </Col>
            </Row>
          </form>
        </ModalBody>
        <ModalFooter>
          <p className="mb-1">
            <button onClick={() => toggle()}>Close</button>
          </p>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewUserModal;
