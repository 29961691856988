import React from "react";
import ConfirmForm from "../components/ConfirmForm";

const ConfimHome = ({
  email,
  password,
  passwordAgain,
  errors,
  handleChange,
  handleSubmit,
  match
}) => {
  return (
    <React.Fragment>
      <header className="login">
        <div className="overlay login"></div>

        <div className="container h-100 ">
          <div className="d-flex h-100 text-center align-items-center pt-5">
            <div className="w-100 text-white">
              <ConfirmForm
                email={email}
                password={password}
                passwordAgain={passwordAgain}
                errors={errors}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                match={match}
              />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default ConfimHome;
