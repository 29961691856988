import React, { useRef, useEffect, useState } from "react";
import { getLogin, putLogin } from "../services/adminService";

const AdminSettingsLoginSaver = ({ title, source }) => {
  const unRef = useRef(null);
  const passwordRef = useRef(null);
  const [username, setUsername] = useState();
  //const [password, setPassword] = useState();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    getLogin(source)
      .then(result => {
        setUsername(result.username);
        //setPassword(result.password);
        return username;
      })
      .then(un => {
        console.log(`${un} Loaded`);
      });
    // eslint-disable-next-line
  }, [source]);

  const handleSubmit = event => {
    event.preventDefault();
    const newUsername = unRef.current.value;
    const newPassword = passwordRef.current.value;
    if (newUsername !== "" && newPassword !== "") {
      console.log(newUsername, newPassword);
      putLogin(source, { username: newUsername, password: newPassword }).then(
        () => {
          setUpdated(true);
        }
      );
    }
  };

  return (
    <React.Fragment>
      <div className="mb-4">
        <p className="text-primary mb-0">
          {title}{" "}
          {updated === true && (
            <span className="badge badge-success">Updated</span>
          )}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="username" value="prayer" />
        <input type="hidden" name="password" value="prayer" />
        <div className="form-group mb-4">
          <input
            ref={unRef}
            className="form-control form-control-lg rounded border-0 shadow-lg"
            required=""
            placeholder={username}
            autoComplete="false"
            readOnly={updated}
          ></input>
        </div>
        <div className="form-group mb-4">
          <input
            ref={passwordRef}
            type="password"
            className="form-control form-control-lg rounded border-0 shadow-lg"
            required=""
            placeholder="New Password"
            autoComplete="new-password"
            readOnly={updated}
          ></input>
        </div>
        <div className="pt-2">
          {updated === false && (
            <button
              type="submit"
              className="btn btn-primary text-uppercase font-size-15 px-6 px-md-7 shadow-lg"
            >
              Update Login
            </button>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

export default AdminSettingsLoginSaver;
