import React, { useState, useEffect } from "react";
import { getCurrentUser } from "../services/authService";
import { getNewMessageCount } from "../services/adminService";

import MailTable from "./MailTable";

const MailBox = () => {
  const [newMail, setNewMail] = useState(0);
  const [user, setUser] = useState({
    last: "",
    email: "",
    level: "",
    name: ""
  });
  useEffect(() => {
    setUser(getCurrentUser());
    getNewMessageCount().then(setNewMail);
    //cookie.save("_usrobj", user, { path: "/" });
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <section className="py-9 fade-in">
        <div className="container">
          <div className="mb-7">
            <h2 className="text-uppercase font-size-22 font-weight-semibold">
              Global Concepts{" "}
              <span className="text-primary"> Message Center</span>
            </h2>
            <p>{newMail} New Messages</p>
          </div>
        </div>

        <div className="bg-smoke p-2 p-md-6 pb-6 pb-md-8 mb-9 rounded">
          <MailTable />
        </div>
      </section>
    </React.Fragment>
  );
};

export default MailBox;
