import React, { useContext, useState, useEffect } from "react";
import { getPoiRec } from "../services/serverCallsService";
import { ModalContext } from "../data/ModalContext";

const PoiRecomendations = ({ id, resort }) => {
  const [pois, setPois] = useState([]);

  useEffect(() => {
    getPoiRec(id).then(result => {
      setPois(result);
    });
  }, [id]);
  const { dispatch } = useContext(ModalContext);
  return (
    <div className="col-md-5 col-lg-4">
      <div className="border border-light-gray bg-smoke rounded p-6">
        <h4 className="text-uppercase mb-5">Client Recommendations</h4>

        <ul className="list-unstyled mb-6">
          {pois.map(poi => {
            return (
              <li key={poi} className="media mb-3">
                <div className="mr-3">
                  <i className="fa fa-check-square" aria-hidden="true"></i>
                </div>

                <div className="media-body">
                  <p className="text-dark text-capitalize mb-0">{poi}</p>
                </div>
              </li>
            );
          })}
        </ul>

        <div className="">
          <button
            className="btn btn-primary text-uppercase"
            type="button"
            onClick={() =>
              dispatch({
                type: "load-and-toggle",
                payload: {
                  item: resort._id,
                  collection: "Registry Collection"
                }
              })
            }
          >
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default PoiRecomendations;
