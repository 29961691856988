import React from "react";
import VideoForm from "../components/VideoForm";

const LogInHome = ({ email, password, errors, handleChange, handleSubmit }) => {
  return (
    <React.Fragment>
      <header className="login">
        <div className="overlay login"></div>
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source
            src="https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4"
            type="video/mp4"
          ></source>
        </video>
        <div className="container h-100 ">
          <div className="d-flex h-100 text-center align-items-center pt-5">
            <div className="w-100 text-white">
              <VideoForm
                email={email}
                password={password}
                errors={errors}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default LogInHome;
