import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";

const LoginModal = ({ modal, toggle }) => {
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={() => toggle()}
        className={"modal-dialog modal-sm modal-dialog-centered"}
      >
        <ModalHeader toggle={() => toggle()}>
          <h3 className="modal-title text-uppercase font-weight-bold">
            Log in to your account
          </h3>
        </ModalHeader>
        <ModalBody>
          <form className="" action="index.html" method="post">
            <div className="form-group">
              <input
                type="email"
                className="form-control bg-smoke"
                required=""
                placeholder="Email"
              ></input>
            </div>

            <div className="form-group">
              <input
                type="password"
                className="form-control bg-smoke"
                required=""
                placeholder="Password"
              ></input>
            </div>

            <div className="form-group form-check mb-0">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck5"
              ></input>
              <label
                className="form-check-label text-gray-color mb-3"
                for="exampleCheck5"
              >
                Remember me
              </label>
              <Link to="/contact" className="pull-right">
                Fogot Password?
              </Link>
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block text-uppercase"
            >
              Login
            </button>
            <div className="text-uppercase text-center py-3">Or</div>
            <button
              type="submit"
              className="btn btn-facebook btn-block text-uppercase"
            >
              Login with facebook
            </button>
          </form>
        </ModalBody>
        <ModalFooter>
          <p className="mb-1">
            Don’t have an Account? <Link to="/contact">Sign up</Link>
          </p>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LoginModal;
