import React from "react";
import DateCountDown from "../custom/DateCountDown";
import { Link } from "react-router-dom";

const ComingSoon = () => {
  return (
    <section
      className="coming-soon"
      style={{ backgroundImage: "url(assets/img/coming-soon/coming-bg.jpg)" }}
    >
      <div className="container">
        <div
          className="row flex-column justify-content-center align-items-center"
          style={{ height: "-webkit-fill-available" }}
        >
          <div className="coming-soon-logo">
            <Link to="/" style={{ backgroundColor: "#0000" }}>
              <img src="assets/img/logo-icon.png" alt="logo.png"></img>
            </Link>
          </div>

          <div className="col-md-9 col-lg-7 col-xl-8">
            <div className="coming-soon-content">
              <h1>We are coming soon</h1>
              <p>
                We are currently working on launching our website. Meanwhile
                read some information about us and you can subscribe to our
                newsletter in order to get notified when we launch..
              </p>
            </div>

            <DateCountDown endDate={"10/27/2019"} />

            <div className="row justify-content-center">
              <div className="col-md-10">
                <form
                  className="form-subscribe mb-6"
                  action="index.html"
                  method="post"
                >
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control form-search border-right-0"
                      required=""
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="input-group-text border-0 btn bg-primary text-white text-uppercase px-3 px-md-6 rounded"
                        type="submit"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComingSoon;
