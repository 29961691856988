import React, { Component } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from "reactstrap";

export default class ResortCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      activeIndex: 0
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }
  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.regweek.img.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.regweek.img.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  render() {
    const { activeIndex } = this.state;
    function convertImgUrl(imgurl) {
      return imgurl.replace(
        "https://www.rci.com/static/Resorts/Assets",
        "/registry/resorts"
      );
    }
    let resortItems = [];
    const resortSlides = this.props.regweek.img.map(item => {
      resortItems.push({
        src: convertImgUrl(item),
        altText: "Resort",
        caption: this.props.regweek.resortName
      });
      return (
        <CarouselItem
          className="custom-tag"
          tag="div"
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item}
        >
          <img
            src={convertImgUrl(item)}
            alt={"registry resort img"}
            className="custom-tag"
          />
          <CarouselCaption captionText="" captionHeader="" />
        </CarouselItem>
      );
    });
    return (
      <div className="col-lg-8 ">
        <style>
          {`.custom-tag {width: 100%; height: 450px; background: black;}`}
        </style>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          className="shadow"
        >
          <CarouselIndicators
            items={resortItems}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {resortSlides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
      </div>
    );
  }
}
