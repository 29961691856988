import React from "react";
import { CustomInput } from "reactstrap";
import { SingleDatePicker } from "react-dates";

const RegistrySearch = ({
  destination,
  region,
  setRegion,
  setDestination,
  startDate,
  setStartDate,
  startFocused,
  setstartFocused,
  endDate,
  setEndDate,
  endFocused,
  setEndFocused,
  destinationList,
  regionList,
  submitSearch,
  scrollHelper
}) => {
  return (
    <div className="bg-dark-light rounded p-6  ">
      <div className="row align-items-center">
        <div className="col-xl-3">
          <div className="section-title-bg mb-1 mb-lg-3 mb-xl-0">
            <h1 className="text-white text-uppercase mb-0">Search</h1>
            <h2 className="text-white text-uppercase font-weight-bold mb-0">
              registry <span className="text-primary">weeks</span>
            </h2>
          </div>
        </div>

        <div className="col-xl-9">
          <form method="GET" action="index.html">
            <div className="row">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <div className="select-dropdown">
                      <div className="form-group mb-lg-0">
                        <div
                          className="select-default select-search-box"
                          onClick={() => scrollHelper()}
                        >
                          <CustomInput
                            id="destinationInput"
                            type="select"
                            placeholder={"Destinations"}
                            className={"registry-search"}
                            value={destination}
                            onChange={e => setDestination(e.target.value)}
                          >
                            <option value="">DESTINATION</option>
                            {destinationList}
                          </CustomInput>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="select-dropdown">
                      <div className="form-group mb-lg-0">
                        <div className="select-default select-search-box">
                          <CustomInput
                            id="region"
                            type="select"
                            value={region}
                            onChange={e => setRegion(e.target.value)}
                            placeholder={"Destinations"}
                            className={"registry-search"}
                          >
                            <option value="">-</option>
                            {regionList}
                          </CustomInput>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="form-group form-group-icon mb-lg-0">
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      <SingleDatePicker
                        noBorder={true}
                        //className="form-control daterange-picker sidebar-daterange-picker text-uppercase"
                        inputIconPosition="after"
                        small={true}
                        block={false}
                        numberOfMonths={1}
                        date={startDate}
                        onDateChange={date => setStartDate(date)}
                        focused={startFocused}
                        onFocusChange={({ focused }) =>
                          setstartFocused(focused)
                        }
                        openDirection="up"
                        hideKeyboardShortcutsPanel={true}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="form-group form-group-icon mb-lg-0">
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      <SingleDatePicker
                        noBorder={true}
                        //className="form-control daterange-picker sidebar-daterange-picker text-uppercase"
                        inputIconPosition="after"
                        small={true}
                        block={false}
                        numberOfMonths={1}
                        date={endDate}
                        onDateChange={date => setEndDate(date)}
                        focused={endFocused}
                        onFocusChange={({ focused }) => setEndFocused(focused)}
                        openDirection="up"
                        hideKeyboardShortcutsPanel={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2">
                <button
                  className="btn btn-primary text-uppercase"
                  type="button"
                  onClick={() => submitSearch()}
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegistrySearch;
