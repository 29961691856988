import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
//import CurrentMonthOptions from "./CurrentMonthOptions";
import moment from "moment";

const CurrentMonthOptions = () => {
  let monthArray = [];

  for (let count = 0; count < 11; count++) {
    monthArray.push(
      <option
        key={count}
        value={parseInt(
          moment()
            .add(count, "months")
            .format("M")
        )}
      >
        {moment()
          .add(count, "months")
          .format("MMMM")}
      </option>
    );
  }
  return monthArray;
};

const SidebarSearchCompass = ({
  rooms,
  roomChange,
  byMonthShow,
  byLocationShow,
  byOtherShow,
  filterDrop,
  month,
  handleMonthChange,
  location,
  handleLocationChange,
  locationList,
  vipRef,
  checkBoxValue,
  toggleCheckboxValue
}) => {
  const selectMenu = locationList.map(key => {
    return (
      <option key={key} value={key}>
        {key}
      </option>
    );
  });
  selectMenu.push(
    <option key="ALL" value="ALL">
      ALL
    </option>
  );
  return (
    <div className="col-lg-3">
      <div className="row">
        <div className="col-md-6 col-lg-12">
          <div className="mb-6 bg-white px-3 py-6 border-top border-top-5 border-primary rounded shadow">
            <h4 className="text-uppercase font-weight-bold">Search</h4>

            <div className="mb-5"></div>
            <div className="accordion" id="accordionOne">
              <div className="card">
                <div
                  className="card-header"
                  id="headingTwo"
                  onClick={() =>
                    filterDrop({ type: "toggle", payload: "byMonthShow" })
                  }
                >
                  <h5
                    className={
                      byMonthShow === true ? "icon-bg" : "icon-bg collapsed"
                    }
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span>Month</span>
                  </h5>
                </div>

                <div
                  id="collapseTwo"
                  className={
                    byMonthShow === true ? "collapse show" : "collapse"
                  }
                  aria-labelledby="headingTwo"
                  data-parent="#accordionOne"
                >
                  <div className="card-body">
                    <div className="form-group form-group-icon form-group-icon-dark mb-5">
                      <Input
                        type="select"
                        onChange={e =>
                          handleMonthChange(parseInt(e.target.value))
                        }
                        value={month}
                        placeholder={"SEARCH BY MONTH"}
                      >
                        {CurrentMonthOptions()}
                      </Input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div
                  className="card-header"
                  id="headingTwo"
                  onClick={() =>
                    filterDrop({ type: "toggle", payload: "byLocationShow" })
                  }
                >
                  <h5
                    className={
                      byLocationShow === true ? "icon-bg" : "icon-bg collapsed"
                    }
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span>Location</span>
                  </h5>
                </div>

                <div
                  id="collapseTwo"
                  className={
                    byLocationShow === true ? "collapse show" : "collapse"
                  }
                  aria-labelledby="headingTwo"
                  data-parent="#accordionOne"
                >
                  <div className="card-body">
                    <div className="form-group form-group-icon form-group-icon-dark mb-5">
                      <Input
                        type="select"
                        onChange={e => handleLocationChange(e.target.value)}
                        value={location}
                        placeholder={"FILTER BY LOCATION"}
                      >
                        {selectMenu}
                      </Input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div
                  className="card-header"
                  id="headingTwo"
                  onClick={() =>
                    filterDrop({ type: "toggle", payload: "byOtherShow" })
                  }
                >
                  <h5
                    className={
                      byOtherShow === true ? "icon-bg" : "icon-bg collapsed"
                    }
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span>Other</span>
                  </h5>
                </div>

                <div
                  id="collapseTwo"
                  className={
                    byOtherShow === true ? "collapse show" : "collapse"
                  }
                  aria-labelledby="headingTwo"
                  data-parent="#accordionOne"
                >
                  <div className="card-body">
                    <div className="form-group form-group-icon form-group-icon-dark mb-5">
                      <FormGroup check>
                        <Label check>
                          <Input
                            innerRef={vipRef}
                            type="checkbox"
                            value={checkBoxValue}
                            onChange={toggleCheckboxValue}
                            //onChange={e => console.log(e.target.value)}
                          />{" "}
                          Include VIP Days
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group mb-5">
                <div className="row align-items-center">
                  <label className="control-label count-control-label col-5 col-lg-12 col-xl-4 text-uppercase mb-0 mb-lg-4 mb-xl-0 text-lg-center">
                    Rooms
                  </label>

                  <div className="col-7 col-lg-12 col-xl-7">
                    <div className="count-input mr-0 mx-lg-auto mr-xl-0">
                      <button
                        className="incr-btn"
                        onClick={() => {
                          roomChange({ type: "decrease" });
                        }}
                      >
                        –
                      </button>
                      <input
                        className="quantity"
                        type="text"
                        value={rooms < 0 ? "H" : rooms === 0 ? "S" : `${rooms}`}
                        readOnly
                      ></input>
                      <button
                        className="incr-btn"
                        onClick={() => roomChange({ type: "increase" })}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarSearchCompass;
