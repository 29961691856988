import React, { useContext, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CruiseContext } from "../data/ModalContext";

const CruiseModal = () => {
  const { stops, cModal, setCmodal } = useContext(CruiseContext);
  useEffect(() => {});
  return (
    <div>
      <Modal
        isOpen={cModal}
        toggle={() => setCmodal(false)}
        className={"modal-dialog modal-sm modal-dialog-centered"}
      >
        <ModalHeader toggle={() => setCmodal(false)}>
          <p className="modal-title text-uppercase font-weight-bold">
            Destination Ports
          </p>
        </ModalHeader>
        <ModalBody>
          <ul>
            {stops.map(stop => {
              return <li>{stop}</li>;
            })}
          </ul>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};

export default CruiseModal;
