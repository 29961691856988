import React, { useState, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem
} from "reactstrap";
import { Link } from "react-router-dom";
//import { fadeHeader } from "../custom/FadeoutNavbar";
import HeaderDropdown from "../custom/HeaderDropdown";

const ResponsiveHeader = ({ page }) => {
  const [scroll, setScroll] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [navScrollUp, setNavScrollUp] = useState(false);
  const [zero, setZero] = useState(0);
  const [hasSticky, setHasSticky] = useState(true);

  //const mainAreaRef = useRef();

  useEffect(() => {
    if (scroll < 650) setHasSticky(false);
    else setHasSticky(true);
  }, [scroll]);
  window.onscroll = () => {
    const newScrollHeight = Math.ceil(window.scrollY / 50) * 50;
    /*  console.log(
      "newScrollHeight:",
      newScrollHeight,
      " / scroll:",
      scroll,
      " / scrollY:",
      window.scrollY
    ); */
    if (scroll !== newScrollHeight) {
      setScroll(newScrollHeight);

      if (scroll > zero) setNavScrollUp(true);
      else setNavScrollUp(false);
      setZero(scroll);

      if (
        ("main_area" === hasSticky && scroll <= 600) ||
        window.outerWidth <= 300
      ) {
        //headerAreaRef.current.setAttribute("style", { height: "auto" });
      }
    }
  };

  return (
    <header className={`header  `} id="pageTop">
      <Navbar
        //innerRef={mainAreaRef}
        //style={{ opacity: fadeHeader(scroll, setScroll) }}
        color="light"
        light
        expand="md"
        className={`nav-menuzord navbar-sticky ${
          navScrollUp ? "navbar-scrollUp" : ""
        }`}
      >
        <div className="container clearfix">
          <div id="menuzord" className="menuzord menuzord-responsive">
            <NavbarBrand className="menuzord-brand mr-4" href="/">
              <img
                className="hidden-xs"
                src="/assets/img/logo-color-big.png"
                alt="logo-img"
              ></img>
            </NavbarBrand>
            <div className="nav-item dropdown nav-item-left mr-0">
              <button
                className="btn btn-xs btn-primary d-none d-lg-inline-block"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-search mr-0" aria-hidden="true"></i>
              </button>

              <div className="dropdown-menu dropdown-menu-right rounded-0">
                <form className="" action="index.html" method="post">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control form-control-sm rounded-0"
                      required=""
                      placeholder="Search...."
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="input-group-text border-0 rounded-0 btn bg-primary"
                        type="submit"
                      >
                        <span className="text-white text-uppercase font-weight-bold">
                          Search
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <Collapse isOpen={isOpen} className="mr-2" navbar>
              <Nav
                className="mr-1 ml-1 pl-5 menuzord-menu menuzord-right menuzord-indented scrollable"
                navbar
              >
                <NavItem className={page === "/compass" ? "active" : ""}>
                  <Link to="/compass">Compass Weeks</Link>
                </NavItem>
                <NavItem className={page === "/registry" ? "active" : ""}>
                  <Link to="/registry">Registry</Link>
                </NavItem>
                <NavItem className={page === "/vip" ? "active" : ""}>
                  <Link to="/vip">Vip Collection</Link>
                </NavItem>
                <NavItem className={page === "/cruise" ? "active" : ""}>
                  <Link to="/cruise">Cruise</Link>
                </NavItem>
                <NavItem className={page === "/travel-center" ? "active" : ""}>
                  <Link to="/travel-center">Travel Center</Link>
                </NavItem>

                <HeaderDropdown
                  menu="Dashboard"
                  className={page === "/dashboard" ? "active" : ""}
                >
                  <li className="">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="">
                    <Link to="/dashboard/profile">Profile</Link>
                  </li>
                  <li className="">
                    <Link to="/dashboard/booking">Booking</Link>
                  </li>

                  <li className="">
                    <Link to="/dashboard/settings">Settings</Link>
                  </li>
                  <li className="">
                    <Link to="/contact">Send an INQUIRY</Link>
                  </li>
                  <li className="">
                    <Link to="/contact">Contact Us</Link>
                  </li>

                  <li className="">
                    <Link to="/">Login</Link>
                  </li>
                </HeaderDropdown>
              </Nav>
            </Collapse>
          </div>
        </div>
        <NavbarToggler className="ml-2 pb-2" onClick={toggle} />
      </Navbar>
    </header>
  );
};

export default ResponsiveHeader;
