import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { Row, Card, CardBody } from "reactstrap";
import { getMessages, getSingleMessage } from "../services/adminService";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const MailTable = () => {
  const [pages, setPages] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([
    { from: "", subject: "", date: "" }
  ]);
  const [selectedRecordId, setSelectedRecordId] = useState("");
  const [selectedMessage, setSelectedMessage] = useState({
    from: {
      name: "",
      last: ""
    },
    subject: "",
    messageText: ""
  });
  let pageSize = 10;
  const tableColumns = [
    {
      Header: "From",
      accessor: "from",
      Cell: row => (
        <span>
          {row.original.from.last}, {row.original.from.name}
        </span>
      ),
      className: "text-center"
    },
    {
      Header: "Subject",
      accessor: "subject",
      className: "text-left"
    },
    {
      Header: "Date",
      accessor: "created_on",
      maxWidth: 80,
      Cell: row => moment(row.original.created_on).format("MM/DD/YY"),
      className: "text-center"
    }
  ];

  return (
    <React.Fragment>
      <Card className="p-0 shadow-lg">
        <CardBody className="p-0">
          <Row>
            <div className="col-lg-8">
              <ReactTable
                className="-striped -highlight"
                columns={tableColumns}
                data={tableData}
                pageSize={pageSize}
                showPageSizeOptions={false}
                resizable={false}
                pages={pages} // should default to -1 (which means we don't know how many pages we have)
                loading={loading}
                manual // informs React Table that you'll be handling sorting and pagination server-side
                onFetchData={(state, instance) => {
                  // show the loading overlay
                  setLoading(true);
                  // fetch your data
                  getMessages(state.page + 1, pageSize).then(res => {
                    // Update react-table
                    setTableData(res.list);
                    setPages(res.pages);
                    setLoading(false);
                  });
                }}
                getTdProps={(state, rowInfo, column, instance) => {
                  if (rowInfo) {
                    return {
                      onClick: (e, handleOriginal) => {
                        setSelectedRecordId(rowInfo.original._id);

                        getSingleMessage(rowInfo.original._id).then(res => {
                          setSelectedMessage(res);
                          setLoading(true);
                          // fetch your data
                          getMessages(state.page + 1, pageSize).then(res => {
                            // Update react-table
                            setTableData(res.list);
                            setPages(res.pages);
                            setLoading(false);
                          });
                        });
                      },
                      style: {
                        "font-weight":
                          rowInfo.original.new === true ? "bolder" : "300"
                      }
                    };
                  }
                  return {};
                }}
                getTrProps={(state, rowInfo, instance) => {
                  if (rowInfo) {
                    return {
                      style: {
                        "font-weight":
                          rowInfo.row.new === true ? "bold" : "normal"
                      }
                    };
                  }
                  return {};
                }}
              />
            </div>
            <div className="col-lg-4">
              {selectedMessage.from === "" && (
                <Card>
                  <CardBody>
                    <div>
                      <FontAwesomeIcon className="mb-4" icon={faArrowLeft} />
                      <h3>Click a contact submission to assign...</h3>
                    </div>
                  </CardBody>
                </Card>
              )}
              {selectedMessage.from !== "" && (
                <React.Fragment>
                  <form>
                    <Card>
                      <CardBody>
                        {selectedMessage.from.last === "" && (
                          <Card>
                            <CardBody>
                              <div>
                                <FontAwesomeIcon
                                  className="mb-4"
                                  icon={faArrowLeft}
                                />
                                <h3>Click a message to view...</h3>
                              </div>
                            </CardBody>
                          </Card>
                        )}
                        {selectedMessage.from.last !== "" && (
                          <div>
                            <h3 className="mt-4">Message</h3>
                            <div className="row mt-1">
                              <div className="col-4 text-primary">Date:</div>

                              <div className="col-8">
                                {selectedMessage && (
                                  <div>
                                    {moment(selectedMessage.created_on).format(
                                      "MM/DD/YY"
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mt-1">
                              <div className="col-4 text-primary">From:</div>

                              <div className="col-8">
                                {selectedMessage && (
                                  <div>
                                    {selectedMessage.from.last},{" "}
                                    {selectedMessage.from.name}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mt-1">
                              <div className="col-4 text-primary">Subject:</div>
                              <div className="col-8">
                                {selectedMessage.subject}
                              </div>
                            </div>
                            <div className="row mt-1">
                              <div className="col-4 text-primary">Message:</div>
                              <div className="col-8">
                                {selectedMessage.messageText}
                              </div>
                            </div>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                    <div className="pb-4"></div>
                  </form>
                </React.Fragment>
              )}
            </div>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MailTable;
