import React, { useState, useEffect, useReducer, useRef } from "react";
import moment from "moment";
import { searchCompassWeeks } from "../services/serverCallsService";
import PageTitle from "../components/PageTitle";
import SidebarSearchCompass from "../components/SidebarSearchCompass";
import Pagination from "../custom/Pagination";
import CompassList from "../components/CompassList";
import queryString from "query-string";

function unitReducer(state, action) {
  switch (action.type) {
    case "decrease":
      return {
        ...state,
        unitSize: state.unitSize - 1 < 0 ? -1 : state.unitSize - 1
      };
    case "increase":
      return {
        ...state,
        unitSize: state.unitSize + 1 > 3 ? 4 : state.unitSize + 1
      };
    case "toggle":
      return {
        ...state,
        byOtherShow: action.payload === "byOtherShow" && !state.byOtherShow,
        byMonthShow: action.payload === "byMonthShow" && !state.byMonthShow,
        byLocationShow:
          action.payload === "byLocationShow" && !state.byLocationShow
      };
    default:
      return state;
  }
}

const Compass = props => {
  const values = queryString.parse(props.location.search);
  const vipRef = useRef();
  const [vipBoxValue, setVipBoxValue] = useState(false);
  const [month, setMonth] = useState(moment().format("M"));
  const [country, setCountry] = useState(
    values.country ? values.country : "ALL"
  );
  const [page, setPage] = useState(1);
  const [resorts, setResorts] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [count, setCount] = useState(0);
  const [
    { unitSize, byMonthShow, byLocationShow, byOtherShow },
    dispatch
  ] = useReducer(unitReducer, {
    unitSize: -1,
    byMonthShow: false,
    byLocationShow: false,
    byOtherShow: false
  });

  //Init API call
  useEffect(() => {
    searchCompassWeeks(page, month, unitSize, country, vipBoxValue).then(
      results => {
        setResorts(results.resorts);
        setDestinations(results.destinations);
        setCount(results.count);
      }
    );
  }, [month, unitSize, country, page, vipBoxValue]);

  useEffect(() => {
    const lastPage = Math.ceil(count / 25);
    if (page > lastPage) setPage(lastPage > 0 ? lastPage : 1);
    // eslint-disable-next-line
  }, [count]);

  function toggleCheckboxValue() {
    setVipBoxValue(vipBoxValue => !vipBoxValue);
  }

  return (
    <React.Fragment>
      <div className="main-wrapper ">
        <PageTitle
          title="Compass Weeks"
          titleImg="assets/img/pages/page-title-bg11.jpg"
        ></PageTitle>
        <section className="bg-smoke py-10">
          <div className="container">
            <div className="row">
              <SidebarSearchCompass
                month={month}
                handleMonthChange={setMonth}
                rooms={unitSize}
                roomChange={dispatch}
                byMonthShow={byMonthShow}
                byLocationShow={byLocationShow}
                filterDrop={dispatch}
                location={country}
                handleLocationChange={setCountry}
                locationList={destinations}
                byOtherShow={byOtherShow}
                toggleCheckboxValue={toggleCheckboxValue}
                vipRef={vipRef}
                checkBoxValue={vipBoxValue}
              />
              <div className="col-lg-9 flex">
                {destinations.length > 0 && (
                  <React.Fragment>
                    <Pagination setPage={setPage} page={page} records={count} />
                    <CompassList resorts={resorts} />
                    <Pagination setPage={setPage} page={page} records={count} />
                  </React.Fragment>
                )}

                {/*grouped.resorts[0].area !== "" && (
                  <CompassList grouped={grouped} />
                ) */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Compass;
