import React, { Component, Suspense } from "react";
import PageTitle from "../components/PageTitle";
import SidebarSearch from "../components/SidebarSearch";
//import HotweeksList from "../components/HotweeksList";
import Pagination from "../components/Pageination";
import moment from "moment";
import _ from "lodash";
import { searchHotweeks } from "../services/serverCallsService";
//import { Row } from "reactstrap";
const HotweeksList = React.lazy(() => import("../components/HotweeksList"));

class Hotweeks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      resortName: "",
      rooms: 1,
      startDate: moment(),
      startFocused: null,
      byPriceShow: false,
      byMonthShow: true,
      byFacilityShow: false,
      byLocationShow: false,
      month: moment().format("M"),
      hotweeks: [],
      grouped: [],
      groupedDisplay: [],
      currentPage: 1,
      location: "",
      locationList: []
    };
  }
  resortNameChange = e => {
    const resortName = e.target.value;
    this.setState({ resortName });
  };
  roomChange = change => {
    let { rooms } = this.state;
    change === "increase" ? (rooms = rooms + 1) : (rooms = rooms - 1);
    if (rooms < 2) rooms = 1;
    if (rooms > 4) rooms = 4;
    //const grouped = this.state.groupedDisplay.filter(week => {
    //  console.log(week.availableUnits);
    //  return week;
    //});
    this.setState({ rooms });
  };

  setStartDate = date => {
    this.setState({ startDate: date });
  };
  setstartFocused = startFocused => {
    this.setState({ startFocused });
  };
  handleSubmit = () => {
    this.setState({ loading: true }, this.submitSearch);
  };
  submitSearch = async () => {
    const hotweeks = await searchHotweeks(this.state.month);

    const groupedObj = _.groupBy(hotweeks, "area.country");
    let grouped = [];
    let locationList = [];
    for (let [key, value] of Object.entries(groupedObj)) {
      grouped.push({ [key]: value });
      locationList.push(<option value={key}>{key}</option>);
      //grouped.push({ [key]: value });
      //console.log(`${key}: ${value}`);
    }
    const groupedDisplay = grouped;

    this.setState({
      hotweeks,
      grouped,
      locationList,
      groupedDisplay,
      loading: false
    });
  };

  filterDrop = menu => {
    if (menu === "byLocationShow") {
      let { byLocationShow } = this.state;
      byLocationShow === true
        ? (byLocationShow = false)
        : (byLocationShow = true);
      this.setState({ byLocationShow });
    }
    if (menu === "byPriceShow") {
      let { byPriceShow } = this.state;
      byPriceShow === true ? (byPriceShow = false) : (byPriceShow = true);
      this.setState({ byPriceShow });
    }
    if (menu === "byMonthShow") {
      let { byMonthShow } = this.state;
      byMonthShow === true ? (byMonthShow = false) : (byMonthShow = true);
      this.setState({ byMonthShow });
    }
    if (menu === "byFacilityShow") {
      let { byFacilityShow } = this.state;
      byFacilityShow === true
        ? (byFacilityShow = false)
        : (byFacilityShow = true);
      this.setState({ byFacilityShow });
    }
  };

  handleMonthChange = e => {
    const month = e.target.value;
    this.setState({ month });
  };
  handleLocationChange = e => {
    const location = e.target.value;
    const { hotweeks } = this.state;

    const groupedObj = _.groupBy(hotweeks, "area.country");
    let grouped = [];

    for (let [key, value] of Object.entries(groupedObj)) {
      if (key === location) grouped.push({ [key]: value });
    }
    const groupedDisplay = grouped;
    this.setState({ location, groupedDisplay });
  };
  handleSortBy = e => {
    console.log(e);
  };
  handlePageChange = newPage => {
    if (newPage < 2) newPage = 1;

    this.setState({ currentPage: newPage });
  };
  componentDidMount() {
    this.submitSearch();
  }

  render() {
    return (
      <React.Fragment>
        <div className="main-wrapper hotweeks">
          <PageTitle
            title="Hotweeks"
            titleImg="assets/img/pages/page-title-bg6.jpg"
          ></PageTitle>
          <section className="bg-smoke py-10">
            <div className="container">
              <div className="row">
                <SidebarSearch
                  month={this.state.month}
                  handleMonthChange={this.handleMonthChange}
                  resortName={this.state.resortName}
                  resortNameChange={this.resortNameChange}
                  rooms={this.state.rooms}
                  startDate={this.state.startDate}
                  startFocused={this.state.startFocused}
                  roomChange={this.roomChange}
                  setStartDate={this.setStartDate}
                  setstartFocused={this.setstartFocused}
                  submitSearch={this.submitSearch}
                  byPriceShow={this.state.byPriceShow}
                  byMonthShow={this.state.byMonthShow}
                  byFacilityShow={this.state.byFacilityShow}
                  byLocationShow={this.state.byLocationShow}
                  filterDrop={this.filterDrop}
                  location={this.state.location}
                  handleLocationChange={this.handleLocationChange}
                  locationList={this.state.locationList}
                />
                <div className="col-lg-9 flex">
                  {/*<WeeksViewOptions handleSortBy={this.handleSortBy} /> */}
                  <Suspense fallback={<h1>Loading...</h1>}>
                    <HotweeksList
                      hotweeks={this.state.hotweeks}
                      grouped={this.state.groupedDisplay}
                    />
                  </Suspense>
                </div>
              </div>
            </div>
            <Pagination
              currentPage={this.state.currentPage}
              handlePageChange={this.handlePageChange}
              numberOfItems={this.state.hotweeks.length}
            />
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default Hotweeks;
