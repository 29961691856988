import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const StepOneForm = ({ inputHandler, survey }) => {
  return (
    <form action="" method="post" target="_blank">
      <div className="row">
        <div className="col-lg-12">
          <label for="exampleFormControlTextarea1">
            Have you previously cruised?
          </label>
          <div className="form-group mb-5">
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  value={survey.a1}
                  name={"a1"}
                  onChange={e => {
                    inputHandler(e);
                  }}
                />{" "}
                Yes
              </Label>
            </FormGroup>
          </div>
          <div className="form-group mb-5">
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  value={survey.a2}
                  name={"a2"}
                  onChange={e => {
                    inputHandler(e);
                  }}
                />{" "}
                No
              </Label>
            </FormGroup>
          </div>
          <div className="form-group mb-5">
            <label for="exampleFormControlTextarea1">
              If yes, tell us which cruise lines you've sailed with - Past
              cruise passengers may be eligible for additional discounts or
              benefits.
            </label>
            <textarea
              className="form-control border-0 bg-smoke"
              rows="7"
            ></textarea>
          </div>
          <label for="exampleFormControlTextarea1">
            Have you already received a quote?
          </label>
          <div className="form-group mb-5">
            <FormGroup check>
              <Label check>
                <Input type="radio" name="radio2" /> Yes
              </Label>
            </FormGroup>
          </div>
          <div className="form-group mb-5">
            <FormGroup check>
              <Label check>
                <Input type="radio" name="radio2" /> No
              </Label>
            </FormGroup>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">Cruise Ship:</label>
            <input
              type="text"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">Sail Date:</label>
            <input
              type="text"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">Date you received quote:</label>
            <input
              type="text"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">Price Quoted:</label>
            <input
              type="number"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">Stateroom Type:</label>
            <input
              type="text"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">Stateroom Category:</label>
            <input
              type="text"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>
      </div>
      <label for="exampleFormControlTextarea1">
        Do you already have a cruise booked
      </label>
      <div className="form-group mb-5">
        <FormGroup check>
          <Label check>
            <Input type="radio" name="radio2" /> Yes
          </Label>
        </FormGroup>
      </div>
      <div className="form-group mb-5">
        <FormGroup check>
          <Label check>
            <Input type="radio" name="radio2" /> No
          </Label>
        </FormGroup>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">Cruise Ship:</label>
            <input
              type="text"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">Price:</label>
            <input
              type="text"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">Sail Date:</label>
            <input
              type="text"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">Stateroom Type:</label>
            <input
              type="text"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">When Did You Book:</label>
            <input
              type="text"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label for="inputName">Stateroom Category:</label>
            <input
              type="text"
              className="form-control border-0 bg-smoke"
            ></input>
          </div>
        </div>
      </div>
      <div className="form-group mb-5">
        <label for="exampleFormControlTextarea1">Pick one</label>
        <div className="form-group mb-5">
          <FormGroup check>
            <Label check>
              <Input type="radio" name="radio2" /> Cruise is paid in full
            </Label>
          </FormGroup>
        </div>
      </div>

      <div className="form-group mb-5">
        <div className="form-group mb-5">
          <FormGroup check>
            <Label check>
              <Input type="radio" name="radio2" /> Reservation only has a
              deposit
            </Label>
          </FormGroup>
        </div>
      </div>
    </form>
  );
};

export default StepOneForm;
