import React, { useState, useEffect } from "react";
import { logout } from "../services/authService";
import { getNewMessageCount } from "../services/adminService";

import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { NavbarToggler, Collapse, NavLink } from "reactstrap";

const AdminHeader = () => {
  let match = useRouteMatch();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(isOpen => !isOpen);
  const [notifyMenu, setNotifyMenu] = useState(false);
  const [userMenu, setUserMenu] = useState(false);
  const [newMessages, setNewMessages] = useState(0);

  useEffect(() => {
    getNewMessageCount().then(setNewMessages);
  }, []);

  function handleDropDownMenu(menu) {
    switch (menu) {
      case "userMenu":
        setUserMenu(!userMenu);
        setNotifyMenu(false);
        break;
      case "notifyMenu":
        setNotifyMenu(!notifyMenu);
        setUserMenu(false);
        break;
      default:
        setUserMenu(false);
        setNotifyMenu(false);
        break;
    }
  }

  return (
    <section className="section-top mt-4">
      <nav className="navbar navbar-expand-md navbar-light navbar-dark-light">
        <div className="container">
          <NavbarToggler className="ml-2" onClick={toggle} />

          {/**Mobile */}
          <div className="navbar-nav-right d-md-none mr-6 pr-1">
            <ul className="list-unstyled d-flex align-items-center mb-0">
              <li
                className={`nav-item dropdown nav-item-left ${notifyMenu ===
                  true && "show"}`}
              >
                <NavLink
                  className="nav-link"
                  id="navbarDropdown"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => handleDropDownMenu("notifyMenu")}
                >
                  <i className="fa fa-bell icon" aria-hidden="true"></i>{" "}
                  <span className="badge-pill badge-primary">4</span>
                </NavLink>

                <div
                  className={`dropdown-menu dropdown-menu-right dropdown-left ${notifyMenu ===
                    true && "show"}`}
                >
                  <ul className="list-unstyled list-group list-group-flush">
                    <li className="">
                      <NavLink className="list-group-item rounded-0 border-bottom first-child">
                        <h3 className="text-dark mb-0">Information</h3>
                      </NavLink>
                    </li>

                    <li>
                      <Link
                        to={{
                          pathname: "/dashboard/profile",
                          search: "?sort=name",
                          hash: "#the-hash",
                          state: { fromDashboard: true }
                        }}
                      />
                      <Link
                        to="/dashboard"
                        className="list-group-item border-bottom border-off-white rounded-0"
                      >
                        <h5 className="fon-size-15 text-dark">
                          Maldive Tour Packages
                        </h5>
                        <small className="text-gray-color">6 Hours Ago</small>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/dashboard"
                        className="list-group-item border-bottom border-off-white rounded-0"
                      >
                        <h5 className="fon-size-15 text-dark">
                          London Tour Booking
                        </h5>
                        <small className="text-gray-color">15 Hours Ago</small>
                      </Link>
                    </li>

                    <li className="">
                      <Link
                        to="/dashboard"
                        className="list-group-item border-off-white border-bottom-0 rounded-0 last-child"
                      >
                        <h5 className="fon-size-15 text-dark">
                          Paris Tour Booking
                        </h5>
                        <small className="text-gray-color">15 Hours Ago</small>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li
                className={`nav-item dropdown nav-item-left mr-0 ${
                  userMenu === true ? "show" : ""
                }`}
              >
                <NavLink
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    handleDropDownMenu("userMenu");
                  }}
                >
                  <img
                    src="/assets/img/dashboard/user.png"
                    alt="User"
                    className="w-100 rounded-circle mr-1"
                  ></img>
                </NavLink>

                <div
                  className={`dropdown-menu dropdown-menu-right dropdown-left ${
                    userMenu === true ? "show" : ""
                  }`}
                >
                  <ul className="list-unstyled list-group list-group-flush">
                    <li className="">
                      <Link
                        className="list-group-item rounded-0 border-bottom first-child"
                        to="/dashboard/users"
                      >
                        <h5 className="fon-size-15 text-dark">Users</h5>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/dashboard/settings"
                        className="list-group-item border-bottom border-off-white rounded-0"
                      >
                        <h5 className="fon-size-15 text-dark text-capitalize">
                          Setting
                        </h5>
                      </Link>
                    </li>

                    <li
                      className=""
                      onClick={() => {
                        logout();
                        window.location = "/";
                      }}
                    >
                      <button className="list-group-item border-off-white border-bottom-0 rounded-0 last-child">
                        <h5 className="fon-size-15 text-dark">Log out</h5>
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>

          <Collapse isOpen={isOpen} className="mr-2" navbar>
            <ul className="navbar-nav mr-auto">
              <li className="nav-item dropdown">
                <Link
                  to={"/dashboard"}
                  className={`nav-link  ${pathname === "/dashboard" &&
                    "active"} `}
                >
                  <i className="fa fa-tachometer" aria-hidden="true"></i>
                  <span>Admin</span>
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  to={`${match.url}/users`}
                  className={`nav-link  ${pathname === "/dashboard/users" &&
                    "active"} `}
                >
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <span>Users</span>
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  to={"/dashboard/booking"}
                  className={`nav-link  ${pathname === "/dashboard/booking" &&
                    "active"} `}
                >
                  <i className="fa fa-cube" aria-hidden="true"></i>
                  <span>Bookings</span>
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  to={"/dashboard/settings"}
                  className={`nav-link  ${pathname === "/dashboard/settings" &&
                    "active"} `}
                >
                  <i className="fa fa-cogs" aria-hidden="true"></i>
                  <span>Settings</span>
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  to={"/dashboard/adminsettings"}
                  className={`nav-link  ${pathname ===
                    "/dashboard/adminsettings" && "active"} `}
                >
                  <i className="fa fa-cogs" aria-hidden="true"></i>
                  <span>ADMIN Settings</span>
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  to={"/dashboard/mail"}
                  className={`nav-link  ${pathname === "/dashboard/mail" &&
                    "active"} `}
                >
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <span>Mail </span>
                  {newMessages > 0 && (
                    <span className="badge-pill badge-primary">
                      {newMessages}
                    </span>
                  )}
                </Link>
              </li>
            </ul>
          </Collapse>

          {/**Full */}
          <div className="navbar-nav-right d-none d-md-block">
            <ul className={`list-unstyled d-flex align-items-center mb-0 `}>
              {/**Notify */}
              <li
                className={`nav-item dropdown nav-item-left ${notifyMenu ===
                  true && "show"}`}
              >
                <NavLink
                  className="nav-link"
                  id="navbarDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => handleDropDownMenu("notifyMenu")}
                >
                  <i className="fa fa-bell" aria-hidden="true"></i>{" "}
                  <span className="badge-pill badge-primary">2</span>
                </NavLink>

                <div
                  className={`dropdown-menu dropdown-menu-right dropdown-left ${notifyMenu ===
                    true && "show"}`}
                >
                  <ul className="list-unstyled list-group list-group-flush">
                    <li className="">
                      <Link
                        to="/contact"
                        className="list-group-item rounded-0 border-bottom first-child"
                      >
                        <h3 className="text-dark mb-0">Information</h3>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/contact"
                        className="list-group-item border-bottom border-off-white rounded-0"
                      >
                        <h5 className="fon-size-15 text-dark">
                          Maldive Tour Packages
                        </h5>
                        <small className="text-gray-color">6 Hours Ago</small>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/contact"
                        className="list-group-item border-bottom border-off-white rounded-0"
                      >
                        <h5 className="fon-size-15 text-dark">
                          London Tour Booking
                        </h5>
                        <small className="text-gray-color">15 Hours Ago</small>
                      </Link>
                    </li>

                    <li className="">
                      <Link
                        to="/contact"
                        className="list-group-item border-off-white border-bottom-0 rounded-0 last-child"
                      >
                        <h5 className="fon-size-15 text-dark">
                          Paris Tour Booking
                        </h5>
                        <small className="text-gray-color">15 Hours Ago</small>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              {/**User */}

              <li
                className={`nav-item dropdown nav-item-left mr-0 ${
                  userMenu === true ? "show" : ""
                }`}
              >
                <NavLink
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => handleDropDownMenu("userMenu")}
                >
                  <img
                    src="/assets/img/dashboard/dash-ava.jpg"
                    alt="User"
                    className="w-100 rounded-circle mr-1"
                  ></img>
                </NavLink>

                <div
                  className={`dropdown-menu dropdown-menu-right dropdown-left ${
                    userMenu === true ? "show" : ""
                  }`}
                >
                  <ul className="list-unstyled list-group list-group-flush">
                    <li className="">
                      <Link
                        to="/dashboard/profile"
                        className="list-group-item rounded-0 border-bottom first-child"
                      >
                        <h5 className="fon-size-15 text-dark">Admin</h5>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/dashboard/settings"
                        className="list-group-item border-bottom border-off-white rounded-0"
                      >
                        <h5 className="fon-size-15 text-dark text-capitalize">
                          Settings
                        </h5>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/dashboard/users"
                        className="list-group-item border-bottom border-off-white rounded-0"
                      >
                        <h5 className="fon-size-15 text-dark text-capitalize">
                          Users
                        </h5>
                      </Link>
                    </li>

                    <li
                      className=""
                      onClick={() => {
                        logout();
                        window.location = "/";
                      }}
                    >
                      <button className="list-group-item border-off-white border-bottom-0 rounded-0 last-child">
                        <h5 className="fon-size-15 text-dark">Log out</h5>
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default AdminHeader;
