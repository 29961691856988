import React, { useRef, useState } from "react";
import { changePassword } from "../services/authService";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText
} from "reactstrap";

const UserSettings = () => {
  const [passIsChanged, setPassIsChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const newPassRef = useRef();
  const repeatPassRef = useRef();

  async function handleSubmit(e, form) {
    e.preventDefault();

    if (form === "password") {
      try {
        await changePassword(
          newPassRef.current.value,
          repeatPassRef.current.value
        );
        setPassIsChanged(true);
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          setErrorMessage(ex.response.data);
          setIsInvalid(true);
        } else {
          setErrorMessage("Error: Please try again");
          setIsInvalid(true);
        }
      }
    } else {
      console.log(form);
    }
  }

  return (
    <section className="py-6 fade-in">
      <div className="container">
        <div className="bg-smoke p-2 p-md-6 pb-6 pb-md-8 mb-9 rounded">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-4">
                    <h3 className="text-uppercase mb-3">Account Settings</h3>
                    <p className="text-primary mb-0">Change Your Password</p>
                  </div>

                  {passIsChanged === false && (
                    <Form onSubmit={e => handleSubmit(e, "password")}>
                      <FormGroup className="mb-4">
                        <Input
                          invalid={isInvalid}
                          onChange={() => setIsInvalid(false)}
                          type="password"
                          className="form-control form-control-lg rounded border-0 shadow-lg"
                          required=""
                          placeholder="New Password"
                          innerRef={newPassRef}
                        ></Input>
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Input
                          invalid={isInvalid}
                          type="password"
                          className="form-control form-control-lg rounded border-0 shadow-lg"
                          required=""
                          placeholder="Confirm New Password"
                          innerRef={repeatPassRef}
                        ></Input>
                        <FormFeedback>{errorMessage}</FormFeedback>
                      </FormGroup>

                      <FormGroup className="pt-2">
                        <button
                          type="submit"
                          className="btn btn-primary text-uppercase font-size-15 px-6 px-md-7 shadow-lg"
                        >
                          Update Password
                        </button>
                      </FormGroup>
                    </Form>
                  )}
                  {passIsChanged === true && (
                    <h3 className="text-uppercase mb-3">Password Changed</h3>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6">
                  <p className="text-primary">Change Settings</p>
                  <form onSubmit={e => handleSubmit(e, "settings")}>
                    <div className="custom-control custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input shadow-lg"
                        id="customCheckSettings1"
                      ></input>
                      <label
                        className="custom-control-label settings-control-label"
                        htmlFor="customCheckSettings1"
                      >
                        <span className="ml-0">
                          Global Concepts has periodic offers and deals on
                          really cool destinations.
                        </span>
                      </label>
                    </div>

                    <div className="custom-control custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input shadow-lg"
                        id="customCheckSettings2"
                      ></input>
                      <label
                        className="custom-control-label settings-control-label"
                        htmlFor="customCheckSettings2"
                      >
                        <span className="ml-0">
                          Global Concepts has fun company news, as well as
                          periodic emails.
                        </span>
                      </label>
                    </div>

                    <div className="custom-control custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input shadow-lg"
                        id="customCheckSettings3"
                      ></input>
                      <label
                        className="custom-control-label settings-control-label"
                        htmlFor="customCheckSettings3"
                      >
                        <span className="ml-0">
                          I have an upcoming reservation.
                        </span>
                      </label>
                    </div>

                    <div className="pt-3">
                      <button
                        type="submit"
                        className="btn btn-primary text-uppercase font-size-15 px-3 px-md-6 shadow-lg"
                      >
                        Update your account
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserSettings;
