import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";
import ResortTab from "./ResortTab";
import LocationTab from "./LocationTab";
import LocationMapTab from "./LocationMapTab";
import AvailableTab from "./AvailableTab";

const ResortTabs = ({ resort, isRegistry }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className="mb-7">
      <div id="destination">
        <div className="mb-0">
          <Nav tabs className="destination-tabs">
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                Resort
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                Location
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                Location Map
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === "4" })}
                onClick={() => {
                  toggle("4");
                }}
              >
                Available Units
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            className={"destinationTab-content"}
            id="destinationTabContent"
            activeTab={activeTab}
          >
            <TabPane tabId="1">
              <ResortTab resort={resort} />
            </TabPane>

            <TabPane tabId="2">
              <LocationTab resort={resort} />
            </TabPane>

            <TabPane tabId="3">
              <LocationMapTab resort={resort} />
            </TabPane>

            <TabPane tabId="4">
              <AvailableTab resort={resort} isRegistry={isRegistry} />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default ResortTabs;
