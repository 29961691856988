import React, { useContext, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ModalContext } from "../data/ModalContext";
import { Link } from "react-router-dom";
import { sendBookingRequest } from "../services/bookingService";
import { getCurrentUserId } from "../services/authService";

const ContactModal = () => {
  const { item, collection, client, email, modal, dispatch } = useContext(
    ModalContext
  );
  const user = getCurrentUserId();
  const emailRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();

  const handleSubmit = event => {
    event.preventDefault();
    const bookingRequest = {
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      message: messageRef.current.value,
      item,
      inventory: collection,
      user: user
    };
    sendBookingRequest(bookingRequest).then(result => {
      console.log(result);
      dispatch({ type: "toggle" });
    });
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={() => dispatch({ type: "toggle" })}
        className={"modal-dialog modal-sm modal-dialog-centered"}
      >
        <ModalHeader toggle={() => dispatch({ type: "toggle" })}>
          <p className="modal-title text-uppercase font-weight-bold">
            Inquiry about an item from {collection}
          </p>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Item ID: {item}</label>
              <input
                ref={emailRef}
                type="email"
                className="form-control bg-smoke"
                required=""
                placeholder={email}
                id="email-input"
                name="email"
              ></input>
            </div>
            <div className="form-group">
              <input
                ref={phoneRef}
                type="text"
                className="form-control bg-smoke"
                required=""
                placeholder="Phone Number"
                id="phone-input"
                name="phone"
              ></input>
            </div>
            <div className="form-group">
              <textarea
                ref={messageRef}
                className="form-control bg-smoke"
                rows="6"
                placeholder="Please tell us the best time and way to contact you..."
                id="message-input"
                name="message"
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary text-uppercase">
              Submit
            </button>
          </form>
        </ModalBody>
        <ModalFooter>
          <p className="mb-1">
            Client ID: <Link to="/dashboard/profile">{client}</Link>
          </p>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ContactModal;
