import React, { useState, useEffect, useRef, useContext } from "react";
import { Container, Card, CardBody } from "reactstrap";
import moment from "moment";
import { requestVip } from "../services/bookingService";
import {
  initCondoWarehouse,
  getStates,
  getCities,
  getResorts,
  searchInventory
} from "../services/serverCallsService";
import { getUserFromDB } from "../services/authService";
import VipSearchHeader from "../components/VipSearchHeader";
import VipPagination from "./VipPagination";
import InventoryRequest from "../components/InventoryRequest";
import InventoryRequestSent from "../components/InventoryRequestSent";
import VipInventoryListHeader from "../components/VipInventoryListHeader";
import BookingModal from "../modals/BookingModal";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ModalContext } from "../data/ModalContext";

const VipWeeks = props => {
  const { dispatch } = useContext(ModalContext);
  //const { toggleInquery } = props;
  //Refs
  const regionRef = useRef();
  const countryRef = useRef();
  const stateRef = useRef();
  const cityRef = useRef();
  const resortRef = useRef();
  const sleepsRef = useRef();
  const searchTextRef = useRef();
  //const invRef = useRef();
  const modalRef = useRef();

  //Values
  const [region, setRegion] = useState(0);
  const [country, setCountry] = useState(0);
  const [state, setState] = useState(0);
  const [city, setCity] = useState(0);
  const [resort, setResort] = useState(0);
  const [sleeps, setSleeps] = useState(0);
  const [startDate, setStartDate] = useState(moment());
  const [startFocused, setStartFocused] = useState(null);
  const [searchText, setSearchText] = useState("");

  //Toggles
  const [stateReadOnly, setStateReadOnly] = useState(true);
  const [cityReadOnly, setCityReadOnly] = useState(true);
  const [resortReadOnly, setResortReadOnly] = useState(true);
  //Select Item Lists
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [resorts, setResorts] = useState([]);
  const [unitSizes, setUnitSizes] = useState([]);
  const [searching, setSearching] = useState(true);
  const [requestSent, setRequestSent] = useState(false);
  //Inventory
  const [inventory, setInventory] = useState([]);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [user, setUser] = useState({});
  // eslint-disable-next-line
  const [vipRequest, setVipRequest] = useState({});
  const [inqueryInventoryId, setInqueryInventoryId] = useState("");
  // eslint-disable-next-line
  const [selectedId, setSelectedId] = useState("");
  function updateVipRequest() {
    setVipRequest({
      request: {
        country: countryRef.current.selectedOptions[0].getAttribute("datatext"),
        region: regionRef.current.selectedOptions[0].getAttribute("datatext"),
        state: stateRef.current.selectedOptions[0].getAttribute("datatext"),
        city: cityRef.current.selectedOptions[0].getAttribute("datatext"),
        resort: resortRef.current.selectedOptions[0].getAttribute("datatext"),
        unitSize: sleepsRef.current.selectedOptions[0].getAttribute("datatext"),
        afterDate: startDate.format("MM/DD/YYYY")
      },
      cwParams: {
        country,
        region,
        state,
        city,
        resort,
        sleeps,
        startDate: startDate.format()
      },
      user
    });
  }

  function getInventoryData() {
    const [selectedInventory, setSelectedInventory] = inventory.filter(
      inv => inv.inventory_id === selectedId
    );
    // console.log(selectedInventory);
    setInqueryInventoryId(selectedInventory.inventory_id);
    return Promise.resolve();
  }
  //Init
  useEffect(() => {
    setSearching(true);
    initCondoWarehouse().then(result => {
      //Independant
      setCountries(result.countries);
      setRegions(result.regions);
      setUnitSizes(result.unitSizes);

      const searchParams = {
        page: 1,
        items_per_page: 5
      };
      searchInventory(searchParams).then(inv => {
        setInventory(inv);
        setSearching(false);
      });
      //Get user and store to state
      getUserFromDB().then(result => {
        setUser(result.data);
      });
    });
  }, []);

  //Country
  useEffect(() => {
    setRequestSent(false);

    if (country !== 0) {
      setSearching(true);
      setRegion(0);
      setState(0);
      setCity(0);
      setResort(0);
      setSleeps(0);
      setResorts([]);
      const searchParams = {
        country_id: country,
        page: 1,
        items_per_page: 5
      };
      searchInventory(searchParams).then(inv => {
        setInventory(inv);
        setSearching(false);
      });
      // eslint-disable-next-line
      if (country == 6) {
        setCities([]);
        getStates().then(usStates => {
          setStates(usStates);
          setStateReadOnly(false);
        });
      } else {
        setStates([]);
        setStateReadOnly(true);
        getCities(country, state).then(countryCities => {
          setCities(countryCities);
        });
      }
    }
    // eslint-disable-next-line
  }, [country]);

  //Sleeps
  useEffect(() => {
    setRequestSent(false);
    if (sleeps !== 0) {
      setSearching(true);
      const searchParams = {
        country_id: country === 0 ? null : country,
        region_id: region === 0 ? null : region,
        state_id: state === 0 ? null : state,
        city_id: city === 0 ? null : city,
        resort_id: resort === 0 ? null : resort,
        unit_size_id: sleeps === 0 ? null : sleeps,
        page: 1,
        items_per_page: 5
      };

      searchInventory(searchParams).then(inv => {
        setInventory(inv);
        setSearching(false);
      });
    }
    // eslint-disable-next-line
  }, [sleeps]);
  //State
  useEffect(() => {
    setRequestSent(false);
    if (state !== 0) {
      setSearching(true);
      const searchParams = {
        state_id: state,
        unit_size_id: sleeps === 0 ? null : sleeps,
        page: 1,
        items_per_page: 5
      };
      searchInventory(searchParams).then(inv => {
        setInventory(inv);
        setSearching(false);
      });

      getCities(country, state).then(statesCities => {
        setCities(statesCities);
        setCityReadOnly(false);
      });
    } else {
      setCityReadOnly(true);
    }
    // eslint-disable-next-line
  }, [state]);
  //Region
  useEffect(() => {
    setRequestSent(false);
    if (region !== 0) {
      setSearching(true);
      setCountry(0);
      setState(0);
      setCity(0);
      setResort(0);
      setStates([]);
      setSleeps(0);
      setStateReadOnly(true);
      setCityReadOnly(true);
      getResorts(region, 0, 0, 0).then(resorts => {
        setResorts(resorts);
        setResortReadOnly(false);
      });

      const searchParams = {
        region_id: region,
        unit_size_id: sleeps === 0 ? null : sleeps,
        page: 1,
        items_per_page: 5
      };
      searchInventory(searchParams).then(inv => {
        setInventory(inv);
        setSearching(false);
      });
    } else {
    }
    // eslint-disable-next-line
  }, [region]);
  //City
  useEffect(() => {
    setRequestSent(false);
    if (city !== 0) {
      setSearching(true);
      setCityReadOnly(false);
      const searchParams = {
        city_id: city,
        unit_size_id: sleeps === 0 ? null : sleeps,
        page: 1,
        items_per_page: 5
      };
      searchInventory(searchParams).then(inv => {
        setInventory(inv);
        setSearching(false);
      });

      getResorts(region, country, state, city).then(resorts => {
        setResorts(resorts);
        setResortReadOnly(false);
      });
    } else {
      setCityReadOnly(true);
    }
    // eslint-disable-next-line
  }, [city]);
  //Resort
  useEffect(() => {
    setRequestSent(false);
    if (resort !== 0) {
      setSearching(true);
      const searchParams = {
        resort_id: resort,
        unit_size_id: sleeps === 0 ? null : sleeps,
        page: 1,
        items_per_page: 5
      };
      searchInventory(searchParams).then(inv => {
        setInventory(inv);
        setSearching(false);
      });
    }
    // eslint-disable-next-line
  }, [resort]);
  //Inventory
  useEffect(() => {
    if (inventory.length !== 0) {
      setPage(inventory[0].current_page);
      setRecords(inventory[0].total_records);
      setTotalPages(inventory[0].total_pages);
    } else {
      setPage(1);
      setRecords(0);
      setTotalPages(0);
    }
    // eslint-disable-next-line
  }, [inventory]);
  //PageChange
  useEffect(() => {
    setRequestSent(false);
    setSearching(true);
    const searchParams = {
      country_id: country === 0 ? null : country,
      region_id: region === 0 ? null : region,
      state_id: state === 0 ? null : state,
      city_id: city === 0 ? null : city,
      resort_id: resort === 0 ? null : resort,
      unit_size_id: sleeps === 0 ? null : sleeps,
      //check_in_date: startDate.format(),
      page: page,
      items_per_page: 5
    };
    searchInventory(searchParams).then(inv => {
      setInventory(inv);
      setSearching(false);
    });
    // eslint-disable-next-line
  }, [page]);
  //Date
  useEffect(() => {
    setRequestSent(false);
    setSearching(true);
    const searchParams = {
      country_id: country === 0 ? null : country,
      region_id: region === 0 ? null : region,
      state_id: state === 0 ? null : state,
      city_id: city === 0 ? null : city,
      unit_size_id: sleeps === 0 ? null : sleeps,
      resort_id: resort === 0 ? null : resort,
      check_in_date: startDate.format(),
      page: 1,
      items_per_page: 5
    };

    searchInventory(searchParams).then(inv => {
      setInventory(inv);
      setSearching(false);
    });
    // eslint-disable-next-line
  }, [startDate]);
  //SearchBar
  useEffect(() => {
    if (searchText !== "") {
      setSearching(true);
      const searchParams = {
        country_id: country === 0 ? null : country,
        region_id: region === 0 ? null : region,
        state_id: state === 0 ? null : state,
        city_id: city === 0 ? null : city,
        unit_size_id: sleeps === 0 ? null : sleeps,
        resort_id: resort === 0 ? null : resort,
        search: searchText,
        page: 1,
        items_per_page: 5
      };

      searchInventory(searchParams).then(inv => {
        setInventory(inv);
        setSearching(false);
      });
    }
    // eslint-disable-next-line
  }, [searchText]);

  //Send VIP Request
  useEffect(() => {
    // eslint-disable-next-line
    if (selectedId != "") {
      getInventoryData().then(result => {
        console.log(result);
        dispatch({
          type: "load-and-toggle",
          payload: { item: inqueryInventoryId }
        });

        setRequestSent(true);
      });
    }
    // eslint-disable-next-line
  }, [selectedId]);

  async function saveVipWeek(inv) {
    inv.item = `${inv.resort_id}-${inv.inventory_id}`;
    //console.log(inv);
    const result = await requestVip(inv);
    console.log(result);

    dispatch({
      type: "load-and-toggle",
      payload: {
        item: result._id,
        collection: "vip"
      }
    });
  }

  const inventoryList = inventory.map(inv => {
    return (
      <tr
        key={`${inv.resort_id}-${inv.inventory_id}`}
        style={{
          borderBottomColor: "#00000020",
          borderBottomStyle: "solid",
          borderBottomWidth: "1px"
        }}
      >
        <td className="m-2 pt-4 pb-4">
          <p className="mb-0 text-uppercase font-weight-bold text-dark">
            {inv.resort_name}
          </p>
          <p>
            {inv.resort_address_1}, {inv.resort_city} {inv.resort_state}
          </p>
          <Link
            to="/vip"
            onClick={() => saveVipWeek(inv)}
            className="text-uppercase"
          >
            ${Math.ceil(inv.price)}
          </Link>
        </td>
        <td className="m-2 pt-4">
          <p className="mb-0 text-uppercase text-dark">Room/Sleeps</p>
          <p>{inv.unit_size}</p>
        </td>
        <td className="m-2 pt-4">
          <p className="mb-0 text-uppercase text-dark">Arrive</p>
          <p>{moment(inv.check_in_date).format("MM/DD/YYYY")}</p>
        </td>
        <td className="m-2 pt-4">
          <p className="mb-0 text-uppercase text-dark">Depart</p>
          <p>
            {moment(inv.check_in_date)
              .add(7, "days")
              .format("MM/DD/YYYY")}
          </p>
        </td>
        <td className="m-2 pt-4">
          <span className="badge badge-pill badge-pill-sm align-middle">
            {inv.quantity_available} Available
          </span>
          <button
            className="btn btn-outline-primary ml-2 mr-2"
            onClick={() => saveVipWeek(inv)}

            //onClick={() => toggleInquery(inv.inventory_id)}
            //onClick={() =>
            //  toggleInquery("090909090909", "The Fox", "Juice", 9999)
            //}
          >
            Book
          </button>
        </td>
      </tr>
    );
  });

  return (
    <React.Fragment>
      <div className="main-wrapper mb-4 fade-in">
        <VipSearchHeader
          id="localseachbar"
          setCountry={setCountry}
          setRegion={setRegion}
          setState={setState}
          setCity={setCity}
          setResort={setResort}
          setSleeps={setSleeps}
          updateVipRequest={updateVipRequest}
          country={country}
          region={region}
          state={state}
          city={city}
          resort={resort}
          sleeps={sleeps}
          countryRef={countryRef}
          regionRef={regionRef}
          stateRef={stateRef}
          cityRef={cityRef}
          resortRef={resortRef}
          sleepsRef={sleepsRef}
          regions={regions}
          countries={countries}
          states={states}
          cities={cities}
          resorts={resorts}
          unitSizes={unitSizes}
          stateReadOnly={stateReadOnly}
          cityReadOnly={cityReadOnly}
          resortReadOnly={resortReadOnly}
        />
        <section>
          <Card>
            <VipInventoryListHeader
              startDate={startDate}
              setStartDate={setStartDate}
              startFocused={startFocused}
              setStartFocused={setStartFocused}
              searching={searching}
              records={records}
              requestSent={requestSent}
              updateVipRequest={updateVipRequest}
              searchText={searchText}
              searchTextRef={searchTextRef}
              setSearchText={setSearchText}
            />
            <CardBody>
              <VipPagination
                setPage={setPage}
                page={page}
                totalPages={totalPages}
                records={records}
              />
              <InventoryRequest
                records={records}
                requestSent={requestSent}
                updateVipRequest={updateVipRequest}
              />
              <InventoryRequestSent
                records={records}
                requestSent={requestSent}
              />
              <Container>
                <table className={"m-4"} style={{ width: "100%" }}>
                  <tbody>{inventoryList}</tbody>
                </table>
              </Container>
            </CardBody>
          </Card>
        </section>
        <VipPagination
          setPage={setPage}
          page={page}
          totalPages={totalPages}
          records={records}
        />
      </div>
      <BookingModal modalRef={modalRef} classNames={classNames} />
    </React.Fragment>
  );
};

export default VipWeeks;
