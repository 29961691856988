import React from "react";
import { Card, CardBody } from "reactstrap";

const InventoryRequest = ({ records, requestSent, updateVipRequest }) => {
  return (
    <React.Fragment>
      {records === 0 && requestSent === false && (
        <center>
          <div className="col-4">
            <Card className="shadow mb-5 mb-lg-0">
              <img
                className="card-img-top"
                src="/assets/img/home/deal/deal-01.jpg"
                alt="request"
              ></img>
              <CardBody>
                <h5 className="card-title">Special Request</h5>
                <p className="card-text">
                  Press the "Request Unseen Inventory" button to receive your
                  inventory search resuts from our other platforms.{" "}
                </p>
              </CardBody>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">hi</li>
              </ul>
              <CardBody className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => updateVipRequest()}
                >
                  Request Unseen Inventory
                </button>
              </CardBody>
            </Card>
          </div>
        </center>
      )}
    </React.Fragment>
  );
};

export default InventoryRequest;
