import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { addEmailToNewsLetter } from "../services/userService";

const Footer = () => {
  const emailRef = useRef();
  const [emailAdded, setEmailAdded] = useState(false);
  const [newsLetterMessage, setNewsLetterMessage] = useState(
    "Join the Global Newsletter"
  );

  async function handleSubmit(e) {
    e.preventDefault();
    setEmailAdded(true);
    try {
      const result = await addEmailToNewsLetter(emailRef.current.value);
      setNewsLetterMessage(result);
    } catch (ex) {}
  }

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="footer-bg-color py-9">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-3 mb-7 mb-lg-0">
                <Link className="d-inline-block" to="/">
                  <img
                    className="w-100 mb-6"
                    src="/assets/img/logo-color-sm.png"
                    alt="img"
                  ></img>
                </Link>
                <p className="mb-0">
                  We'll Lead the Way! Through the dizzying array of options for
                  your vacation and travel, Global Concepts works for you to
                  provide a multitude of options by integrating multiple
                  platforms to meet all of your needs! We also strive to
                  continue to build your options by seeking continued additions,
                  alliances, accreditation, and licensing.
                </p>
              </div>

              <div className="col-md-6 col-lg-3 mt-md-4 mb-7 mb-lg-0">
                <div className="title-tag">
                  <h6>Contact us</h6>
                </div>

                <p>
                  Contact Us Today To Inquire About Becoming An Exclusive
                  Client!
                </p>
                <ul className="list-unstyled mb-0">
                  <li className="media mb-2">
                    <div className="mr-3">
                      <i className="fa fa-home" aria-hidden="true"></i>
                    </div>
                    <div className="media-body">
                      <Link to="/contact">
                        503 E Ramsey Rd San Antonio, TX 78216
                      </Link>
                    </div>
                  </li>

                  <li className="media mb-2">
                    <div className="mr-3">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                    </div>
                    <div className="media-body">
                      <Link to="tel:2104374966">210-437-4966</Link>
                    </div>
                  </li>

                  <li className="media">
                    <div className="mr-3">
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    </div>
                    <div className="media-body">
                      <Link to="mailTo:info@myglobaltravel.com">
                        info@myglobaltravel.com
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-md-6 col-lg-3 mt-lg-4 mb-7 mb-md-0">
                <div className="title-tag pb-1">
                  <h6>Gallery</h6>
                </div>

                <div className="row mr-auto gallery mb-2 mb-md-0">
                  <div className="col-4 mb-3">
                    <div className="media media-hover">
                      <div className="content w-100">
                        <img
                          className="media-img"
                          data-src="/assets/img/home/gallery/thumb-gallery-1.jpg"
                          src="/assets/img/home/gallery/thumb-gallery-1.jpg"
                          alt="gallery-img"
                        ></img>
                      </div>
                    </div>
                  </div>

                  <div className="col-4 mb-3">
                    <div className="media media-hover">
                      <div className="content w-100">
                        <img
                          className="media-img"
                          data-src="/assets/img/home/gallery/thumb-gallery-2.jpg"
                          src="/assets/img/home/gallery/thumb-gallery-2.jpg"
                          alt="gallery-img"
                        ></img>
                        <Link
                          to="/contact"
                          className="media-img-overlay"
                          data-fancybox="gallery"
                        ></Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-4 mb-3">
                    <div className="media media-hover">
                      <div className="content w-100">
                        <img
                          className="media-img"
                          data-src="/assets/img/home/gallery/thumb-gallery-3.jpg"
                          src="/assets/img/home/gallery/thumb-gallery-3.jpg"
                          alt="gallery-img"
                        ></img>
                        <Link
                          to="/contact"
                          className="media-img-overlay"
                          data-fancybox="gallery"
                        ></Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="media media-hover">
                      <div className="content w-100">
                        <img
                          className="media-img"
                          data-src="/assets/img/home/gallery/thumb-gallery-4.jpg"
                          src="/assets/img/home/gallery/thumb-gallery-4.jpg"
                          alt="gallery-img"
                        ></img>
                        <Link
                          to="/contact"
                          className="media-img-overlay"
                          data-fancybox="gallery"
                        ></Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="media media-hover">
                      <div className="content w-100">
                        <img
                          className="media-img"
                          data-src="/assets/img/home/gallery/thumb-gallery-5.jpg"
                          src="/assets/img/home/gallery/thumb-gallery-5.jpg"
                          alt="gallery-img"
                        ></img>
                        <Link
                          to="/contact"
                          className="media-img-overlay"
                          data-fancybox="gallery"
                        ></Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="media media-hover">
                      <div className="content w-100">
                        <img
                          className="media-img"
                          data-src="/assets/img/home/gallery/thumb-gallery-6.jpg"
                          src="/assets/img/home/gallery/thumb-gallery-6.jpg"
                          alt="gallery-img"
                        ></img>
                        <Link
                          to="/contact"
                          className="media-img-overlay"
                          data-fancybox="gallery"
                        ></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 mt-lg-4">
                <div className="title-tag">
                  <h6>Newsletter</h6>
                </div>

                <p>{newsLetterMessage}</p>
                {emailAdded === false && (
                  <form className="mb-6" onSubmit={handleSubmit}>
                    <div className="input-group input-group-sm">
                      <input
                        type="email"
                        className="form-control form-control-sm form-transparent"
                        required=""
                        placeholder="Enter your email"
                        ref={emailRef}
                      ></input>

                      <div className="input-group-append">
                        <button
                          className="input-group-text border-0 btn btn-append hover-bg-primary"
                          type="submit"
                        >
                          <i
                            className="fa fa-long-arrow-right text-white"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </form>
                )}

                <ul className="list-inline d-flex mb-0">
                  <li className="mr-3 mr-lg-2 mr-xl-3">
                    <Link
                      to="/contact"
                      className="icon-default icon-border rounded-circle hover-bg-primary"
                    >
                      <i
                        className="fa fa-facebook text-white"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </li>

                  <li className="mr-3 mr-lg-2 mr-xl-3">
                    <Link
                      to="/contact"
                      className="icon-default icon-border rounded-circle hover-bg-primary"
                    >
                      <i
                        className="fa fa-twitter text-white"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </li>

                  <li className="mr-3 mr-lg-2 mr-xl-3">
                    <Link
                      to="/contact"
                      className="icon-default icon-border rounded-circle hover-bg-primary"
                    >
                      <i
                        className="fa fa-google-plus text-white"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </li>

                  <li className="mr-3 mr-lg-2 mr-xl-3">
                    <Link
                      to="/contact"
                      className="icon-default icon-border rounded-circle hover-bg-primary"
                    >
                      <i
                        className="fa fa-pinterest-p text-white"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </li>

                  <li className="">
                    <Link
                      to="/contact"
                      className="icon-default icon-border rounded-circle hover-bg-primary"
                    >
                      <i
                        className="fa fa-vimeo text-white"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright py-6">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 order-1 order-md-0">
                <p className="mb-0 mb-md-0 text-md-left">
                  Copyright &copy; 2019 All Rights Reserved by
                  <Link to="https://globalconcepts210.com" target="_blank">
                    Global Concepts
                  </Link>
                </p>
              </div>

              <div className="col-md-6">
                <ul className="list-inline text-capitalize d-flex align-items-center justify-content-md-end justify-content-center mb-md-0">
                  <li className="mr-3">
                    <Link to="/dashboard/settings">Privacy policy</Link>
                  </li>

                  <li className="mr-3">
                    <Link to="/dashboard">About us</Link>
                  </li>

                  <li className="mr-3">
                    <Link to={"/contact"}>Support</Link>
                  </li>

                  <li className="">
                    <Link to="/dashboard">Blog</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
