import React from "react";
import classnames from "classnames";

const BookingModal = ({ modalRef, className }) => {
  //const [isOpen, setIsOpen] = useState(false);
  //const [removeAtt, setRemoveAtt] = useState("aria-modal");
  //const [setAtt, setSetAtt] = useState("aria-hidden");
  //const [modalStyle, setModalStyle] = useState("");

  return (
    <div
      ref={modalRef}
      className={classnames("modal fade", className)}
      id="inquiry"
      role="dialog"
      aria-label="inquiryModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-sm modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header rounded">
            <h3 className="modal-title text-uppercase font-weight-bold">
              Inquiry about tour
            </h3>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              //onClick={() => setIsOpen(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body pb-3">
            <form className="" action="index.html" method="post">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control bg-smoke"
                  required=""
                  placeholder="Your Name"
                ></input>
              </div>

              <div className="form-group">
                <input
                  type="email"
                  className="form-control bg-smoke"
                  required=""
                  placeholder="Your Email"
                ></input>
              </div>

              <div className="form-group">
                <input
                  type="number"
                  className="form-control bg-smoke"
                  required=""
                  placeholder="Phone Number"
                ></input>
              </div>

              <div className="form-group">
                <textarea
                  className="form-control bg-smoke"
                  rows="6"
                  placeholder="Message"
                ></textarea>
              </div>

              <button type="submit" className="btn btn-primary text-uppercase">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;
