import React from "react";
import _ from "lodash";
import PoiRecomendations from "./PoiRecomendations";

const ResortTab = ({ resort, activeTab }) => {
  const recItems = resort.recreationOnsite.map(item => {
    if (item === null) return null;
    return (
      <li key={item} className="media media-list-content">
        <div className="">
          <i className="fa fa-square" aria-hidden="true"></i>
        </div>
        <div className="media-body">
          <p className="mb-0">{item}</p>
        </div>
      </li>
    );
  });
  const recCutPoint = Math.ceil((recItems.length - 2) / 2);
  const recColOne = _.slice(recItems, 0, recCutPoint);
  const recColTwo = _.slice(recItems, recCutPoint + 1, recItems.length);

  const amenItems = resort.amenitiesOnsite.map(item => {
    // eslint-disable-next-line
    if (item === null) return;
    return (
      <li key={item} className="media media-list-content">
        <div className="">
          <i className="fa fa-square" aria-hidden="true"></i>
        </div>
        <div className="media-body">
          <p className="mb-0">{item}</p>
        </div>
      </li>
    );
  });
  const amenCutPoint = Math.ceil((amenItems.length - 2) / 2);
  const amenColOne = _.slice(amenItems, 0, amenCutPoint);
  const amenColTwo = _.slice(amenItems, amenCutPoint + 1, amenItems.length);
  // eslint-disable-next-line
  const spAssItems = resort.specialAssistInfo.map(item => {
    // eslint-disable-next-line
    if (item === null) return;
    for (let [key, value] of Object.entries(item)) {
      return (
        <li className="media media-list-content">
          <div className="">
            <i className="fa fa-square" aria-hidden="true"></i>
          </div>
          <div className="media-body">
            <p className="mb-0">{`${key}: ${value}`}</p>
          </div>
        </li>
      );
    }
  });
  const spAssCutPoint = Math.ceil((spAssItems.length - 2) / 2);
  const spAssColOne = _.slice(spAssItems, 0, spAssCutPoint);
  const spAssColTwo = _.slice(spAssItems, spAssCutPoint + 1, spAssItems.length);

  return (
    <div
      className="tab-pane"
      id="overView"
      role="tabpanel"
      aria-labelledby="overView-tab"
    >
      <div className="row mb-5 ">
        <div className="col-md-7 col-lg-8 animated fadeIn">
          <h3 className="text-uppercase mb-5 ">{resort.resortName}</h3>

          <p className="mb-5">{resort.resortUnitInfo}</p>
          {resort.recreationOnsite[0] != null && (
            <React.Fragment>
              <h5 className="text-uppercase mb-3 mt-5">Resort Amenities</h5>
              <div className="mb-6 mb-md-0">
                <div className="row">
                  <div className="col-lg-6">
                    <ul className="list-unstyled mb-0">{amenColOne}</ul>
                  </div>
                  <div className="col-lg-6">
                    <ul className="list-unstyled mb-0">{amenColTwo}</ul>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          {resort.recreationOnsite[0] != null && (
            <React.Fragment>
              <h5 className="text-uppercase mb-3 mt-5">Resort Activities</h5>
              <div className="mb-6 mb-md-0">
                <div className="row">
                  <div className="col-lg-6">
                    <ul className="list-unstyled mb-0">{recColOne}</ul>
                  </div>

                  <div className="col-lg-6">
                    <ul className="list-unstyled mb-0">{recColTwo}</ul>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {resort.specialAssistInfo[0] === 99999 && (
            <React.Fragment>
              <h5 className="text-uppercase mb-3 mt-5">Special Assistance</h5>
              <div className="mb-6 mb-md-0">
                <div className="row">
                  <div className="col-lg-6">
                    <ul className="list-unstyled mb-0">yy{spAssColOne}</ul>
                  </div>
                  <div className="col-lg-6">
                    <ul className="list-unstyled mb-0">cc{spAssColTwo}</ul>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        <PoiRecomendations resort={resort} id={resort._id} />
      </div>
    </div>
  );
};

export default ResortTab;
