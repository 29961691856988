import React, { useState, useEffect } from "react";
import moment from "moment";
import { Progress } from "reactstrap";
import useSocket from "use-socket.io-client";
//import openSocket from "socket.io-client";
import { getStatus, startCompassUpdate } from "../services/userService";

const CompassUpdater = () => {
  const [socket] = useSocket("https://xuya2.com", {
    transports: ["websocket"]
  });

  const [clearAllAvailableIds, setClearAllAvailableIds] = useState(0);
  const [getListOfPricedResorts, setGetListOfPricedResorts] = useState(0);
  const [saveAvailable, setSaveAvailable] = useState(0);
  const [getListOfLastCallResorts, setGetListOfLastCallResorts] = useState(0);
  const [saveLastCallAvailable, setSaveLastCallAvailable] = useState(0);
  const [markAllResortsFalse, setMarkAllResortsFalse] = useState(0);
  const [markAvailableResorts, setMarkAvailableResorts] = useState(0);
  const [removeNoImgResorts, setRemoveNoImgResorts] = useState(0);
  const [lastUpdate, setLastUpdate] = useState("");
  const [phase, setPhase] = useState("");

  socket.connect();

  useEffect(() => {
    getStatus().then(result => {
      setClearAllAvailableIds(result.data.clearAllAvailableIds.percent);
      setGetListOfPricedResorts(result.data.getListOfPricedResorts.percent);
      setSaveAvailable(result.data.saveAvailable.percent);
      setGetListOfLastCallResorts(result.data.getListOfLastCallResorts.percent);
      setSaveLastCallAvailable(result.data.saveLastCallAvailable.percent);
      setMarkAllResortsFalse(result.data.markAllResortsFalse.percent);
      setMarkAvailableResorts(result.data.markAvailableResorts.percent);
      setRemoveNoImgResorts(result.data.removeNoImgResorts.percent);
      setLastUpdate(result.data.created_on);
    });
  }, []);

  useEffect(() => {
    socket.on("clearAllAvailableIds", message => {
      setClearAllAvailableIds(message);
      setPhase("clearAllAvailableIds");
    });
    socket.on("getListOfPricedResorts", message => {
      setGetListOfPricedResorts(message);
      setPhase("getListOfPricedResorts");
    });
    socket.on("saveAvailable", message => {
      setSaveAvailable(message);
      setPhase("saveAvailable");
    });
    socket.on("getListOfLastCallResorts", message => {
      setGetListOfLastCallResorts(message);
      setPhase("getListOfLastCallResorts");
    });
    socket.on("saveLastCallAvailable", message => {
      setSaveLastCallAvailable(message);
      setPhase("saveLastCallAvailable");
    });
    socket.on("markAllResortsFalse", message => {
      setMarkAllResortsFalse(message);
      setPhase("markAllResortsFalse");
    });
    socket.on("markAvailableResorts", message => {
      setMarkAvailableResorts(message);
      setPhase("markAvailableResorts");
    });
    socket.on("removeNoImgResorts", message => {
      setRemoveNoImgResorts(message);
      setPhase("removeNoImgResorts");
    });
    // eslint-disable-next-line
  }, [
    clearAllAvailableIds,
    getListOfPricedResorts,
    saveAvailable,
    getListOfLastCallResorts,
    saveLastCallAvailable,
    markAllResortsFalse,
    markAvailableResorts,
    removeNoImgResorts
  ]);

  async function startUpdate() {
    setClearAllAvailableIds(0);
    setGetListOfPricedResorts(0);
    setSaveAvailable(0);
    setGetListOfLastCallResorts(0);
    setSaveLastCallAvailable(0);
    setMarkAllResortsFalse(0);
    setMarkAvailableResorts(0);
    setRemoveNoImgResorts(0);
    await startCompassUpdate();
  }

  return (
    <div className="col-lg-6">
      <div className="mb-4">
        <h3 className="text-uppercase mb-4">Update Compass Weeks</h3>
        <p className="text-primary mb-0">
          Last Update Executed:{" "}
          <span>{moment(lastUpdate).format("MM/DD/YYY - HH:mm:ss")}</span>
        </p>
        <div className="pt-2">
          <button
            onClick={() => startUpdate()}
            className="btn btn-primary text-uppercase font-size-15 px-6 px-md-7 shadow-lg"
          >
            Run Updater
          </button>
        </div>
      </div>
      <div>
        <div className="text-center">
          Clearing Inventory - {clearAllAvailableIds}%
        </div>
        <Progress
          value={clearAllAvailableIds}
          animated={phase === "clearAllAvailableIds" ? true : false}
          color="danger"
        />
        <div className="text-center">
          Creating List of Priced Resorts - {getListOfPricedResorts}%
        </div>
        <Progress
          value={getListOfPricedResorts}
          animated={phase === "getListOfPricedResorts" ? true : false}
          color="primary"
        />
        <div className="text-center">
          Saving Priced Resorts - {saveAvailable}%
        </div>
        <Progress
          value={saveAvailable}
          animated={phase === "saveAvailable" ? true : false}
          color="success"
        />
        <div className="text-center">
          Creating List of Last Call Resorts - {getListOfLastCallResorts}%
        </div>
        <Progress
          value={getListOfLastCallResorts}
          animated={phase === "getListOfLastCallResorts" ? true : false}
          color="primary"
        />
        <div className="text-center">
          Saving Last Call Resorts - {saveLastCallAvailable}%
        </div>
        <Progress
          value={saveLastCallAvailable}
          animated={phase === "saveLastCallAvailable" ? true : false}
          color="success"
        />
        <div className="text-center">
          Preparing Saved Inventory - {markAllResortsFalse}%
        </div>
        <Progress
          value={markAllResortsFalse}
          animated={phase === "markAllResortsFalse" ? true : false}
          color="danger"
        />
        <div className="text-center">
          Verifying Saved Inventory - {markAvailableResorts}%
        </div>
        <Progress
          value={markAvailableResorts}
          animated={phase === "markAvailableResorts" ? true : false}
          color="primary"
        />
        <div className="text-center">
          Removing Unverified - {removeNoImgResorts}%
        </div>
        <Progress
          value={removeNoImgResorts}
          animated={phase === "removeNoImgResorts" ? true : false}
          color="success"
        />
      </div>
    </div>
  );
};

export default CompassUpdater;
