import React from "react";
import _ from "lodash";
import Benefits from "./Benefits";

const LocationTab = ({ resort }) => {
  const recItems = resort.recreationOffsite.map(item => {
    if (item === null) item = "";
    return (
      <li key={item} className="media media-list-content">
        <div className="">
          <i className="fa fa-square" aria-hidden="true"></i>
        </div>

        <div className="media-body">
          <p className="mb-0">{item}</p>
        </div>
      </li>
    );
  });

  const recCutPoint = Math.ceil((recItems.length - 2) / 2);
  const recColOne = _.slice(recItems, 0, recCutPoint);
  const recColTwo = _.slice(recItems, recCutPoint + 1, recItems.length);

  const amenItems = resort.amenitiesOffsite.map(item => {
    // eslint-disable-next-line
    if (item === null) return;
    return (
      <li className="media media-list-content">
        <div className="">
          <i className="fa fa-square" aria-hidden="true"></i>
        </div>
        <div className="media-body">
          <p className="mb-0">{item}</p>
        </div>
      </li>
    );
  });
  const amenCutPoint = Math.ceil((amenItems.length - 2) / 2);
  const amenColOne = _.slice(amenItems, 0, amenCutPoint);
  const amenColTwo = _.slice(amenItems, amenCutPoint + 1, amenItems.length);

  return (
    <div
      className="tab-pane"
      id="overView"
      role="tabpanel"
      aria-labelledby="overView-tab"
    >
      <div className="row mb-5">
        <div className="col-md-7 col-lg-8 animated fadeIn">
          <h3 className="text-uppercase mb-5">{resort.area.region}</h3>
          <p className="mb-5">{resort.areaInfo}</p>
          {resort.amenitiesOffsite[0] != null && (
            <React.Fragment>
              <h5 className="text-uppercase mb-3 mt-5">Area Amenities</h5>
              <div className="mb-6 mb-md-0">
                <div className="row">
                  <div className="col-lg-6">
                    <ul className="list-unstyled mb-0">{amenColOne}</ul>
                  </div>

                  <div className="col-lg-6">
                    <ul className="list-unstyled mb-0">{amenColTwo}</ul>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          {resort.recreationOffsite[0] != null && (
            <React.Fragment>
              <h5 className="text-uppercase mb-3 mt-5">Area Activities</h5>
              <div className="mb-6 mb-md-0">
                <div className="row">
                  <div className="col-lg-6">
                    <ul className="list-unstyled mb-0">{recColOne}</ul>
                  </div>

                  <div className="col-lg-6">
                    <ul className="list-unstyled mb-0">{recColTwo}</ul>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <Benefits resort={resort} />
      </div>
    </div>
  );
};

export default LocationTab;
