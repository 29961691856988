import React from "react";
import { Link } from "react-router-dom";

const SelectRoom = ({ handleSelectCabin, cabin }) => {
  return (
    <div className="row justify-content-center mb-4">
      <div
        className="col-md-6 col-lg-3"
        onClick={() => handleSelectCabin("INTERIOR")}
      >
        <div
          className={`card h-100 card-bg mb-5 mb-lg-0 ${
            cabin === "INTERIOR" ? "hover-select" : false
          }`}
        >
          <Link
            onClick={() => handleSelectCabin("INTERIOR")}
            className="position-relative"
          >
            <img
              className="card-img-top"
              src="https://www.goldcrownresort.com/images/en/cruise/interior.jpg"
              alt="Interior"
            ></img>
            <div className="card-img-overlay card-hover-overlay rounded-top d-flex flex-column"></div>
          </Link>

          <div className="card-body">
            <h5>
              <Link
                onClick={() => handleSelectCabin("INTERIOR")}
                className="card-title text-uppercase"
              >
                INTERIOR
              </Link>
            </h5>

            <p className="mb-2">
              Interior cabins do not have windows or portholes. Cabins typically
              have private bathrooms, closets, TV, twin beds, and vanity area.
            </p>
          </div>
          <div className="card-footer">
            <Link
              onClick={() => handleSelectCabin("INTERIOR")}
              className="btn btn-xs btn-outline-secondary text-uppercase"
            >
              Select Cabin
            </Link>
          </div>
        </div>
      </div>

      <div
        className="col-md-6 col-lg-3"
        onClick={() => handleSelectCabin("OCEAN VIEW")}
      >
        <div
          className={`card h-100 card-bg mb-5 mb-lg-0 ${
            cabin === "OCEAN VIEW" ? "hover-select" : false
          }`}
        >
          <Link
            onClick={() => handleSelectCabin("OCEAN VIEW")}
            className="position-relative"
          >
            <img
              className="card-img-top"
              src="https://www.goldcrownresort.com/images/en/cruise/oceanview.jpg"
              alt="Ocean"
            ></img>
            <div className="card-img-overlay card-hover-overlay rounded-top d-flex flex-column"></div>
          </Link>

          <div className="card-body h-100">
            <h5>
              <Link
                onClick={() => handleSelectCabin("OCEAN VIEW")}
                className="card-title text-uppercase"
              >
                OCEAN VIEW
              </Link>
            </h5>

            <p className="mb-2">
              Ocean View cabins have a window or porthole. Some ocean view
              cabins may have a tender or lifeboat in front of the window.
              Cabins typically have private bathrooms, closet, TV, twin beds,
              and vanity area.
            </p>
          </div>
          <div className="card-footer">
            <Link
              onClick={() => handleSelectCabin("OCEAN VIEW")}
              className="btn btn-xs btn-outline-secondary text-uppercase"
            >
              Select Cabin
            </Link>
          </div>
        </div>
      </div>

      <div
        className="col-md-6 col-lg-3"
        onClick={() => handleSelectCabin("BALCONY")}
      >
        <div
          className={`card h-100 card-bg mb-5 mb-lg-0 ${
            cabin === "BALCONY" ? "hover-select" : false
          }`}
        >
          <Link
            onClick={() => handleSelectCabin("BALCONY")}
            className="position-relative"
          >
            <img
              className="card-img-top"
              src="https://www.goldcrownresort.com/images/en/cruise/balcony.jpg"
              alt="Balcony"
            ></img>
            <div className="card-img-overlay card-hover-overlay rounded-top d-flex flex-column"></div>
          </Link>

          <div className="card-body h-100">
            <h5>
              <Link
                onClick={() => handleSelectCabin("BALCONY")}
                className="card-title text-uppercase"
              >
                BALCONY
              </Link>
            </h5>

            <p className="mb-2">
              Balcony cabins have a private balcony. Some balcony cabins may be
              above a tender or lifeboat. Balcony typically have private
              bathrooms, closets, TV, twin beds, and vanity area.
            </p>
          </div>
          <div className="card-footer">
            <Link
              onClick={() => handleSelectCabin("BALCONY")}
              className="btn btn-xs btn-outline-secondary text-uppercase"
            >
              Select Cabin
            </Link>
          </div>
        </div>
      </div>

      <div
        className="col-md-6 col-lg-3"
        onClick={() => handleSelectCabin("SUITE")}
      >
        <div
          className={`card h-100 card-bg mb-5 mb-lg-0 ${
            cabin === "SUITE" ? "hover-select" : false
          }`}
        >
          <Link
            onClick={() => handleSelectCabin("SUITE")}
            className="position-relative"
          >
            <img
              className="card-img-top"
              src="https://www.goldcrownresort.com/images/en/cruise/suite.jpg"
              alt="Suite"
            ></img>
            <div className="card-img-overlay card-hover-overlay rounded-top d-flex flex-column"></div>
          </Link>

          <div className="card-body h-100">
            <h5>
              <Link
                onClick={() => handleSelectCabin("SUITE")}
                className="card-title text-uppercase"
              >
                suite
              </Link>
            </h5>

            <p className="mb-2">
              Suites are the most spacious cabins. Suites have a private
              balcony. Some suites may also have additional amenities such as
              priority boarding and concierge service, large sitting areas,
              walk-in closets, and bathrooms with whirlpool tubs
            </p>
          </div>
          <div className="card-footer">
            <Link
              onClick={() => handleSelectCabin("SUITE")}
              className="btn btn-xs btn-outline-secondary text-uppercase"
            >
              Select Cabin
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectRoom;
