import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
//import _ from "lodash";

const CompassList = ({ resorts }) => {
  function convertImgUrl(imgurl) {
    if (imgurl !== "") {
      return `/compass/resorts/${imgurl}`
      // return imgurl.replace(
      //   "https://www.rci.com/static/Resorts/Assets",
      //   "/compass/resorts"
      // );
    } else {
      return;
    }
  }
  if (resorts?.length < 1) {
    return <h3>Loading Compass Weeks...</h3>;
  } else {
    return resorts.map(area => {
      const countries = area.resorts.map(resort => {
        return (
          <div
            key={resort._id}
            className="media media-list-view-resorts media-border flex-column flex-md-row align-items-stretch mb-5 shadow"
          >
            <div className="media-img position-relative ">
              <img
                width="75"
                height="75"
                className="img-fluid position-relative "
                src={resort.img ? convertImgUrl(resort.img) : ""}
                // src={resort.img ? convertImgUrl(resort.img) : ""}
                alt="gallery-img"
              ></img>
              <Link
                className="media-img-overlay"
                data-fancybox="gallery"
                to={`/compass/resort/${resort._id}`}
              ></Link>
            </div>

            <div className="media-body">
              <div className="row">
                <div className="col-md-7 col-xl-8">
                  <h4>
                    <Link
                      className="media-title"
                      to={`/compass/resort/${resort._id}`}
                    >
                      {resort.resortName}
                    </Link>
                  </h4>

                  <p className="mb-md-0">{`${resort.region !== null
                    ? `${resort.region},`
                    : resort.district !== null
                      ? `${resort.district},`
                      : ""
                    } ${resort.country}`}</p>
                  <div className="date-view">
                    <span>
                      <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                      {moment(resort.startDate).format("MMM Do YYYY")}
                    </span>{" "}
                    -
                    <span>
                      <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                      {moment(resort.endDate).format("MMM Do YYYY")}
                    </span>
                  </div>
                </div>

                <div className="col-md-5 col-xl-4">
                  <div className="bg-primary text-center p-3 view-details">
                    <h2 className="text-white font-weight-bold h1">
                      ${Math.ceil(resort.price)}
                    </h2>
                    <p className="text-white text-capitalize">Starting At</p>
                    <div>
                      <Link
                        to={`/compass/resort/${resort._id}`}
                        className="btn btn-xs btn-block btn-outline-white btn-booking text-uppercase mb-2"
                      >
                        Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });

      return (
        <div className="animated fadeIn slow" key={area.area}>
          <h2 className="">{area.area}</h2>

          {countries}
        </div>
      );
    });
  }
  //return <React.Fragment>{displayCountries}</React.Fragment>;
};

export default CompassList;
