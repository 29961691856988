import React, { useEffect, useContext } from "react";
import ReactTable from "react-table";
import { Row, Card, CardBody } from "reactstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  getRequests,
  getRequest,
  getAgents,
  getFreeText
} from "../../services/bookingService";
//import CustomSelect from "../../custom/CustomSelect";
import { BookingRequestContext } from "../../data/ModalContext";

const BookingRequestTable = () => {
  const {
    setBRmodal,
    setSelectedRequest,
    selectedRequest,
    setPage,
    setSelectedClient,
    setSelectedRecordId,
    setItemFreeText,
    setSelectedAgent,
    selectedAgent,
    setSelectedStatus,
    pages,
    setPages,
    loading,
    setLoading,
    setAgents,
    tableData,
    setTableData
  } = useContext(BookingRequestContext);

  let pageSize = 10;

  useEffect(() => {
    getRequests(1, pageSize).then(res => {
      setTableData(res.list);
      setPages(res.pages);
      setLoading(false);
    });
    getAgents().then(setAgents);

    // eslint-disable-next-line
  }, []);

  const tableColumns = [
    {
      Header: "Client",
      columns: [
        {
          Header: "Date",
          accessor: "created_on",
          maxWidth: 80,
          Cell: row => (
            <span>{moment(row.original.created_on).format("MM/DD/YY")}</span>
          ),
          className: "text-center"
        },
        {
          Header: "Last Name",
          accessor: "user.last",
          className: "text-center"
        },
        {
          Header: "User",
          accessor: "user.email",
          className: "text-center"
        }
      ]
    },
    {
      Header: "Inv",
      columns: [
        {
          Header: "Inventory",
          accessor: "inventory",
          className: "text-center"
        }
      ]
    },
    {
      Header: "Office",
      columns: [
        {
          Header: "Assigned To",
          accessor: "assigned",
          Cell: row =>
            row.original.assigned === true && (
              <span>
                {"agent" in row.original
                  ? row.original.agent.last && (
                      <span>
                        {row.original.agent.last}, {row.original.agent.name}
                      </span>
                    )
                  : ""}
              </span>
            ),
          className: "text-center"
        },
        {
          Header: "Responded",
          accessor: "responded",
          Cell: row =>
            row.original.responded === true && (
              <FontAwesomeIcon icon={faCheckCircle} />
            ),
          className: "text-center",
          maxWidth: 100
        },
        {
          Header: "Booked",
          accessor: "booked",
          Cell: row =>
            row.original.booked === true && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "rgb(56,208,102)" }}
              />
            ),
          className: "text-center",
          maxWidth: 100
        }
      ]
    }
  ];

  return (
    <React.Fragment>
      <Card className="p-0 shadow-lg">
        <CardBody className="p-0">
          <Row>
            <div className="col-lg-12">
              <ReactTable
                className="-striped -highlight"
                columns={tableColumns}
                data={tableData}
                pageSize={pageSize}
                showPageSizeOptions={false}
                resizable={false}
                pages={pages} // should default to -1 (which means we don't know how many pages we have)
                loading={loading}
                manual // informs React Table that you'll be handling sorting and pagination server-side
                onFetchData={(state, instance) => {
                  // show the loading overlay
                  setLoading(true);
                  setPage(state.page);
                  // fetch your data
                  getRequests(state.page + 1, pageSize).then(res => {
                    // Update react-table
                    setTableData(res.list);
                    setPages(res.pages);
                    setLoading(false);
                  });
                }}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (rowInfo) {
                        setLoading(true);
                        //Store current Page State
                        setPage(state.page);

                        //Set Client/User ID
                        setSelectedClient(rowInfo.original.user._id);

                        //-NOT SURE WHAT I DID HERE
                        setBRmodal(true);

                        //Set Request ID
                        setSelectedRecordId(rowInfo.original._id);

                        //Get and then Set Request OBJECT from ID
                        getRequest(rowInfo.original._id)
                          .then(setSelectedRequest)
                          .then(selected => {
                            try {
                              setSelectedAgent(selected.agent);
                            } catch (ex) {
                              console.log("null");
                            }
                          });
                        //Destructure agent ID - if one is assigned
                        const { agent, ...request } = selectedRequest;

                        if (agent) console.log("sel", agent);
                        //, selectedAgent);
                        else console.log("not assigned"); //, selectedAgent);

                        // selectedRequest => {

                        //   if (agent) setSelectedAgent(agent);

                        //   console.log("sel", selectedAgent);
                        // });

                        //setSelectedStatus(selectedResult.status);
                        getRequests(state.page + 1, pageSize).then(res => {
                          // Update react-table
                          setTableData(res.list);
                          setPages(res.pages);
                          setLoading(false);
                        });

                        getFreeText(rowInfo.original._id).then(ftResult => {
                          setItemFreeText(ftResult);
                        });
                      }
                    }
                  };
                }}
              />
            </div>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default BookingRequestTable;
