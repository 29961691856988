import React from "react";
import { Link } from "react-router-dom";

const CruiseProgress = ({ step, handleChangeToStep }) => {
  return (
    <div className="mb-8">
      <div className="row progress-wizard">
        <div
          className={`col-4 progress-wizard-step ${
            step === 1 ? "active" : "complete"
          }`}
        >
          <div className="progress">
            <div className="progress-bar"></div>
          </div>

          <Link
            onClick={() => handleChangeToStep(1)}
            className="progress-wizard-dot"
          >
            <div className="progress-wizard-content">
              <i className="fa fa-user" aria-hidden="true"></i>
              <span className="d-block">1. Cruise History</span>
            </div>
          </Link>
        </div>

        <div
          className={`col-4 progress-wizard-step ${
            step === 1 ? "incomplete" : step > 2 ? "complete" : "active"
          }`}
        >
          <div className="progress">
            <div className="progress-bar"></div>
          </div>

          <Link
            onClick={() => handleChangeToStep(2)}
            className="progress-wizard-dot"
          >
            <div className="progress-wizard-content">
              <i className="fa fa-usd" aria-hidden="true"></i>
              <span className="d-block">2. Passenger Info</span>
            </div>
          </Link>
        </div>

        <div
          className={`col-4 progress-wizard-step ${
            step < 3 ? "incomplete" : "active"
          } `}
        >
          <div className="progress">
            <div className="progress-bar"></div>
          </div>

          <Link
            onClick={() => handleChangeToStep(3)}
            className="progress-wizard-dot"
          >
            <div className="progress-wizard-content">
              <i className="fa fa-check" aria-hidden="true"></i>
              <span className="d-block">3. Confirmation</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CruiseProgress;
