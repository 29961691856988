import React from "react";

const PageTitle = ({ title, titleImg }) => {
  return (
    <section className="page-title">
      <div
        className="page-title-img bg-img bg-overlay-darken"
        style={{ backgroundImage: "url(" + titleImg + ")" }}
      >
        <div className="container">
          <div
            className="row align-items-center justify-content-center"
            style={{ height: "200px" }}
          >
            <div className="col-lg-6">
              <div className="page-title-content">
                <div className="">
                  <h2 className="text-uppercase text-white font-weight-bold">
                    {title}
                  </h2>
                </div>
                <p className="text-white mb-0"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
