import React, { useRef, useState, useEffect } from "react";
//import cookie from "react-cookies";
import { Link } from "react-router-dom";
import { sendUserQuestion } from "../services/bookingService";
import { getMe } from "../services/userService";
import {
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
  Label,
  CardFooter
} from "reactstrap";

const UserProfile = () => {
  const questionRef = useRef();
  const [messageSent, setMessageSent] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [user, setUser] = useState({});

  useEffect(() => {
    getMe().then(setUser);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setMessageSent(true);
    try {
      await sendUserQuestion(questionRef.current.value);
      setResultMessage("Question Sent: An agent will contact you shortly");
    } catch (ex) {
      setResultMessage("Something went wrong, Please call us at: 210-437-4966");
    }
  }
  //let match = useRouteMatch();
  //const user = cookie.load("_usrobj");

  if (user) {
    return (
      <section className="py-6 fade-in">
        <div className="container ">
          <div className="bg-smoke p-2 p-md-6 pb-6 pb-md-8 mb-9 rounded">
            <div className=" hover-profile mb-7 mb-lg-9">
              <div className="row">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <div className="mb-6 mb-md-0">
                    <Card className="shadow">
                      <img
                        className="w-100"
                        src="/assets/img/blog/blog-06.jpg"
                        alt="profile"
                      ></img>

                      <CardHeader>
                        <center>
                          <h2>Have a question?</h2>
                        </center>
                      </CardHeader>
                      <CardBody>
                        {messageSent === false && (
                          <form onSubmit={e => handleSubmit(e)}>
                            <FormGroup>
                              <Label>Ask it here</Label>
                              <Input innerRef={questionRef} type="text"></Input>
                            </FormGroup>
                            <FormGroup>
                              <button className="btn btn-primary">
                                Submit
                              </button>
                            </FormGroup>
                          </form>
                        )}
                        {messageSent === true && <h3>{resultMessage}</h3>}
                      </CardBody>
                    </Card>
                  </div>
                </div>

                <div className="col-md-6 col-lg-7 col-xl-8">
                  <Card className="shadow p-3 mh-100">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <div className="mb-6 mb-md-4 mb-lg-6">
                        <h2 className="text-uppercase font-weight-bold">{`${user.name} ${user.last}`}</h2>
                        <p className="mb-0">{`${user.level} Client`}</p>
                      </div>

                      <div className="icon-setting">
                        <Link to="/dashboard/settings">
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        </Link>
                      </div>
                    </div>
                    <CardBody>
                      <ul className="list-unstyled">
                        <li className="media media-profile-list">
                          <strong>First Name:</strong>

                          <div className="media-body ml-2">
                            <span className="text-gray-color">{user.name}</span>
                          </div>
                        </li>

                        <li className="media media-profile-list">
                          <strong>Last Name:</strong>

                          <div className="media-body ml-2">
                            <span className="text-gray-color">{user.last}</span>
                          </div>
                        </li>

                        <li className="media media-profile-list">
                          <strong>Email:</strong>

                          <div className="media-body ml-2">
                            <Link
                              to="mail:to"
                              className="text-gray-color hover-text-primary"
                            >
                              {user.email}
                            </Link>
                          </div>
                        </li>
                      </ul>
                      {/*
                      <ul className="list-inline d-flex mb-0">
                        <li className="mr-3 mr-lg-2 mr-xl-3">
                          <Link className="icon-default icon-light-border rounded-circle hover-bg-primary">
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>

                        <li className="mr-3 mr-lg-2 mr-xl-3">
                          <Link className="icon-default icon-light-border rounded-circle hover-bg-primary">
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </Link>
                        </li>

                        <li className="mr-3 mr-lg-2 mr-xl-3">
                          <Link className="icon-default icon-light-border rounded-circle hover-bg-primary">
                            <i className="fa fa-rss" aria-hidden="true"></i>
                          </Link>
                        </li>

                        <li className="">
                          <Link className="icon-default icon-light-border rounded-circle hover-bg-primary">
                            <i className="fa fa-vimeo" aria-hidden="true"></i>
                          </Link>
                        </li>
                      </ul>
                    */}
                    </CardBody>
                    <CardFooter></CardFooter>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default UserProfile;
