import React from "react";

const Pagination = ({ currentPage, handlePageChange, numberOfItems }) => {
  const pages = Math.ceil(numberOfItems / 20);
  console.log(pages);
  return (
    <section className="pt-5 pt-md-7">
      <div className="container">
        <nav aria-label="Page navigation">
          <ul className="pagination justify-content-center align-items-center">
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <i
                  className="fa fa-long-arrow-left d-none d-md-inline-block mr-md-1"
                  aria-hidden="true"
                ></i>{" "}
                Previous
              </button>
            </li>

            <li className="page-item">
              <button className="page-link active">{currentPage}</button>
            </li>

            <li className="page-item">
              <button className="page-link">{currentPage + 1}</button>
            </li>

            <li className="page-item">
              <button className="page-link">{currentPage + 2}</button>
            </li>
            {() => {
              return (
                <React.Fragment>
                  <li className="page-item">
                    <button className="page-link">...</button>
                  </li>

                  <li className="page-item">
                    <button className="page-link">{pages}</button>
                  </li>
                </React.Fragment>
              );
            }}

            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
                <i
                  className="fa fa-long-arrow-right d-none d-md-inline-block ml-md-1"
                  aria-hidden="true"
                ></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default Pagination;
