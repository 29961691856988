import React, { useState } from "react";
import ActivityTable from "./ActivityTable/";
import ContactTable from "./ContactTable/";
import BookingRequestTable from "./BookingAssignments/BookingRequestTable";
import { BookingRequestContext } from "../data/ModalContext";
import BookingRequestModal from "./BookingAssignments/BookingRequestModal";

const AdminControlPanel = () => {
  const [brModal, setBRmodal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [agents, setAgents] = useState([]);
  const [selectedRecordId, setSelectedRecordId] = useState("");
  const [itemFreeText, setItemFreeText] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("0");
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const [pages, setPages] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [selectedClient, setSelectedClient] = useState("");

  //let pageSize = 10;
  return (
    <BookingRequestContext.Provider
      value={{
        brModal,
        setBRmodal,
        selectedRequest,
        setSelectedRequest,
        agents,
        setAgents,
        selectedRecordId,
        setSelectedRecordId,
        itemFreeText,
        setItemFreeText,
        selectedAgent,
        setSelectedAgent,
        selectedStatus,
        setSelectedStatus,
        pages,
        setPages,
        loading,
        setLoading,
        tableData,
        setTableData,
        page,
        setPage,
        selectedClient,
        setSelectedClient
      }}
    >
      <div className="bg-smoke p-2 p-md-6 pb-6 pb-md-8 mb-9 rounded">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="mb-4">
                <h3 className="text-uppercase mb-3">Admin Control Panel</h3>
              </div>
            </div>

            <div className="row mb-8">
              <div className="col-lg-12">
                <div className="mb-4">
                  <h3 className="text-uppercase mb-3">User Activity</h3>
                  <p className="text-primary mb-0">Tracking</p>
                </div>
                <div className="">
                  <ActivityTable />
                </div>
              </div>
            </div>
            <div className="row mb-8">
              <div className="col-lg-12">
                <div className="mb-4">
                  <h3 className="text-uppercase mb-3">Contact Request</h3>
                  <p className="text-primary mb-0">Users</p>
                </div>
                <div className="">
                  <ContactTable />
                </div>
              </div>
            </div>
            <div className="row mb-8">
              <div className="col-lg-12">
                <div className="mb-4">
                  <h3 className="text-uppercase mb-3">Booking Requests</h3>
                  <p className="text-primary mb-0">All</p>
                </div>
                <div className="">
                  <BookingRequestTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingRequestModal />
    </BookingRequestContext.Provider>
  );
};

export default AdminControlPanel;
