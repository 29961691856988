export default function getTcPlatforms() {
  return [
    {
      name: "Holiday Lettings",
      description: "Rentals, Apartments & Villas",
      img: "/assets/img/home/packages/packages-1.jpg",
      filter: "lodging",
      link: "https://www.holidaylettings.co.uk/"
    },
    {
      name: "Last minute travel",
      description: "unique travel experiences",
      img: "/assets/img/home/packages/packages-2.jpg",
      filter: "lodging",
      link:
        "https://click.linksynergy.com/fs-bin/click?id=4POqVVSMkeg&offerid=228650.78&type=4&subid=0"
    },
    {
      name: "Viator",
      description: "100,000 bookable activities",
      img: "/assets/img/home/packages/packages-3.jpg",
      filter: "activities",
      link: "https://www.partner.viator.com/en/26190"
    },
    {
      name: "Project Expedition",
      description: "tours, & attractions",
      img: "/assets/img/home/packages/packages-4.jpg",
      filter: "activities",
      link:
        "https://mandrillapp.com/track/click/30465534/www.projectexpedition.com?p=eyJzIjoib2RBOHhpOVNBYUNyRk9oTlcyTVRmdjdVR3BBIiwidiI6MSwicCI6IntcInVcIjozMDQ2NTUzNCxcInZcIjoxLFwidXJsXCI6XCJodHRwczpcXFwvXFxcL3d3dy5wcm9qZWN0ZXhwZWRpdGlvbi5jb21cXFwvP3V0bV9zb3VyY2U9Y29tcGFzc2dyb3VwdHJhdmVsQGdtYWlsLmNvbSZ1dG1fbWVkaXVtPXJlZmVycmFsJnV0bV9jYW1wYWlnbj10YWdlbnRcIixcImlkXCI6XCI0MTM4NjIxMTg4Y2M0MGE2YTUwYWJkNTQ0YjcxMzQ4N1wiLFwidXJsX2lkc1wiOltcImMzMGI1N2M5Mjg5MzY2YjJmNTBiZGMyZmY3NzU0NDUyZDkwNGJkOTZcIl19In0"
    },
    {
      name: "Military",
      description: "Military Tours",
      img: "/assets/img/home/packages/packages-6.jpg",
      filter: "activities",
      link: "https://www.beyondbandofbrothers.com"
    },
    {
      name: "Tickets At Work",
      description: "All Tickets",
      img: "/assets/img/home/packages/packages-5.jpg",
      filter: "activities",
      link: "https://www.ticketsatwork.com/tickets/"
    }
  ];
}
