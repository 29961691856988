import React, { useReducer, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ProtectedRoute from "./custom/ProtectedRoute";
import routes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
//import jwtDecode from "jwt-decode";
import "./assets/css/star-color5.css";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";
import "./App.css";
import Login from "./layouts/Login";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import "./CustomSelectInput.css";
import Confirmation from "./layouts/Confirmation";
import PasswordResetHome from "./views/PasswordResetHome";
import { CSSTransition } from "react-transition-group";
import { ModalContext, CruiseContext } from "./data/ModalContext";
import { UserContext } from "./data/UserContext";
import "react-table/react-table.css";

toast.configure();

const reducer = (state, action) => {
  switch (action.type) {
    case "toggle":
      return { ...state, modal: !state.modal };
    case "load-and-toggle":
      return {
        ...state,
        item: action.payload.item,
        collection:
          action.payload.collection === "Cruises"
            ? "cruises"
            : action.payload.collection === "VIP"
            ? "vip"
            : action.payload.collection === "Compass Weeks"
            ? "compass"
            : action.payload.collection === "Registry Collection"
            ? "registry"
            : action.payload.collection === "Compass Availability"
            ? "compass"
            : action.payload.collection === "Compass Resort"
            ? "registry"
            : "compass",
        modal: !state.modal
      };
    case "load-client":
      return {
        ...state,
        client: action.payload.client,
        email: action.payload.email,
        name: action.payload.email
      };
    default:
      return state;
  }
};

const App = () => {
  const [
    { item, collection, client, email, name, modal },
    dispatch
  ] = useReducer(reducer, {
    item: null,
    collection: null,
    client: null,
    modal: false
  });

  const [stops, setStops] = useState([]);
  const [cModal, setCmodal] = useState(false);

  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <div>
        <Route path="/login" component={Login} />
        <Route path="/:id/cx/:token" component={Confirmation} />
        <Route path="/xreset" component={PasswordResetHome} />

        {routes.map((route, index) => {
          return (
            <ModalContext.Provider
              key={index + item}
              value={{
                item,
                collection,
                client,
                email,
                name,
                modal,
                dispatch
              }}
            >
              <CruiseContext.Provider
                value={{ stops, setStops, cModal, setCmodal }}
              >
                <ProtectedRoute key={index} route={route} index={index}>
                  {({ match }) => (
                    <CSSTransition
                      in={match != null}
                      timeout={300}
                      classNames="page"
                      unmountOnExit
                    >
                      <div className="page">
                        <Route.Component />
                      </div>
                    </CSSTransition>
                  )}
                </ProtectedRoute>
              </CruiseContext.Provider>
            </ModalContext.Provider>
          );
        })}
      </div>
    </Router>
  );
};

export default App;
