import React, { useEffect, useRef, useState } from "react";

const GoogleMap = props => {
  const googleMapScript = document.createElement("script");
  const [googleMap, setGoogleMap] = useState(null);
  const googleMapRef = useRef(null);

  useEffect(() => {
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${props.apikey}&libraries=places`;
    window.document.body.appendChild(googleMapScript);

    googleMapScript.addEventListener("load", () => {
      setGoogleMap(() => createGoogleMap());
      createMarker();
    });
    // eslint-disable-next-line
  }, []);

  const createGoogleMap = () =>
    new window.google.maps.Map(googleMapRef.current, {
      zoom: 10,
      scrollwheel: false,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      center: {
        lat: props.lat,
        lng: props.lng
      },
      disableDefaultUI: true
    });

  const createMarker = () => {
    new window.google.maps.Marker({
      position: { lat: props.lat, lng: props.lng },
      map: googleMap
    });
  };

  return (
    <div
      id="google-map"
      ref={googleMapRef}
      style={{ width: "100%", height: "300px" }}
    />
  );
};

export default GoogleMap;
