import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const iconHub = {
  "All Types": {
    icon: "fa-plane",
    color: "primary"
  },
  Airlines: {
    icon: "fa-plane",
    color: "primary"
  },
  registry: {
    icon: "fa-train",
    color: "success-light"
  },
  Registry: {
    icon: "fa-train",
    color: "success-light"
  },
  vip: {
    icon: "fa-gift",
    color: "warning"
  },
  "Vip Collection": {
    icon: "fa-gift",
    color: "warning"
  },
  compass: {
    icon: "fa-fort-awesome",
    color: "danger"
  },
  Compass: {
    icon: "fa-fort-awesome",
    color: "danger"
  },
  cruise: {
    icon: "fa-ship",
    color: "info"
  },
  Cruises: {
    icon: "fa-ship",
    color: "info"
  },
  Requests: {
    icon: "fa-gift",
    color: "warning"
  }
};

const UserBookingSingle = props => {
  return (
    <tr>
      <th scope="row">
        <div className={`badge badge-${iconHub[props.type].color}`}>
          <span className="d-block">
            <h1>{moment(props.data.date).format("DD")}</h1>
            <h3>{moment(props.data.date).format("MMM")}</h3>
          </span>
        </div>
      </th>

      <td className="second-child">
        <i
          className={`fa fa ${iconHub[props.type].icon} border-${
            iconHub[props.type].color
          } text-${iconHub[props.type].color}`}
          aria-hidden="true"
        ></i>
        <div>
          <Link href="" className={`hover-text-${iconHub[props.type].color}`}>
            {props.data.title}
          </Link>
          <p className="">{props.data.priceDescription}</p>
        </div>
      </td>

      <td>
        <h2 className={`text-${iconHub[props.type].color}`}>
          ${parseInt(props.data.price)}
        </h2>
        <h4>{props.data.description}</h4>
      </td>

      <td>
        <ul className="list-unstyled">
          <li className="">
            <h5>Trip id</h5>
          </li>

          <li>
            <h6>{props.data._id}</h6>
          </li>

          <li className="">
            <h5>{props.data.inventory} Booked on</h5>
          </li>

          <li>
            <h6 className="mb-0">
              {moment(props.data.created_on).format("dddd, MMM DD, YYYY")}
            </h6>
          </li>
        </ul>
      </td>
    </tr>
  );
};

export default UserBookingSingle;
