import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import LoginModal from "../components/LoginModal";
import InqueryModal from "../components/InqueryModal";
import { login } from "../services/authService";
import Joi from "joi-browser";
import LogInHome from "../views/LogInHome";

import "../login.css";

class Login extends Component {
  state = {
    data: { email: "", password: "" },
    errors: {}
  };
  schema = {
    email: Joi.string()
      .required()
      .email()
      .label("Email"),
    password: Joi.string()
      .required()
      .min(5)
      .label("Password")
  };
  validate = () => {
    const { error } = Joi.validate(this.state.data, this.schema, {
      abortEarly: false
    });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
    //console.log(result);
  };

  componentWillMount() {
    /* Here is a great place to redirect someone who is already logged in to the protected route */
    //if (this.Auth.loggedIn()) this.props.history.replace("/listall");
  }
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleSubmit = e => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    //console.log("submit");
    this.doSubmit();
  };

  doSubmit = async () => {
    try {
      const { data: jwt } = await login(this.state.data);
      localStorage.setItem("token", jwt);
      //this.props.history.push("/");
      this.props.history.replace("/");
      //window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <Container fluid>
        <Row>
          <Col tag="main" className="main-content p-0" lg="12" md="12" sm="12">
            <LogInHome
              email={this.state.data.email}
              password={this.state.data.password}
              errors={this.state.errors}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
            />

            <LoginModal
              modal={this.state.loginModal}
              toggle={this.toggleLogin}
              className
            />
            <InqueryModal
              modal={this.state.inqueryModal}
              toggle={this.toggleInquery}
              inqueryItem={this.state.inqueryItem}
              className
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
