import React from "react";
import LazyHero from "react-lazy-hero";

const ParalaxBanner = ({ title, titleImg, subtitle, color, opacity }) => {
  return (
    <section>
      <LazyHero
        className
        imageSrc={titleImg}
        color={color}
        opacity={opacity}
        isCentered={true}
        transitionDuration="600"
        parallaxOffset="100"
        minHeight="170px"
      >
        <div className="row align-items-center justify-content-center text-center">
          <div className="col-12">
            <div className="content mb-7">
              <h4 className="text-uppercase text-white mb-2">{subtitle}</h4>
              <h2 className="text-uppercase text-white font-weight-bold">
                {title}
              </h2>
            </div>
          </div>
        </div>
      </LazyHero>

      {/* ... */}
    </section>
  );
};

export default ParalaxBanner;
