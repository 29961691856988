import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, UserDashboard } from "./layouts";
import Blank from "./layouts/Blank";

// Route Views
import Home from "./views/home";
import Hotweeks from "./views/hotweeks";
import Registry from "./views/registry";
import RegistryResorts from "./views/RegistryResorts";
import HotweeksResorts from "./views/HotweeksResorts";
import TravelCenter from "./views/TravelCenter";
import DashWraper from "./views/DashWraper";
import ContactUsPage from "./views/ContactUsPage";
import LogInHome from "./views/LogInHome";
import ComingSoon from "./views/ComingSoon";
import VipWeeks from "./views/VipWeeks";
import CruiseWrapper from "./views/CruiseWrapper";
import Compass from "./views/Compass";
import CustomSelect from "./custom/CustomSelect";

//import HotelFlightsSearchHomePage from "./views/HotelFlightsSearchHomePage";
//import CompassWeeks from "./views/CompassWeeks";
//import websockets from "./dev/websockets";
//import DateCountDown from "./custom/DateCountDown";
//import ImageProxy from "./dev/ImageProxy";
//import WebsocketTest from "./dev/WebsocketTest";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: "/home",
    layout: DefaultLayout,
    component: Home
  },
  {
    path: "/hotweeks",
    layout: DefaultLayout,
    component: Hotweeks,
    exact: true
  },
  {
    path: "/compass",
    layout: DefaultLayout,
    component: Compass,
    exact: true
  },
  {
    path: "/compass/resort/:id",
    layout: DefaultLayout,
    component: HotweeksResorts,
    exact: true
  },
  {
    path: "/registry",
    layout: DefaultLayout,
    component: Registry,
    exact: true
  },
  {
    path: "/registry/resort/:id",
    layout: DefaultLayout,
    component: RegistryResorts
  },
  {
    path: "/cruise",
    layout: DefaultLayout,
    component: CruiseWrapper
  },
  {
    path: "/travel-center",
    layout: DefaultLayout,
    component: TravelCenter,
    exact: true
  },
  {
    path: "/dashboard",
    layout: UserDashboard,
    component: DashWraper
  },
  {
    path: "/contact",
    layout: DefaultLayout,
    component: ContactUsPage
  },
  {
    path: "/test",
    layout: Blank,
    component: CustomSelect
  },
  {
    path: "/loginnn",
    layout: Blank,
    component: LogInHome
  },
  {
    path: "/coming",
    layout: DefaultLayout,
    component: ComingSoon
  },
  {
    path: "/vip",
    layout: DefaultLayout,
    component: VipWeeks
  }
];
