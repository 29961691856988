//import moment from "moment";
import { db_get, db_post } from "./dbService";
import { cw_get, cw_post } from "./condoWarehouse";
//import { logError } from "../services/logService";

export async function searchHotweeks(month) {
  return await db_post(`/resorts/search`, { month });
}
export function searchCompassWeeks(
  page = 1,
  searchMonth = "ALL",
  searchUnitSize = -1,
  searchCountry = { $eg: null },
  vipBoxValue = false
) {
  const month = searchMonth;
  const unitSize = !searchUnitSize ? -1 : searchUnitSize;
  const country = searchCountry;
  return db_post(`/compass/find`, {
    month,
    unitSize,
    page,
    country,
    vipBoxValue
  });
}
export function getCompassWeeksCountries(searchMonth, searchUnitSize) {
  const month = searchMonth;
  const unitSize = !searchUnitSize ? "-1" : searchUnitSize;
  return db_post(`/compass/country`, { month, unitSize });
}
export function getCompassWeeksRegions(
  searchMonth,
  searchUnitSize,
  searchCountry
) {
  const month = searchMonth;
  const unitSize = !searchUnitSize ? "0" : searchUnitSize;
  const country = searchCountry;
  return db_post(`/resorts/compass/country`, { month, unitSize, country });
}

export async function searchWeeks(country, startDate, endDate, unitSize) {
  console.log(country);
  return await db_post(`/resorts/find`, {
    country,
    startDate,
    endDate,
    unitSize
  });
}

export async function searchRegistryResorts(country, region) {
  return await db_post(`/resorts/reg/search`, {
    country,
    region
  });
}

export async function getHotweek(id) {
  return await db_post(`/resorts/resort`, { id });
}

export async function getWeather(id) {
  return await db_post(`/resorts/weather`, { id });
}

export async function getTopPois(id) {
  return await db_post(`/resorts/toppoi`, { id });
}

export async function getPoiRec(id) {
  return await db_post(`/resorts/poirec`, { id });
}

export async function getRegistryAreas() {
  return await db_get(`/resorts/areas`);
}
export async function getResortCountries() {
  return await db_get(`/resorts/country`);
}
export async function getResortRegions(country) {
  return await db_post(`/resorts/regions`, { country });
}

export async function searchCruises() {
  return await db_get(`/cruise`);
}

export async function getClientRecommend(area) {
  return await db_post(`/poi/top`, { area });
}

//Condo Warehouse
export function initCondoWarehouse() {
  return cw_get(`/vip/init`);
}
export async function getStates() {
  const states = await cw_get("/vip/states");
  return states.states;
}
export async function getCities(country, state) {
  const cities = await cw_get(`/vip/cities/${country}/${state}`);
  return cities.cities;
}
export async function getResorts(region, country, state, city) {
  const resorts = await cw_get(
    `/vip/resorts/${region}/${country}/${state}/${city}`
  );
  return resorts.resorts;
}
export async function searchInventory(searchParams) {
  const inventory = await cw_post(`/vip/srch`, searchParams);

  return inventory.inventory;
}

export default {
  searchHotweeks,
  getHotweek,
  searchCruises,
  getWeather,
  getRegistryAreas,
  getResortCountries,
  getResortRegions,
  getClientRecommend,
  initCondoWarehouse,
  getCities,
  getStates,
  getPoiRec
};
