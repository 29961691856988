import { getJwt } from "./authService";
import axios from "axios";
import { dbUrl } from "../config.json";
import { toast } from "react-toastify";
import { logError } from "../services/errorService";

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.error("Logging the error", error);
    logError("dbService - axios", error, this.state, this.props);
    toast.error("An unexpected error occurred");
  }

  return Promise.reject(error);
});

function storeCwToken(cwToken) {
  localStorage.setItem("x-con", cwToken);
}

function getXcon() {
  return localStorage.getItem("x-con");
}

function authorizeCaller() {
  //GET WEBTOKEN FROM LOCAL STORAGE
  const jwt = getJwt();
  const xcon = getXcon();

  //RETURN AXIOS AUTHORIZED HTTP CALLER
  return axios.create({
    withCredentials: false,
    baseURL: `${dbUrl}`,
    headers: {
      common: {
        "x-auth-token": jwt,
        "x-con": xcon
      }
    }
  });
}

export async function cw_get(endPoint) {
  //axios.defaults.headers.common["x-auth-token"] = getJwt();
  const http = authorizeCaller();

  const { data: response } = await http.get(endPoint);
  storeCwToken(response.token);
  return response;
}

export async function cw_post(endPoint, body) {
  const http = authorizeCaller();

  const { data: response } = await http.post(endPoint, body);
  storeCwToken(response.token);
  return response;
}

export default {
  cw_get,
  cw_post
};
