import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import DayPickerRangeControllerWrapper from "../../custom/DayPickerRangeControllerWrapper";
import moment from "moment";
import { getActivity } from "../../services/logService";
import "react-table/react-table.css";
import { Row, Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faSignInAlt,
  faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";

function isInclusivelyDurring(day, start, end) {
  if (!moment.isMoment(day) || !moment.isMoment(start) || !moment.isMoment(end))
    return false;
  return moment(day).isBetween(start, end);
}

const ActivityTable = props => {
  const [startRange, setStartRange] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pages, setPages] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([
    { icon: "", created_on: "", activity: "" }
  ]);
  let pageSize = 10;
  useEffect(() => {
    if (startDate !== "Invalid date" && endDate === "Invalid date") {
      setEndDate(
        moment(startDate)
          .add(1, "d")
          .format()
      );
    }
    getActivity(startDate, endDate, 1, pageSize).then(res => {
      // Update react-table
      setTableData(res.list);
      setPages(res.pages);
      setStartRange(res.startRange);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [startDate, endDate]);
  const tableColumns = [
    {
      Header: "icon",
      accessor: "icon",
      maxWidth: 60,
      Cell: row => (
        <FontAwesomeIcon
          icon={
            row.original.icon === "faSignInAlt"
              ? faSignInAlt
              : row.original.icon === "faSignOutAlt"
              ? faSignOutAlt
              : faEye
          }
        />
      ),
      className: "text-center"
    },
    {
      Header: "Date",
      accessor: "created_on",
      maxWidth: 160,
      Cell: row => moment(row.original.created_on).format("MM/DD/YY, h:mm:ss a")
    },
    {
      Header: "Activity",
      accessor: "activity"
    }
  ];

  return (
    <React.Fragment>
      <Card className="p-0 shadow-lg">
        <CardBody className="p-0">
          <Row>
            <div className="col-lg-8">
              <ReactTable
                columns={tableColumns}
                data={tableData}
                pageSize={pageSize}
                showPageSizeOptions={false}
                resizable={false}
                pages={pages} // should default to -1 (which means we don't know how many pages we have)
                loading={loading}
                manual // informs React Table that you'll be handling sorting and pagination server-side
                onFetchData={(state, instance) => {
                  // show the loading overlay
                  setLoading(true);
                  // fetch your data
                  getActivity(
                    startDate,
                    endDate,
                    state.page + 1,
                    pageSize
                  ).then(res => {
                    // Update react-table
                    setTableData(res.list);
                    setPages(res.pages);
                    setStartRange(res.startRange);
                    setLoading(false);
                  });
                }}
              />
            </div>
            <div className="col-lg-4">
              <DayPickerRangeControllerWrapper
                isOutsideRange={day =>
                  !isInclusivelyDurring(
                    day,
                    moment(startRange).subtract(2, "d"),
                    moment().add(1, "d")
                  )
                }
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                showInputs={true}
              />
            </div>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ActivityTable;
