import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { resetUserPassword } from "../services/adminService";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from "reactstrap";

const PasswordResetAdminModal = ({ modal, toggle, id }) => {
  const passwordRef = useRef(null);

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      const response = await resetUserPassword(id, passwordRef.current.value);
      toast.success(`${response}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      passwordRef.current.value = "";
      toggle();
    } catch (ex) {
      toast.error(`Error: ${ex.response.data}`, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={() => toggle()}
        className={"modal-dialog modal-lg modal-dialog-centered"}
      >
        <ModalHeader
          className="modal-title text-uppercase font-weight-bold"
          toggle={() => toggle()}
        >
          Reset Users Password
        </ModalHeader>
        <ModalBody>
          <form className="" onSubmit={handleSubmit}>
            <div className="form-row form-group">
              <Col>
                <div className="form-group">
                  <label htmlFor="newPassword">New Password</label>
                  <input
                    id="newPassword"
                    type="text"
                    className="form-control bg-smoke"
                    required=""
                    autoComplete="off"
                    ref={passwordRef}
                  ></input>
                </div>
              </Col>
            </div>
            <Row>
              <Col>
                <button className="btn btn-success btn-block text-uppercase">
                  Reset
                </button>
              </Col>
            </Row>
          </form>
        </ModalBody>
        <ModalFooter>
          <p className="mb-1">
            <button onClick={() => toggle()}>Close</button>
          </p>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PasswordResetAdminModal;
