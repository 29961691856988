import React from "react";
import { Input } from "reactstrap";
//import Select2 from "react-select2-wrapper";
//import { SingleDatePicker } from "react-dates";

const SidebarSearch = ({
  rooms,
  roomChange,
  byPriceShow,
  byMonthShow,
  byLocationShow,
  filterDrop,
  month,
  handleMonthChange,
  location,
  handleLocationChange,
  locationList
}) => {
  const selectMenu = locationList.map(key => {
    return <option value={key}>{key}</option>;
  });
  return (
    <div className="col-lg-3">
      <div className="row">
        <div className="col-md-6 col-lg-12">
          <div className="mb-6 bg-white px-3 py-6 border-top border-top-5 border-primary rounded shadow">
            <h4 className="text-uppercase font-weight-bold">Search</h4>

            <div className="mb-5">
              <input
                type="text"
                className="form-control border-top-0 border-left-0 border-right-0 pl-0"
                required=""
                aria-describedby="textHelp"
                placeholder="Resort Name"
                //value={resortName}
                //onChange={e => resortNameChange(e)}
              ></input>
            </div>
            <div className="accordion" id="accordionOne">
              <div className="card">
                <div
                  className="card-header"
                  id="headingTwo"
                  onClick={() => filterDrop("byMonthShow")}
                >
                  <h5
                    className={
                      byMonthShow === true ? "icon-bg" : "icon-bg collapsed"
                    }
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span>Month</span>
                  </h5>
                </div>

                <div
                  id="collapseTwo"
                  className={
                    byMonthShow === true ? "collapse show" : "collapse"
                  }
                  aria-labelledby="headingTwo"
                  data-parent="#accordionOne"
                >
                  <div className="card-body">
                    <div className="form-group form-group-icon form-group-icon-dark mb-5">
                      <Input
                        type="select"
                        onChange={e => handleMonthChange(e.target.value)}
                        value={month}
                        placeholder={"SEARCH BY MONTH"}
                      >
                        <option key={"9999"} value="">
                          SEARCH BY MONTH
                        </option>
                        <option key={"10october"} value="10">
                          October
                        </option>
                        <option key={"11november"} value="11">
                          November
                        </option>
                        <option key={"12december"} value="12">
                          December
                        </option>
                        <option key={"1january"} value="1">
                          January
                        </option>
                      </Input>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              //onClick={() => submitSearch()}
              className="btn btn-block btn-xs btn-outline-secondary text-uppercase"
            >
              Search
            </button>
          </div>
        </div>

        <div className="col-md-6 col-lg-12">
          <div className="mb-6 bg-white p-3 pt-6 border-top border-top-5 border-primary rounded shadow">
            <h4 className="text-uppercase font-weight-bold">Filter by</h4>

            <div className="accordion" id="accordionOne">
              <div className="card">
                <div
                  className="card-header"
                  id="headingTwo"
                  onClick={() => filterDrop("byLocationShow")}
                >
                  <h5
                    className={
                      byLocationShow === true ? "icon-bg" : "icon-bg collapsed"
                    }
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span>Location</span>
                  </h5>
                </div>

                <div
                  id="collapseTwo"
                  className={
                    byLocationShow === true ? "collapse show" : "collapse"
                  }
                  aria-labelledby="headingTwo"
                  data-parent="#accordionOne"
                >
                  <div className="card-body">
                    <div className="form-group form-group-icon form-group-icon-dark mb-5">
                      <Input
                        type="select"
                        onChange={e => handleLocationChange(e.target.value)}
                        value={location}
                        placeholder={"FILTER BY LOCATION"}
                      >
                        {selectMenu}
                      </Input>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header"
                  id="headingOne"
                  onClick={() => filterDrop("byPriceShow")}
                >
                  <h5
                    className={
                      byPriceShow === true ? "icon-bg" : "icon-bg collapsed"
                    }
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <span>By Price</span>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  className={
                    byPriceShow === true ? "collapse show" : "collapse"
                  }
                  aria-labelledby="headingOne"
                  data-parent="#accordionOne"
                >
                  <div className="card-body">
                    <div className="custom-control custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                      ></input>
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      >
                        <span>$0 - $50</span>
                      </label>
                    </div>

                    <div className="custom-control custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck2"
                      ></input>
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck2"
                      >
                        <span>$50 - $100</span>
                      </label>
                    </div>

                    <div className="custom-control custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck3"
                      ></input>
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck3"
                      >
                        <span>$100 - $150</span>
                      </label>
                    </div>

                    <div className="custom-control custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck4"
                      ></input>
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck4"
                      >
                        <span>$150 - $200</span>
                      </label>
                    </div>

                    <div className="custom-control custom-checkbox mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck5"
                      ></input>
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck5"
                      >
                        <span>$200 - $250</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mb-5">
                <div className="row align-items-center">
                  <label className="control-label count-control-label col-5 col-lg-12 col-xl-4 text-uppercase mb-0 mb-lg-4 mb-xl-0 text-lg-center">
                    Rooms
                  </label>

                  <div className="col-7 col-lg-12 col-xl-7">
                    <div className="count-input mr-0 mx-lg-auto mr-xl-0">
                      <button
                        className="incr-btn"
                        onClick={() => {
                          roomChange("decrease");
                        }}
                      >
                        –
                      </button>
                      <input
                        className="quantity"
                        type="number"
                        value={rooms}
                        readOnly
                      ></input>
                      <button
                        className="incr-btn"
                        onClick={() => roomChange("increase")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarSearch;
