import React from "react";
import CompassUpdater from "../custom/CompassUpdater";
import AdminSettingsLoginSaver from "../components/AdminSettingsLoginSaver";
import AdminSettingsApiSaver from "../components/AdminSettingsApiSaver";

const AdminSettings = () => {
  return (
    <section className="py-6 fade-in">
      <div className="container">
        <div className="bg-smoke p-2 p-md-6 pb-6 pb-md-8 mb-9 rounded">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-4">
                    <h3 className="text-uppercase mb-3">Admin Settings</h3>
                  </div>
                  <AdminSettingsApiSaver
                    source="googleMapsApi"
                    title="Google Maps API Key"
                  />
                </div>
                <CompassUpdater />
              </div>

              <div className="row pt-3">
                <div className="col-lg-6">
                  <AdminSettingsLoginSaver source="rci" title="RCI" />
                </div>
                <div className="col-lg-6">
                  <AdminSettingsLoginSaver
                    source="goldCrown"
                    title="Gold Crown"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminSettings;
