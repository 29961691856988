import React from "react";
import LazyHero from "react-lazy-hero";
import { Link } from "react-router-dom";

const CustomCruiseBanner = ({ showButton }) => {
  return (
    <section>
      <LazyHero
        imageSrc="/assets/img/home/promotion/promotion-1.jpg"
        color="#021a2b66"
        opacity="0.4"
        isCentered={true}
        transitionDuration="600"
        parallaxOffset="100"
        minHeight="200px"
      >
        <div className="row align-items-center justify-content-center text-center">
          <div className="col-12">
            <div className="content mb-7">
              <h4 className="text-uppercase text-white mb-2">Cruises</h4>
              <h2 className="text-uppercase text-white font-weight-bold">
                Submit a custom cruise request
              </h2>
            </div>
            <ul className="list-unstyled icon-rating d-flex justify-content-center text-warning mb-1">
              <li className="mr-1">
                <i className="fa fa-star" aria-hidden="true"></i>
              </li>
              <li className="mr-1">
                <i className="fa fa-star" aria-hidden="true"></i>
              </li>
              <li className="mr-1">
                <i className="fa fa-star" aria-hidden="true"></i>
              </li>
              <li className="mr-1">
                <i className="fa fa-star" aria-hidden="true"></i>
              </li>
              <li>
                <i className="fa fa-star" aria-hidden="true"></i>
              </li>
            </ul>
            {showButton === true && (
              <React.Fragment>
                <h4 className="text-white mb-4">
                  Tell use when and where you want to go
                </h4>
                <Link
                  to="/cruise/custom"
                  className="btn btn-primary text-uppercase"
                >
                  Click Here to Try
                </Link>
              </React.Fragment>
            )}
          </div>
        </div>
      </LazyHero>

      {/* ... */}
    </section>
  );
};

export default CustomCruiseBanner;
