import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { Row, Card, CardBody } from "reactstrap";
import {
  getContacts,
  getContact,
  getAgents,
  editContact
} from "../../services/bookingService";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CustomSelect from "../../custom/CustomSelect";

const ContactTable = () => {
  const [pages, setPages] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([
    { email: "", name: "", agent: "" }
  ]);
  const [selectedRecordId, setSelectedRecordId] = useState("");
  const [selectedContact, setSelectedContact] = useState(tableData[0]);
  //const [selectedRequest, setSelectedRequest] = useState(tableData[0]);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("0");
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const [respondedOn, setRespondedOn] = useState(null);
  const [assignedOn, setAssignedOn] = useState(null);

  let pageSize = 10;

  useEffect(() => {
    getContacts(1, pageSize).then(result => {
      setTableData(result.list);
      setPages(result.pages);
      setLoading(false);
    });
    getAgents().then(setAgents);

    // eslint-disable-next-line
  }, []);

  function handleCustomSelect(name, e) {
    switch (name) {
      case "agent":
        if (e.target.value !== "0") setAssignedOn(Date.now());
        setSelectedAgent(e.target.value);
        break;
      case "status":
        setSelectedStatus(e.target.value);
        if (e.target.value === "Responded") setRespondedOn(Date.now());
        break;
      default:
        return;
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    const contact = {
      agent: selectedAgent !== "0" ? selectedAgent : null,
      assigned: selectedAgent !== "0" ? true : false,
      assigned_on: assignedOn,
      responded: selectedStatus === "Pending" ? false : true,
      responded_on: respondedOn,
      status: selectedStatus
    };

    setLoading(true);
    if (selectedRecordId !== "") {
      editContact(selectedRecordId, contact).then(() => {
        getContacts(1, pageSize).then(res => {
          setTableData(res.list);
          setPages(res.pages);
        });
      });
    }
    getAgents().then(setAgents);
    setSelectedRecordId("");
    setSelectedContact({ email: "" });
    setSelectedAgent("0");
    setSelectedStatus("Pending");
    setLoading(false);
  };

  const status = [
    {
      value: "Pending",
      text: "Pending"
    },
    {
      value: "Responded",
      text: "Responded"
    }
  ];

  const tableColumns = [
    {
      Header: "Date",
      accessor: "created_on",
      maxWidth: 80,
      Cell: row =>
        moment(row.original.created_on).format("MM/DD/YY - HH:mm:ss"),
      className: "text-center"
    },
    {
      Header: "User",
      accessor: "user.email",
      className: "text-center"
    },
    {
      Header: "Assigned To",
      accessor: "agent",
      Cell: row =>
        row.original.assigned === true ? (
          <span>
            {row.original.agent.last}, {row.original.agent.name}
          </span>
        ) : (
          <span></span>
        ),
      className: "text-center"
    },
    {
      Header: "Responded",
      accessor: "responded",
      Cell: row =>
        row.original.responded === true && (
          <FontAwesomeIcon icon={faCheckCircle} />
        ),
      className: "text-center"
    }
  ];

  const agentSelect = agents.map(agent => {
    if (agent) {
      return {
        value: agent._id,
        text: `${agent.last}, ${agent.name}`
      };
    } else {
      return null;
    }
  });

  return (
    <React.Fragment>
      <Card className="p-0 shadow-lg">
        <CardBody className="p-0">
          <Row>
            <div className="col-lg-8">
              <ReactTable
                className="-striped -highlight"
                columns={tableColumns}
                data={tableData}
                pageSize={pageSize}
                showPageSizeOptions={false}
                resizable={false}
                pages={pages} // should default to -1 (which means we don't know how many pages we have)
                loading={loading}
                manual // informs React Table that you'll be handling sorting and pagination server-side
                onFetchData={(state, instance) => {
                  // show the loading overlay
                  setLoading(true);
                  // fetch your data
                  getContacts(state.page + 1, pageSize).then(res => {
                    // Update react-table
                    setTableData(res.list);
                    setPages(res.pages);
                    setLoading(false);
                  });
                }}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (rowInfo) {
                        setSelectedRecordId(rowInfo.original._id);
                        getContact(rowInfo.original._id)
                          .then(res => {
                            setSelectedContact(res);
                            return res;
                          })
                          .then(selectedResult => {
                            setSelectedAgent(
                              selectedResult.agent === null
                                ? "0"
                                : selectedResult.agent
                            );
                            setSelectedStatus(selectedResult.status);
                          });
                      }
                    }
                  };
                }}
              />
            </div>
            <div className="col-lg-4">
              {selectedContact.email === "" && (
                <Card>
                  <CardBody>
                    <div>
                      <FontAwesomeIcon className="mb-4" icon={faArrowLeft} />
                      <h3>Click a contact submission to assign...</h3>
                    </div>
                  </CardBody>
                </Card>
              )}
              {selectedContact.email !== "" && (
                <React.Fragment>
                  <form onSubmit={handleSubmit}>
                    <Card>
                      <CardBody>
                        <div>
                          <h3 className="mt-4">Contact Submition</h3>
                          <div className="row mt-1">
                            <div className="col-4 text-primary">Name:</div>

                            <div className="col-8">
                              {selectedContact && (
                                <div>{selectedContact.name}</div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-4 text-primary">Email:</div>
                            <div className="col-8">{selectedContact.email}</div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-4 text-primary">Phone:</div>
                            <div className="col-8">{selectedContact.phone}</div>
                          </div>
                          <div className="row mt-1">
                            <div className="col text-primary">Message:</div>
                          </div>
                          <div className="row mt-1">
                            <div className="col">
                              <p>{selectedContact.message}</p>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <CustomSelect
                            options={agentSelect}
                            label="Assign To:"
                            value={selectedAgent}
                            idName="agent"
                            handleCustomSelect={handleCustomSelect}
                          />
                        </div>
                        <div className="form-group">
                          <CustomSelect
                            options={status}
                            value={selectedStatus}
                            idName="status"
                            handleCustomSelect={handleCustomSelect}
                          />
                        </div>
                      </CardBody>
                    </Card>
                    <div className="pb-4">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </React.Fragment>
              )}
            </div>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ContactTable;
