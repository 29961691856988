import React, { Component } from "react";
import { userIsAdmin } from "../services/authService";
import UserDashboard from "../components/UserDashboard";
import AdminDashboard from "../components/AdminDashboard";

class DashWraper extends Component {
  state = {
    notifyMenu: false,
    userMenu: false,
    bookingType: "All Types"
  };
  handleDropDownMenu = (key, value) => {
    //console.log({ [key]: value });
    this.setState({ [key]: value });
  };
  setBookingType = bookingType => {
    this.setState({ bookingType });
  };
  render() {
    if (userIsAdmin()) {
      return (
        <AdminDashboard
          notifyMenu={this.state.notifyMenu}
          handleNotifyMenu={this.handleDropDownMenu}
          userMenu={this.state.userMenu}
          handleUserMenu={this.handleDropDownMenu}
          handleDropDownMenu={this.handleDropDownMenu}
          bookingType={this.state.bookingType}
          setBookingType={this.setBookingType}
        />
      );
    } else {
      return (
        <UserDashboard
          notifyMenu={this.state.notifyMenu}
          handleNotifyMenu={this.handleDropDownMenu}
          userMenu={this.state.userMenu}
          handleUserMenu={this.handleDropDownMenu}
          handleDropDownMenu={this.handleDropDownMenu}
          bookingType={this.state.bookingType}
          setBookingType={this.setBookingType}
        />
      );
    }
  }
}

export default DashWraper;
