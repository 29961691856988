import React, { useContext } from "react";
import { ModalContext } from "../data/ModalContext";

const Benefits = props => {
  const { dispatch } = useContext(ModalContext);
  return (
    <div className="col-md-5 col-lg-4">
      <div className="border border-light-gray bg-smoke rounded p-6">
        <h4 className="text-uppercase mb-5">Todo List</h4>

        <ul className="list-unstyled mb-6">
          <li className="media mb-3">
            <div className="mr-3">
              <i className="fa fa-check-square" aria-hidden="true"></i>
            </div>

            <div className="media-body">
              <p className="text-dark text-capitalize mb-0">Airfare?</p>
            </div>
          </li>

          <li className="media mb-3">
            <div className="mr-3">
              <i className="fa fa-check-square" aria-hidden="true"></i>
            </div>

            <div className="media-body">
              <p className="text-dark text-capitalize mb-0">Rental Car?</p>
            </div>
          </li>

          <li className="media mb-3">
            <div className="mr-3">
              <i className="fa fa-check-square" aria-hidden="true"></i>
            </div>

            <div className="media-body">
              <p className="text-dark text-capitalize mb-0">
                Travel Documents?
              </p>
            </div>
          </li>

          <li className="media">
            <div className="mr-3">
              <i className="fa fa-check-square" aria-hidden="true"></i>
            </div>

            <div className="media-body">
              <p className="text-dark text-capitalize mb-0">Special Needs?</p>
            </div>
          </li>
        </ul>

        <div className="">
          <button
            className="btn btn-primary text-uppercase"
            type="button"
            onClick={() =>
              dispatch({
                type: "load-and-toggle",
                payload: {
                  item: props.resort._id,
                  collection: "Registry Collection"
                }
              })
            }
          >
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
