import React, { useState } from "react";
import UserBookingsHeading from "./UserBookingsHeader";
import UserBookings from "./UserBookings";

const UserBookingMain = () => {
  const [activeTab, setActiveTab] = useState("All Types");
  return (
    <React.Fragment>
      <UserBookingsHeading activeTab={activeTab} setActiveTab={setActiveTab} />
      <UserBookings activeTab={activeTab} />
    </React.Fragment>
  );
};

export default UserBookingMain;
