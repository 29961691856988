import React, { useState, useEffect } from "react";
//import Timer from "./DateCountDown/Timer";

const DateCountDown = ({ endDate }) => {
  const seconds =
    Math.floor(new Date(endDate).getTime() / 1000) -
    Math.floor(Date.now() / 1000);
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [daysLeft, setDaysLeft] = useState(Math.floor((timeLeft - 1) / 86400));
  const [hoursLeft, setHoursLeft] = useState(
    Math.floor(((timeLeft - 1) % 86400) / 3600)
  );
  const [minLeft, setMinLeft] = useState(
    Math.floor(((timeLeft - 1) % 3600) / 60)
  );
  const [secLeft, setSecLeft] = useState((timeLeft - 1) % 60);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      setDaysLeft(Math.floor((timeLeft - 1) / 86400));
      setHoursLeft(Math.floor(((timeLeft - 1) % 86400) / 3600));
      setMinLeft(Math.floor(((timeLeft - 1) % 3600) / 60));
      setSecLeft((timeLeft - 1) % 60);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <div className="coming-soon-count">
      <div id="coming-soon" className="syotimer timer">
        <div className="timer-head-block"></div>
        <div className="timer-body-block">
          <div className="table-cell day">
            <div className="tab-val">{daysLeft}</div>
            <div className="tab-metr tab-unit">days</div>
          </div>
          <div className="table-cell hour">
            <div className="tab-val">{hoursLeft}</div>
            <div className="tab-metr tab-unit">hours</div>
          </div>
          <div className="table-cell minute">
            <div className="tab-val">{minLeft}</div>
            <div className="tab-metr tab-unit">minutes</div>
          </div>
          <div className="table-cell second">
            <div className="tab-val" style={{ opacity: "1" }}>
              {secLeft}
            </div>
            <div className="tab-metr tab-unit">seconds</div>
          </div>
        </div>
        <div className="timer-foot-block"></div>
      </div>
    </div>
  );
};

export default DateCountDown;
