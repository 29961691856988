import React, { Component } from "react";
import Footer from "../components/Footer";
import { Container, Row, Col } from "reactstrap";
import LoginModal from "../components/LoginModal";
import InqueryModal from "../components/InqueryModal";
import CruiseModal from "../components/CruiseModal";
import ResponsiveHeader from "../dev/ResponsiveHeader";
import ContactModal from "../components/ContactModal";
import { pageLogger } from "../services/logService";
import "../assets/plugins/font-awesome/css/font-awesome.min.css";
import "../assets/plugins/fancybox/jquery.fancybox.min.css";
import "../assets/plugins/isotope/isotope.min.css";
import "../assets/plugins/animate/animate.css";
import "../assets/plugins/daterangepicker/css/daterangepicker.css";
import "../assets/plugins/slick/slick.css";
import "../assets/plugins/slick/slick-theme.css";

class DefaultLayout extends Component {
  state = {
    cruiseModal: false,
    cruiseId: "",
    loginModal: false,
    inqueryModal: false,
    inqueryItem: "",
    page: ""
  };

  toggleCruise = () => {
    this.setState(prevState => ({
      cruiseModal: !prevState.cruiseModal
    }));
  };

  toggleLogin = () => {
    this.setState(prevState => ({
      loginModal: !prevState.loginModal
    }));
  };
  toggleInquery = (
    inqueryStartDate,
    inqueryName,
    inqueryInventoryId,
    inqueryPrice,
    inqueryLevel,
    inqueryType,
    inquerySource
  ) => {
    this.setState(prevState => ({
      inqueryModal: !prevState.inqueryModal,
      inqueryItem: inqueryInventoryId,
      inqueryStartDate: inqueryStartDate,
      inqueryName: inqueryName,
      inqueryInventoryId: inqueryInventoryId,
      inqueryPrice: inqueryPrice,
      inqueryLevel: inqueryLevel,
      inqueryType: inqueryType,
      inquerySource: inquerySource
    }));
  };

  pageNameGetter = name => {
    this.setState(name);
  };
  render() {
    const { props } = this;
    const { path } = props.match;

    //User Tracking
    if (path !== "/dashboard") {
      pageLogger(path);
    }

    //const regex = path.replace(/(.*?)/)
    const children = React.Children.map(props.children, child => {
      return React.cloneElement(child, {
        toggleCruise: this.toggleCruise,
        toggleInquery: this.toggleInquery,
        toggleLogin: this.toggleLogin,
        pageNameGetter: this.pageNameGetter
      });
    });

    return (
      <Container fluid>
        <Row>
          <ResponsiveHeader
            page={path}
            toggleLogin={this.toggleLogin}
            toggleInquery={this.toggleInquery}
          />
          <Col>
            <div
              tag="main"
              className="main-content mt-9 p-0 page"
              lg="12"
              md="12"
              sm="12"
            >
              {children}
              <LoginModal
                modal={this.state.loginModal}
                toggle={this.toggleLogin}
                className
              />

              <InqueryModal
                modal={this.state.inqueryModal}
                toggle={this.toggleInquery}
                inqueryItem={this.state.inqueryItem}
                inqueryStartDate={this.state.inqueryStartDate}
                inqueryName={this.state.inqueryName}
                inqueryInventoryId={this.state.inqueryInventoryId}
                inqueryPrice={this.state.inqueryPrice}
                inqueryLevel={this.state.inqueryLevel}
                inqueryType={this.state.inqueryType}
                inquerySource={this.state.inquerySource}
                className
              />
              <CruiseModal
                modal={this.state.cruiseModal}
                toggle={this.toggleCruise}
                cruiseId={this.state.cruiseId}
              />
            </div>
            <ContactModal />
            <Footer />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DefaultLayout;
