import { db_get, db_post, db_put } from "./dbService";
import jwtDecode from "jwt-decode";
//import moment from "moment";
const tokenKey = "token";

export async function sendBookingRequest(request) {
  try {
    return await db_post(`/booking/req/${request.inventory}`, request);
  } catch (ex) {
    //console.log(ex);
  }
}

export async function sendContactRequest(contactData) {
  try {
    return await db_post(`/booking/contact`, contactData);
  } catch (ex) {}
}

export async function sendUserQuestion(question) {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const { _id, name, email } = jwtDecode(jwt);
    const contactData = {
      user: _id,
      name: name,
      email: email,
      message: question
    };
    return await db_post(`/booking/contact`, contactData);
  } catch (ex) {}
}

export async function getAllContacts() {
  try {
    return await db_get("/booking/contact");
  } catch (ex) {}
}

export async function getContacts(page, pageSize) {
  try {
    const result = db_post(`/booking/contacts`, {
      page,
      pageSize
    });

    return result;
  } catch (ex) {}
}

export async function getContact(id) {
  try {
    return await db_get(`/booking/contact/${id}`);
  } catch (ex) {}
}
export async function getAgents() {
  try {
    return await db_get(`/booking/agents`);
  } catch (ex) {}
}
export async function editContact(id, contactUpdate) {
  try {
    return await db_put(`/booking/contact/${id}`, contactUpdate);
  } catch (ex) {}
}

export async function editRequest(id, contactUpdate) {
  try {
    return await db_put(`/booking/request/${id}`, contactUpdate);
  } catch (ex) {}
}
export async function getRequests(page, pageSize) {
  try {
    const result = db_post(`/booking/requests`, {
      page,
      pageSize
    });
    return result;
  } catch (ex) {}
}
export async function getRequest(id) {
  try {
    return await db_get(`/booking/request/${id}`);
  } catch (ex) {}
}
export async function getFreeText(id) {
  try {
    return await db_get(`/booking/freetext/${id}`);
  } catch (ex) {}
}

//USER BOOKING
export async function getBookings(type) {
  try {
    console.log(type);
    const jwt = localStorage.getItem(tokenKey);
    const { _id } = jwtDecode(jwt);
    switch (type) {
      case "All Types":
        return await db_get(`/booking/${_id}`);
        break;
      case "Cruises":
        return await db_post(`/booking/${_id}`, { type: "cruises" });
        break;
      case "Vip Collection":
        return await db_post(`/booking/${_id}`, { type: "vip" });
        break;
      case "Compass":
        return await db_post(`/booking/${_id}`, { type: "compass" });
        break;
      case "Registry":
        return await db_post(`/booking/${_id}`, { type: "registry" });
        break;
      case "Airlines":
        return await db_post(`/booking/${_id}`, { type: "airlines" });
        break;
      default:
        return await db_get(`/booking/${_id}`);
    }
  } catch (ex) {}
}

//VIP Booking
export async function requestVip(vip) {
  try {
    return await db_post("/vip/request", { vip });
  } catch (ex) {}
}
