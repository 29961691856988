import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { searchCruises } from "../services/serverCallsService";
import LazyHero from "react-lazy-hero";
import { Link } from "react-router-dom";
import { ModalContext, CruiseContext } from "../data/ModalContext";

const CruiseFeatured = () => {
  const { dispatch } = useContext(ModalContext);
  const { setStops, setCmodal } = useContext(CruiseContext);
  const [featuredCruises, setFeaturedCruises] = useState([]);
  useEffect(() => {
    searchCruises().then(setFeaturedCruises);
  }, []);

  const featured = featuredCruises.map(cruise => {
    return (
      <div
        key={cruise._id}
        className="media media-list-view flex-column flex-md-row mb-5 shadow-sm animated
          fadeIn"
      >
        <div className="media-img position-relative">
          <img
            className="img-fluid position-relative"
            src={cruise.img}
            alt="cruise-img"
          />
          <Link className="media-img-overlay" data-fancybox="gallery"></Link>
        </div>

        <div className="media-body">
          <div className="row">
            <div className="col-md-7 col-xl-8">
              <h4>
                <Link className="media-title" to="/cruise">
                  {cruise.ship}
                </Link>
              </h4>
              <div className="rating-view">
                <span className="content-view text-uppercase">
                  {cruise.line}
                </span>
                <span className="content-view text-uppercase pl-1">
                  {moment(cruise.startDate).format("L")}
                </span>
              </div>
              <table className="mt-3">
                <tbody>
                  <tr>
                    <td className="pr-2">INSIDE</td>
                    <td className="pr-2">OCEAN</td>
                    <td className="pr-2">BALCONY</td>
                  </tr>
                  <tr
                    style={{
                      color: "#17a2b8"
                    }}
                  >
                    <td>${cruise.insideRoom}</td>
                    <td>${cruise.oceanView}</td>
                    <td>${cruise.balconySuite}</td>
                  </tr>
                </tbody>
              </table>

              <p style={{ fontSize: "small" }} className="pt-2">
                {}
              </p>
              <h5 className="media-title">{cruise.discription}</h5>
              <div className="date-view">
                <span>
                  <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                  {moment(cruise.startDate).format("L")}
                </span>
                <span>
                  <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                  {moment(cruise.endDate).diff(
                    moment(cruise.startDate),
                    "days"
                  ) + " Days"}
                </span>
              </div>
              <div></div>
            </div>

            <div className="col-md-5 col-xl-4">
              <div className="bg-primary text-center p-3 view-details">
                <h2 className="text-white font-weight-bold h1">
                  ${cruise.insideRoom}
                </h2>
                <p className="text-white text-capitalize">Per person</p>
                <div>
                  <button
                    onClick={() =>
                      dispatch({
                        type: "load-and-toggle",
                        payload: { item: cruise._id, collection: "Cruises" }
                      })
                    }
                    className="btn btn-xs btn-block btn-outline-white btn-booking text-uppercase mb-2"
                  >
                    Inquiry
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      setCmodal(true);
                      setStops(cruise.stops);
                    }}
                    className="btn btn-xs btn-block btn-outline-white btn-booking text-uppercase mb-2"
                  >
                    Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="main-wrapper cruise fade-in">
      <section>
        <LazyHero
          imageSrc="/assets/img/home/promotion/promotion-1.jpg"
          color="#021a2b66"
          opacity={0.4}
          isCentered={true}
          transitionDuration={600}
          parallaxOffset={100}
          minHeight="300px"
        >
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">
              <div className="content mb-7">
                <h4 className="text-uppercase text-white mb-2">Cruises</h4>
                <h2 className="text-uppercase text-white font-weight-bold">
                  Submit a custom cruise request
                </h2>
              </div>

              <h4 className="text-white mb-4">
                Tell use when and where you want to go
              </h4>
              <Link
                to="/cruise/custom"
                className="btn btn-primary text-uppercase"
              >
                Click Here to Try
              </Link>
            </div>
          </div>
        </LazyHero>
      </section>

      <section className="bg-smoke py-10">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-3 order-lg-1">
              <div className="row">
                <div className="col-md-6 col-lg-12">
                  <div className="mb-6 bg-white p-3 border-top border-top-5 border-primary rounded shadow-sm">
                    <h4 className="text-uppercase font-weight-bold">
                      Custom Request
                    </h4>

                    <p>
                      Don't see what you want? That's okay! Click here to
                      Request your Custom Cruise Today!
                    </p>
                    <Link
                      to="/cruise/custom"
                      className="btn btn-block btn-xs btn-outline-primary text-uppercase"
                    >
                      Click Here
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-9">{featured}</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CruiseFeatured;
