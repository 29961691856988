import React, { useState, useEffect, useContext } from "react";
import { getTopPois } from "../services/serverCallsService";
import { ModalContext } from "../data/ModalContext";

const PoiSection = props => {
  const { dispatch } = useContext(ModalContext);
  const [pois, setPois] = useState([
    {
      images: [
        {
          sizes: {
            medium: {
              url: ""
            }
          }
        }
      ],
      name: ""
    }
  ]);

  useEffect(() => {
    getTopPois(props.id).then(setPois);
  }, [props.id]);

  const poiRow = pois.map(poi => {
    return (
      <div key={poi.name} className="col-md-6 col-lg-4 ">
        <div
          className="media media-hoverable justify-content-center mb-5 mb-lg-0 animated
                rubberBand"
        >
          <div className="media-content" style={{ height: "200px" }}>
            <img
              className="media-img"
              src={
                poi.images.length > 0 ? poi.images[0].sizes.medium.url : "no"
              }
              alt="Poi"
            ></img>

            <a
              className="media-img-overlay"
              onClick={() =>
                dispatch({
                  type: "load-and-toggle",
                  payload: { item: poi.id }
                })
              }
            >
              <div className="overlay-content">
                <h3 className="text-uppercase font-weight-bold mb-3">
                  {poi.name}
                </h3>
                {/*<p className="text-white text-capitalize mb-4">Lorem ipsum</p> */}

                <button
                  type="button"
                  onClick={() =>
                    dispatch({
                      type: "load-and-toggle",
                      payload: { item: poi.id }
                    })
                  }
                  className="btn btn-xs btn-sale btn-outline-primary text-uppercase"
                >
                  View more
                </button>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="mb-0">
      {pois.length > 2 && (
        <div className={`fade-in ${pois.length === 3 ? "show" : ""}`}>
          <h3 className="text-uppercase mb-5">Area Points of Interest</h3>
          <div className="row">{poiRow}</div>
        </div>
      )}
    </div>
  );
};

export default PoiSection;
