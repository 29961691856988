import React from "react";
import { Link } from "react-router-dom";
import { Input, FormFeedback } from "reactstrap";

const VideoForm = ({ email, password, errors, handleChange, handleSubmit }) => {
  return (
    <div className="container login">
      <div className="row">
        <div className="col-lg-7 col-xl-3 align-items-center"></div>
        <div className="col-lg-7 col-xl-6 align-items-center">
          <div className="content">
            <div className="tab-heading">
              <h2 className="text-uppercase ">
                <span className="text-primary font-weight-bold ">Global</span>{" "}
                Concepts
              </h2>
            </div>

            <div className="tab-features ">
              <div className="tab-content px-6 py-7 " id="myTabContent">
                <div
                  className="tab-pane fade show active "
                  id="tours"
                  role="tabpanel"
                  aria-labelledby="tours-tab"
                >
                  <div className="container login ">
                    <form className="" action="" method="post">
                      <div className="row">
                        <div className="col-md-12">
                          <label for="exampleInputText">Login</label>
                          <div className="form-group form-group-icon form-group-icon-default mb-6 ">
                            <i className="fa fa-user" aria-hidden="true"></i>
                            <Input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Email"
                              autoComplete="email"
                              value={email}
                              onChange={e => {
                                handleChange(e);
                              }}
                              invalid={errors.email && true}
                            ></Input>
                            <FormFeedback>{errors.email}</FormFeedback>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <label for="exampleInputText">Password</label>
                          <div className="form-group form-group-icon form-group-icon-default mb-6 ">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                            <Input
                              type="password"
                              className="form-control"
                              name="password"
                              placeholder="Password"
                              autoComplete="new-password"
                              value={password}
                              onChange={e => handleChange(e)}
                              error={errors.password}
                              invalid={errors.password && true}
                            ></Input>
                            <FormFeedback>{errors.password}</FormFeedback>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2">
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn btn-lg btn-primary text-uppercase shadow-lg"
                        >
                          Login
                        </button>
                        <Link className="ml-4" to="/xreset">
                          Reset Password
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoForm;
