import { db_get, db_put, db_post } from "./dbService";
import { getCurrentUserId } from "./authService";

export async function getLogin(source) {
  return await db_get(`/logins/${source}`);
}

export async function putLogin(source, update) {
  return await db_put(`/logins/${source}`, update);
}

export async function resetUserPassword(userId, password) {
  return await db_put(`/admin/${userId}`, { password });
}

export async function sendUserMessage(userId, subject, message) {
  return await db_post(`/admin/message/${userId}`, { message, subject });
}

export async function getNewMessageCount() {
  const userId = getCurrentUserId();
  return await db_get(`/admin/message/new/${userId}`);
}

export async function getMessages(pageNum, pageSize) {
  const userId = getCurrentUserId();
  return await db_post(`/admin/message/all/${userId}`, { pageNum, pageSize });
}

export async function getSingleMessage(messageId) {
  const userId = getCurrentUserId();
  return await db_put(`/admin/message/${userId}`, { messageId });
}
