import React from "react";
import { Input, InputGroup, InputGroupAddon, CardHeader } from "reactstrap";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

const VipInventoryListHeader = ({
  startDate,
  setStartDate,
  startFocused,
  searching,
  records,
  requestSent,
  updateVipRequest,
  searchText,
  searchTextRef,
  setSearchText,
  setStartFocused
}) => {
  return (
    <CardHeader className="d-flex justify-content-between">
      <div className="col-3">
        <SingleDatePicker
          //style={{ backgroundColor: "#fff" }}
          showDefaultInputIcon={true}
          showClearDate={true}
          //className="form-controll daterange-picker daterange-picker-vip text-uppercase"
          inputIconPosition="after"
          small={true}
          block={false}
          numberOfMonths={1}
          date={startDate}
          onDateChange={e => {
            setStartDate(e === null ? moment() : e);
          }}
          focused={startFocused}
          onFocusChange={({ focused }) => setStartFocused(focused)}
          openDirection="down"
          hideKeyboardShortcutsPanel={true}
        />
      </div>

      {searching === true && <p>Searching Inventory...</p>}
      {searching === false && (
        <center>
          {records > 0 && (
            <h2 className="font-weight-bold text-dark">
              <span className="badge badge-primary">{records}</span> Bookings
              Available
            </h2>
          )}
          {records === 0 && requestSent === false && (
            <div>
              <h2 className="font-weight-bold text-dark">VIP Travel Request</h2>
              <button
                className="btn btn-primary btn-block text-uppercase"
                type="button"
                onClick={() => updateVipRequest()}
              >
                Request Unseen Inventory
              </button>
            </div>
          )}
        </center>
      )}

      <div className="col-3">
        <InputGroup>
          <InputGroupAddon
            className="border align-content-center"
            addonType="prepend"
          >
            <i className="fa fa-search p-2 mt-1"></i>
          </InputGroupAddon>
          <Input
            placeholder="Search Inventory"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            ref={searchTextRef}
          />
        </InputGroup>
      </div>
    </CardHeader>
  );
};

export default VipInventoryListHeader;
