import React from "react";

const CustomSelect = ({
  options,
  label,
  value,
  handleCustomSelect,
  idName
}) => {
  const selectOptions = options.map(option => {
    return (
      <option key={option.value + option.text} value={option.value}>
        {option.text}
      </option>
    );
  });

  return (
    <select
      value={value}
      onChange={e => handleCustomSelect(idName, e)}
      className="custom-select"
      style={{ width: "200px" }}
    >
      {label && <option value="0">{label}</option>}
      {selectOptions}
    </select>
  );
};

export default CustomSelect;
