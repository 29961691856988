import { useRef, useEffect } from "react";

/*

Service tools for 

*/

//Returns a value for the style attribute's opacity property
//-Fade header from mouse scroll.
//Parameters:
// currentScrollHeight = number of pixles scrolled over,
// setCurrentScrollHeight = handler
//
export function fadeHeader(currentScrollHeight, setCurrentScrollHeight) {
  window.onscroll = () => {
    const newScrollHeight = Math.ceil(window.scrollY / 50) * 50;
    //if (currentScrollHeight != newScrollHeight) {
    setCurrentScrollHeight(newScrollHeight);
    //}
  };
  return Math.min(10 / currentScrollHeight, 1) > 300
    ? 0
    : Math.min(10 / currentScrollHeight, 1);
}

// Custom Hook for adding Event Lisner if it's supported by element
export function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = event => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

export default {
  fadeHeader,
  useEventListener
};
