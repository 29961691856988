import React from "react";

const CruiseAreas = () => {
  return (
    <React.Fragment>
      <option value="NULL"> -- </option>
      <option value="Alaska">Alaska</option>
      <option value="Eastern Caribbean">Eastern Caribbean</option>
      <option value="Western Caribbean">Western Caribbean</option>
      <option value="Southern Caribbean">Southern Caribbean</option>
      <option value="Bahamas">Bahamas</option>
      <option value="Bermuda">Bermuda</option>
      <option value="Cuba">Cuba</option>
      <option value="Canada/New England">Canada/New England</option>
      <option value="Hawaii">Hawaii</option>
      <option value="Mexico">Mexico</option>
      <option value="Northern Europe">Northern Europe</option>
      <option value="Western Europe">Western Europe</option>
      <option value="Eastern Mediterranean">Eastern Mediterranean</option>
      <option value="Western Mediterranean">Western Mediterranean</option>
      <option value="Panama Canal/Central America">
        Panama Canal/Central America
      </option>
      <option value="River Cruise - Asia">River Cruise - Asia</option>
      <option value="River Cruise - Europe">River Cruise - Europe</option>
      <option value="River Cruise - U.S.">River Cruise - U.S.</option>
      <option value="South America">South America</option>
      <option value="Asia">Asia</option>
      <option value="Australia/New Zealand">Australia/New Zealand</option>
      <option value="Tahiti">Tahiti</option>
      <option value="U.S. Pacific/Northwest">U.S. Pacific/Northwest</option>
    </React.Fragment>
  );
};

export default CruiseAreas;
