import React, { useContext } from "react";
import _ from "lodash";
import moment from "moment";
import Benefits from "./Benefits";
import { Link } from "react-router-dom";
import { ModalContext } from "../data/ModalContext";
import { useLocation } from "react-router-dom";

const AvailableTab = ({ resort, activeTab, isRegistry }) => {
  const unSortedAvailableUnits = _.sortBy(
    resort.availableUnitsIds,
    "startDate",
    "desc"
  );
  const { dispatch } = useContext(ModalContext);
  const locationURL = useLocation();
  const availableUnits = unSortedAvailableUnits.map(unit => {
    return (
      <div
        key={unit._id}
        className="media media-list-view media-list-fullwidth media-border flex-column flex-md-row align-items-stretch mb-5 shadow-lg"
      >
        <div className="media-img position-relative">
          <img
            className="img-fluid position-relative"
            src={resort.img[0]}
            alt="gallery-img"
          ></img>
          <Link
            to={locationURL}
            onClick={() =>
              dispatch({
                type: "load-and-toggle",
                payload: {
                  item: resort._id,
                  collection: "Compass Resort"
                }
              })
            }
            className="media-img-overlay"
            data-fancybox="gallery"
          ></Link>
        </div>
        <div className="media-body">
          <div className="row">
            <div className="col-md-7 col-xl-8">
              <h4>
                <Link
                  className="media-title"
                  to={locationURL}
                  onClick={() =>
                    dispatch({
                      type: "load-and-toggle",
                      payload: {
                        item: unit._id,
                        collection: "Compass Availability"
                      }
                    })
                  }
                >
                  {unit.unitSize <= 0 ? "Studio" : `${unit.unitSize} Bedroom`}
                </Link>
              </h4>
              <p className="mb-md-0">{`${moment(unit.startDate).format(
                "dddd"
              )} Check-In`}</p>
              <p className="mb-md-0 mt-3">{`Check in Time: ${moment(
                resort.checkIn.replace("Check in Time:", "").trim(),
                "H:mm"
              ).format("h:mm a")}`}</p>
              <p className="mb-md-0 mt-1">{`Check out Time: ${moment(
                resort.checkOut.replace("Check out Time:", "").trim(),
                "H:mm"
              ).format("h:mm a")}`}</p>

              <div className="date-view">
                <span>
                  <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                  {moment(unit.startDate).format("L")}
                </span>
                -
                <span>
                  <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                  {moment(unit.endDate).format("L")}
                </span>
              </div>
            </div>

            <div className="col-md-5 col-xl-4">
              <div className="bg-primary text-center p-3 view-details">
                {unit.nights !== 7 && (
                  <React.Fragment>
                    <h2 className="text-white font-weight-bold h1">
                      ${Math.ceil(unit.price)}
                    </h2>
                    <p className="text-white text-capitalize">{"Per Night"}</p>
                  </React.Fragment>
                )}
                {unit.nights === 7 && (
                  <React.Fragment>
                    <h2 className="text-white font-weight-bold h1">
                      ${Math.ceil(unit.fullPrice)}
                    </h2>
                    <p className="text-white text-capitalize">
                      {"For " + unit.nights + " Nights"}
                    </p>
                  </React.Fragment>
                )}
                <div>
                  <Link
                    to={locationURL}
                    onClick={() =>
                      dispatch({
                        type: "load-and-toggle",
                        payload: {
                          item: resort._id,
                          collection: "Compass Resort"
                        }
                      })
                    }
                    className="btn btn-xs w-75 mx-auto btn-outline-white btn-booking text-uppercase mb-2"
                  >
                    Info
                  </Link>
                </div>
                <div>
                  <Link
                    to={locationURL}
                    onClick={() =>
                      dispatch({
                        type: "load-and-toggle",
                        payload: {
                          item: unit._id,
                          collection: "Compass Availability"
                        }
                      })
                    }
                    className="btn btn-xs w-75 mx-auto btn-outline-white btn-booking text-uppercase mb-2"
                  >
                    Book
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div
      className={`tab-pane ${activeTab === 4 ? "active" : ""}`}
      id="overView"
      role="tabpanel"
      aria-labelledby="overView-tab"
    >
      <div className="row mb-5">
        {isRegistry && (
          <div className="col-md-9 col-lg-8 animated fadeIn">
            <div className="hover-profile mb-7 mb-lg-9">
              <div className="row">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <div className="mb-6 mb-md-0">
                    <img
                      className="w-100"
                      src="/assets/img/home/deal/deal-03.jpg"
                      alt="Registry Request"
                    ></img>
                  </div>
                </div>

                <div className="col-md-6 col-lg-7 col-xl-8">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="mb-6 mb-md-4 mb-lg-6">
                      <h2 className="text-uppercase font-weight-bold">
                        Registry Request
                      </h2>
                      <p className="mb-0">{resort.resortName}</p>
                    </div>

                    <div className="icon-setting">
                      <div>
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>

                  <p className="line-height-26">
                    PLEASE NOTE: The Registry of Resorts is intended for
                    vacation accommodations in a certain area, not for a
                    specific resort. The resort you have selected as your first
                    choice (ABOVE), may not be available and alternative resorts
                    in the area you have specified maybe offered to you.
                  </p>
                  <form>
                    <div className="form-group row">
                      <label for="first" className="col-sm-5 col-form-label">
                        <strong>First Choice Start Date:</strong>
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          readonly=""
                          className="form-control form-control-plaintext bg-transparent border"
                          value={"1/1/2020"}
                        ></input>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label for="first" className="col-sm-5 col-form-label">
                        <strong>Second Choice Start Date:</strong>
                      </label>
                      <div className="col-sm-7">
                        <input
                          type="text"
                          readonly=""
                          className="form-control form-control-plaintext bg-transparent border"
                          value={"2/1/2020"}
                        ></input>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label for="inputState">
                          <strong>Number of Weeks:</strong>
                        </label>
                        <select id="inputState" className="form-control">
                          <option selected>Choose...</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3+</option>
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <label for="inputState">
                          <strong>Bedroom Size:</strong>
                        </label>
                        <select id="inputState" className="form-control">
                          <option selected>Choose...</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3+</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        for="exampleFormControlTextarea1"
                        className="text-gray-colo"
                      >
                        <strong>INSTRUCTIONS </strong>Please be certain to input
                        here any alternate location or dates you would be
                        interested in booking.
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-primary mb-2"
                    >
                      Send Request
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isRegistry && (
          <div className="col-md-7 col-lg-8 animated fadeIn">
            {availableUnits}
          </div>
        )}
        <Benefits resort={resort} />
      </div>
    </div>
  );
};

export default AvailableTab;
