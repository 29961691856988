import React from "react";

const StepTwoButtons = ({ handlePrevStep, handleNextStep }) => {
  return (
    <div className="d-flex justify-content-between">
      <button
        type="button"
        onClick={() => handlePrevStep()}
        className="btn btn-primary text-uppercase"
      >
        Back
      </button>

      <button
        type="button"
        onClick={() => handleNextStep()}
        className="btn btn-primary text-uppercase"
      >
        Confirm
      </button>
    </div>
  );
};

export default StepTwoButtons;
