import React, { useState, useEffect } from "react";
import UserBookingSingle from "./UserBookingSingle";
import { getBookings } from "../../services/bookingService";

const UserBookings = ({ activeTab }) => {
  const [bookings, setBookings] = useState([{ date: "" }]);

  useEffect(() => {
    getBookings(activeTab).then(setBookings);
  }, [activeTab]);

  const data = {
    date: Date.now(),
    title: "Bla Bla Bla",
    priceDescription: "jgjhgjhjhjh",
    price: 888,
    description: "jbjhhghhgfhgfhgfgh",
    inventory: "compass",
    created_on: Date.now(),
    inventory: "registry",
    compass: "hhhhhhhhhh",
    registry: "eeeeeeeee",
    vip: "wwwwwwwww",
    cruise: "ppppppppp"
  };
  return (
    <div className="table-dashboard mb-7 fade-in">
      <div className="row">
        <div className="col-12">
          <h2 className="text-uppercase mb-3">{activeTab}</h2>
          <table className="table table-hover">
            {bookings.length > 0 && (
              <tbody>
                {bookings.map(book => {
                  return (
                    <UserBookingSingle
                      key={book._id}
                      data={book}
                      type={activeTab}
                    />
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserBookings;
