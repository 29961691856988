import React from "react";

const CruiseCounterPic = ({ step }) => {
  return (
    <div className="col-md-5 col-lg-4">
      <h3 className="mb-5">Booking Details</h3>
      <div className="card bg-smoke mb-6 mb-md-0">
        <img
          className="card-img-top"
          src="http://www.goldcrownresort.com/images/admin/cruises/Miracle.jpg"
          alt="Cruise"
        ></img>

        <div className="card-footer mt-6">
          <h2 className="mb-0">
            <span>Step: </span>
            <span className="text-primary font-weight-bold">{step}</span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default CruiseCounterPic;
