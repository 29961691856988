import React from "react";
import { Link } from "react-router-dom";

const TravelCenterPlatform = ({ name, description, img, handleClick }) => {
  const descriptionArray = description.trim().split(" ");
  const firstWord = descriptionArray[0];
  descriptionArray[0] = "";
  const secondText = descriptionArray.join(" ").trim();
  return (
    <React.Fragment>
      <div className={`col-md-6 col-lg-4 element-item `}>
        <div className="media media-hoverable justify-content-center mb-2 mb-md-4 mb-lg-6">
          <Link
            onClick={() => {
              handleClick();
            }}
            className="media-content"
          >
            <img className="media-img" src={img} alt={name}></img>
            <div className="media-object">
              <h3 className="text-uppercase mb-0">{name}</h3>
            </div>
            <div className="media-img-overlay">
              <div className="overlay-content">
                <h3 className="">
                  {firstWord} <span className="text-primary">{secondText}</span>
                </h3>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TravelCenterPlatform;
