import React, { useState } from "react";
import { userList } from "../services/userService";
import ReactTable from "react-table";
import moment from "moment";
import "react-table/react-table.css";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import UserModal from "./userModal";
import NewUserModal from "./NewUserModal";
import PasswordResetAdminModal from "./PasswordResetAdminModal";
import SendUserMessageModal from "./SendUserMessageModal";

const handleViewUser = (id, toggleModal, view, setId, setView) => {
  setView(view);
  setId(id);
  toggleModal();
};

const ClientTable = () => {
  const [tableData, setTableData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newModalIsOpen, setNewModalIsOpen] = useState(false);
  const [passwordResetIsOpen, setPasswordResetIsOpen] = useState(false);
  const [sendMessageIsOpen, setSendMessageIsOpen] = useState(false);
  const [id, setId] = useState("");
  const [view, setView] = useState(true);
  const [refresh, setRefresh] = useState(0);

  React.useEffect(() => {
    userList()
      .then(result => {
        return result.data;
      })
      .then(setTableData);
  }, [refresh]);

  function refreshHandler() {
    const refreshCounter = refresh + 1;
    setRefresh(refreshCounter);
  }

  function toggleModal() {
    if (modalIsOpen) setModalIsOpen(false);
    else setModalIsOpen(true);
  }
  function toggleNewModal() {
    if (newModalIsOpen) setNewModalIsOpen(false);
    else setNewModalIsOpen(true);
  }
  function getStatusClass(status) {
    const statusMap = {
      Active: "success",
      Suspended: "danger",
      New: "primary"
    };

    return `text-${statusMap[status]}`;
  }
  function getLevelClass(level) {
    const levelMap = {
      Gold: "warning",
      Silver: "primary",
      Admin: "secondary"
    };

    return `text-${levelMap[level]}`;
  }
  function addHandler(user) {
    //user.created_on = moment();
    tableData.unshift(user);
    setTableData(tableData);
  }
  function editHandler(id, user) {
    //const listUpdate = tableData.filter(listUser => listUser._id !== id);
    const index = tableData.findIndex(listUser => listUser._id === id);
    tableData[index] = user;
    setTableData(tableData);
  }

  function togglePasswordResetModal() {
    setPasswordResetIsOpen(passwordResetIsOpen => !passwordResetIsOpen);
  }
  function toggleSendMessageModal() {
    setSendMessageIsOpen(sendMessageIsOpen => !sendMessageIsOpen);
  }

  function handleResetPassword(id) {
    setId(id);
    togglePasswordResetModal();
    //console.log(passwordResetIsOpen, row);
  }

  function handleSendMessage(id) {
    setId(id);
    toggleSendMessageModal();
  }

  const tableColumns = [
    {
      Header: "#",
      accessor: "_id",
      show: false,
      className: "text-center"
    },
    {
      Header: "Date Added",
      accessor: "created_on",
      className: "text-center",
      Cell: row => moment(row.original.created_on).format("MMMM DD, YYYY")
    },
    {
      Header: "Email",
      accessor: "email",
      className: "text-center"
    },
    {
      Header: "Last",
      accessor: "last",
      className: "text-center"
    },
    {
      Header: "First",
      accessor: "name",
      className: "text-center"
    },
    {
      Header: "Level",
      accessor: "level",
      maxWidth: 100,
      Cell: row => (
        <span className={getLevelClass(row.original.level)}>
          {row.original.level}
        </span>
      ),
      className: "text-center"
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 100,
      Cell: row => (
        <span className={getStatusClass(row.original.status)}>
          {row.original.status}
        </span>
      ),
      className: "text-center"
    },
    {
      Header: "Actions",
      accessor: "actions",
      maxWidth: 300,
      minWidth: 180,
      sortable: false,
      Cell: row => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          <Button
            theme="white"
            onClick={() =>
              handleViewUser(
                row.original._id,
                toggleModal,
                "view",
                setId,
                setView
              )
            }
          >
            <i className="fa fa-eye"></i>
          </Button>
          <Button
            theme="white"
            onClick={() =>
              handleViewUser(
                row.original._id,
                toggleModal,
                "edit",
                setId,
                setView,
                editHandler
              )
            }
          >
            <i className="fa fa-pencil"></i>
          </Button>
          <Button
            theme="white"
            onClick={() => handleSendMessage(row.original._id)}
          >
            <i className="fa fa-envelope"></i>
          </Button>
          <Button
            theme="white"
            onClick={() => handleResetPassword(row.original._id)}
          >
            <i className="fa fa-key"></i>
          </Button>
        </ButtonGroup>
      )
    }
  ];

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header py-4">
        <Col sm="4" className="d-flex ml-auto my-auto">
          <Button
            className="btn btn-success btn-lg btn-block"
            onClick={() => toggleNewModal()}
          >
            Add New User
          </Button>
        </Col>
      </Row>
      <Card className="p-0">
        <CardHeader className="p-0">
          <Container
            fluid
            className="file-manager__filters border-bottom"
          ></Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              columns={tableColumns}
              data={tableData}
              pageSize={10}
              showPageSizeOptions={false}
              resizable={false}
            />
          </div>
        </CardBody>
      </Card>
      <SendUserMessageModal
        modal={sendMessageIsOpen}
        toggle={toggleSendMessageModal}
        id={id}
      />
      <PasswordResetAdminModal
        modal={passwordResetIsOpen}
        toggle={togglePasswordResetModal}
        id={id}
      />
      <UserModal
        modal={modalIsOpen}
        toggle={toggleModal}
        id={id}
        view={view}
        editHandler={editHandler}
      />
      <NewUserModal
        modal={newModalIsOpen}
        toggle={toggleNewModal}
        addHandler={addHandler}
        refreshHandler={refreshHandler}
      />
    </Container>
  );
};

export default ClientTable;
