import React, { useState, useRef, useCallback } from "react";
import { useEventListener } from "./FadeoutNavbar";
import { Link } from "react-router-dom";

const HeaderDropdown = props => {
  const [isDropped, setIsDropped] = useState(false);
  const liRef = useRef();

  const dropHandler = useCallback(
    e => {
      if (e.type === "mouseover" && e.screenX > 890) setIsDropped(true);
      else setIsDropped(false);
    },
    [setIsDropped]
  );

  useEventListener("mouseout", dropHandler, liRef.current);
  useEventListener("mouseover", dropHandler, liRef.current);

  return (
    <li ref={liRef}>
      <Link to="/dashboard">{props.menu}</Link>
      <ul
        className={`dropdown drop-up ${isDropped ? "none" : ""} `}
        style={{
          right: "auto",
          display: `${isDropped ? "block" : "none"}`
        }}
        props={{ ...props }}
      >
        {props.children}
      </ul>
    </li>
  );
};

export default HeaderDropdown;
