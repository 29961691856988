import React from "react";

const StepOneButton = ({ handleNextStep }) => {
  return (
    <div className="text-center text-md-left text-lg-right">
      <button
        type="button"
        onClick={() => handleNextStep()}
        className="btn btn-primary text-uppercase"
      >
        Next
      </button>
    </div>
  );
};

export default StepOneButton;
