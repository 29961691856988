import React, { Component } from "react";
import moment from "moment";
//import PageTitle from "../components/PageTitle";
//import { searchCruises } from "../services/serverCallsService";

//import { FormGroup, Input, Label } from "reactstrap";
import StepOneForm from "../components/step1form";
import StepTwoForm from "../components/Step2form";
import CustomCruiseBanner from "./CustomCruiseBanner";
import CruiseProgress from "../components/CruiseProgress";
import StepTwoButtons from "../components/StepTwoButtons";
import CruiseCounterPic from "../components/CruiseCounterPic";
import StepOneButton from "../components/StepOneButton";
//import CruiseRequest from "../forms/cruiseRequest";
//import CustomCruise from "../forms/cruiseRequest";

class CustomCruise extends Component {
  state = {
    step: 1,
    cabin: "",
    area: "",
    startDate: moment(),
    startFocused: null,
    survey: {}
  };
  handleNextStep = () => {
    const { step: lastStep } = this.state;
    const step = lastStep + 1;
    this.setState({ step });
  };
  handlePrevStep = () => {
    const { step: lastStep } = this.state;
    const step = lastStep - 1;
    this.setState({ step });
  };
  handleChangeToStep = step => {
    this.setState({ step });
  };
  handleSelectCabin = cabin => {
    this.setState({ cabin });
  };
  handleCruiseAreaChange = e => {
    const area = e.target.value;
    this.setState({ area });
  };
  setStartDate = date => {
    this.setState({ startDate: date });
  };
  setstartFocused = startFocused => {
    this.setState({ startFocused });
  };

  inputHandler = e => {
    const { survey } = this.state;
    survey[e.target.name] = e.target.value;
    console.log(survey);
    this.setState({ survey });
  };

  render() {
    //const { pageNameGetter } = this.props;

    return (
      <div className="main-wrapper cruise">
        <CustomCruiseBanner showButton="false" />
        <section className="py-8 py-md-10">
          <div className="container">
            <CruiseProgress
              step={this.state.step}
              handleChangeToStep={this.handleChangeToStep}
            />
            <div className="row">
              <div className="col-md-7 col-lg-8 order-1 order-md-0">
                <h3 className="text-capitalize mb-5">Cruise History</h3>
                {this.state.step === 1 && (
                  <StepOneForm
                    inputHandler={this.inputHandler}
                    survey={this.state.survey}
                  />
                )}
                {this.state.step === 1 && (
                  <StepOneButton handleNextStep={this.handleNextStep} />
                )}
                {this.state.step === 2 && (
                  <StepTwoForm
                    step={this.state.step}
                    cabin={this.state.cabin}
                    area={this.state.area}
                    handleSelectCabin={this.handleSelectCabin}
                    handleCruiseAreaChange={this.handleCruiseAreaChange}
                    startDate={this.state.startDate}
                    setStartDate={this.setStartDate}
                    startFocused={this.state.startFocused}
                    setstartFocused={this.setstartFocused}
                  />
                )}

                {this.state.step > 1 && (
                  <StepTwoButtons
                    handleNextStep={this.handleNextStep}
                    handlePrevStep={this.handlePrevStep}
                  />
                )}
              </div>

              <CruiseCounterPic step={this.state.step} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CustomCruise;
