import React, { useState } from "react";
//import MainHeader from "../components/MainHeader";
//import { pageLogger } from "../services/logService";
import Footer from "../components/Footer";
import { Container, Row, Col } from "reactstrap";
import LoginModal from "../components/LoginModal";
import CruiseModal from "../components/CruiseModal";
import InqueryModal from "../components/InqueryModal";
import ResponsiveHeader from "../dev/ResponsiveHeader";

import "../assets/plugins/font-awesome/css/font-awesome.min.css";
import "../assets/plugins/no-ui-slider/nouislider.min.css";
import "../assets/plugins/fancybox/jquery.fancybox.min.css";
import "../assets/plugins/fancybox/fancyMorph.css";
import "../assets/plugins/isotope/isotope.min.css";
import "../assets/plugins/animate/animate.css";
import "../assets/plugins/daterangepicker/css/daterangepicker.css";
import "../assets/plugins/menuzord/css/menuzord.css";
import "../assets/plugins/menuzord/css/menuzord-animations.css";
import "../assets/plugins/dzsparallaxer/dzsparallaxer.css";
import "../assets/plugins/selectric/selectric.css";
import "../assets/plugins/slick/slick.css";
import "../assets/plugins/slick/slick-theme.css";

const UserDashboard = props => {
  const [loginModal, setLoginModal] = useState(false);
  const [inqueryModal, setInqueryModal] = useState(false);
  const [cruiseModal, setCruiseModal] = useState(false);
  //const [path, setPath] = useState(props.match.path);
  const { path } = props.match;

  const children = React.Children.map(props.children, child => {
    return React.cloneElement(child, {
      setCruiseModal,
      setInqueryModal,
      setLoginModal
    });
  });

  return (
    <Container fluid>
      <Row>
        <Col tag="main" className="main-content p-0" lg="12" md="12" sm="12">
          <ResponsiveHeader page={path} setLoginModal={setLoginModal} />
          {children}
          <LoginModal
            modal={loginModal}
            toggle={() => setLoginModal}
            className
          />
          <InqueryModal
            modal={inqueryModal}
            toggle={setInqueryModal}
            //inqueryItem={inqueryItem}
            inqueryModal={setInqueryModal}
          />
          <CruiseModal modal={cruiseModal} toggle={setCruiseModal} />
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default UserDashboard;
