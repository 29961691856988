import React from "react";
import SelectRoom from "../components/SelectRoom";
import CruiseAreas from "../components/CruiseAreas";
//import moment from "moment";
import { SingleDatePicker } from "react-dates";

import { FormGroup, Input, Label } from "reactstrap";
import "react-dates/initialize";

const StepTwoForm = ({
  step,
  cabin,
  area,
  handleSelectCabin,
  handleCruiseAreaChange,
  startDate,
  setStartDate,
  startFocused,
  setstartFocused
}) => {
  return (
    <form action="" method="post" target="_blank">
      <div className="row">
        <div className="col-lg-12">
          <label for="exampleFormControlTextarea1">
            Names & Dates of Birth for ALL Passengers?
          </label>
          <div className="form-group mb-5">
            <label for="exampleFormControlTextarea1">
              Please provide us with the Names and the Dates of Birth for all
              Passengers
            </label>
            <textarea
              className="form-control border-0 bg-smoke"
              rows="7"
            ></textarea>
          </div>
          <label for="exampleFormControlTextarea1">
            Select your Cabin Type
          </label>
          <SelectRoom cabin={cabin} handleSelectCabin={handleSelectCabin} />
          <label for="exampleFormControlTextarea1">
            Select your Cruise Area
          </label>
          <div className="form-group form-group-icon form-group-icon-dark mb-5">
            <Input
              type="select"
              onChange={e => handleCruiseAreaChange(e)}
              value={area}
              placeholder={"Cruise Areas"}
            >
              <CruiseAreas />
            </Input>
          </div>
          <label for="exampleFormControlTextarea1">
            Dates you wish to travel
          </label>
          <div className="form-group form-group-icon form-group-icon-dark mb-5">
            <i className="fa fa-calendar" aria-hidden="true"></i>
            <SingleDatePicker
              noBorder={true}
              className="form-control daterange-picker text-uppercase"
              inputIconPosition="after"
              small={true}
              block={false}
              numberOfMonths={1}
              date={startDate}
              onDateChange={date => setStartDate(date)}
              focused={startFocused}
              onFocusChange={({ focused }) => setstartFocused(focused)}
              openDirection="up"
              hideKeyboardShortcutsPanel={true}
            />
          </div>
          <label for="exampleFormControlTextarea1">Dining Preferences *</label>
          <div className="form-group form-group-icon form-group-icon-dark mb-5">
            <Input
              type="select"
              onChange={e => handleCruiseAreaChange(e)}
              value={area}
              placeholder={"Cruise Areas"}
            >
              <option value="NULL"> -- </option>
              <option value="Early Dining">Early Dining</option>
              <option value="Late Dining">Late Dining</option>
              <option value="Open Dining">Open Dining</option>
            </Input>
          </div>
          <p>
            * Please note that dining preferences are live and the closest
            available option to your preference will be selected. Open Dining
            typically runs from 5:45 pm - 9:30 pm but varies with each cruise
            line that offers this option.
          </p>

          <label for="exampleFormControlTextarea1">
            Please tell us your preferred port of departure
          </label>

          <div className="col-lg-6">
            <div className="form-group">
              <label for="inputName">First Choice</label>
              <input
                type="text"
                className="form-control border-0 bg-smoke"
              ></input>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <label for="inputName">Second Choice</label>
              <input
                type="text"
                className="form-control border-0 bg-smoke"
              ></input>
            </div>
          </div>
          <div className="form-group mb-5">
            <label for="exampleFormControlTextarea1">
              Are you Retired or Active United States Military?
            </label>
            <div className="form-group mb-5">
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" /> Yes
                </Label>
              </FormGroup>
            </div>
          </div>

          <div className="form-group mb-5">
            <div className="form-group mb-5">
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" /> No
                </Label>
              </FormGroup>
            </div>
          </div>
          <div className="form-group mb-5">
            <label for="exampleFormControlTextarea1">
              If so please provide what branch
            </label>
            <textarea
              className="form-control border-0 bg-smoke"
              rows="7"
            ></textarea>
          </div>
          <div className="form-group mb-5">
            <label for="exampleFormControlTextarea1">
              Are any travelers 55 year or older?
            </label>
            <div className="form-group mb-5">
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" /> Yes
                </Label>
              </FormGroup>
            </div>
          </div>

          <div className="form-group mb-5">
            <div className="form-group mb-5">
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" /> No
                </Label>
              </FormGroup>
            </div>
          </div>
          <div className="form-group mb-5">
            <label for="exampleFormControlTextarea1">
              Are any travelers an airline employee?
            </label>
            <div className="form-group mb-5">
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" /> Yes
                </Label>
              </FormGroup>
            </div>
          </div>

          <div className="form-group mb-5">
            <div className="form-group mb-5">
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" /> No
                </Label>
              </FormGroup>
            </div>
          </div>
          <div className="form-group mb-5">
            <label for="exampleFormControlTextarea1">
              If so please provide which airline
            </label>
            <textarea
              className="form-control border-0 bg-smoke"
              rows="7"
            ></textarea>
          </div>
          <div className="form-group mb-5">
            <label for="exampleFormControlTextarea1">
              Global Concepts can book with any of the major cruise lines;
              please let us know if you prefer a specific cruise line or have
              any special instructions.
            </label>
            <textarea
              className="form-control border-0 bg-smoke"
              rows="7"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoForm;
