import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardTitle,
  CardText,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import {
  getAgents,
  editRequest,
  getFreeText,
  getRequests
} from "../../services/bookingService";
import { BookingRequestContext } from "../../data/ModalContext";
import classnames from "classnames";
import moment from "moment";
import CustomSelect from "../../custom/CustomSelect";
import { getCurrentUserId } from "../../services/authService";

const BookingRequestModal = () => {
  const {
    brModal,
    setBRmodal,
    //setSelectedRequest,
    selectedRequest,
    agents,
    setAgents,
    selectedRecordId,
    itemFreeText,
    setItemFreeText,
    selectedAgent,
    setSelectedAgent,
    selectedStatus,
    setSelectedStatus,
    setPages,
    setLoading,
    setTableData,
    page
  } = useContext(BookingRequestContext);

  const [activeTab, setActiveTab] = useState("1");
  const [responded, setResponded] = useState("0");
  const [respondedOn, setRespondedOn] = useState("0");
  const [assignedOn, setAssignedOn] = useState("0");
  const [bookedOn, setBookedOn] = useState("0");

  const freeTextRef = useRef(null);
  const pageSize = 10;

  useEffect(() => {
    getAgents().then(setAgents);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = event => {
    setLoading(true);
    event.preventDefault();
    const userId = getCurrentUserId();

    const request = {
      assignedOn: null,
      respondedOn: null,
      user: userId,
      agent: selectedAgent,
      assigned: selectedAgent !== "999999999999999999999999" ? true : false,
      assigned_on: assignedOn,
      responded: selectedStatus === "Pending" ? false : true,
      responded_on: respondedOn,
      booked: selectedStatus === "Booked" ? true : false,
      bookedOn,
      booked_on: bookedOn,
      status: selectedStatus,
      freeText:
        freeTextRef.current.value === "" ? null : freeTextRef.current.value
    };

    if (selectedRecordId !== "") {
      editRequest(selectedRecordId, request);
      getFreeText(selectedRecordId).then(setItemFreeText);
    }
    getAgents().then(setAgents);
    freeTextRef.current.value = null;
    setBRmodal(false);
    getRequests(page + 1, pageSize).then(res => {
      setTableData(res.list);
      setPages(res.pages);
      setLoading(false);
    });
  };

  const bookingAgentSelect = agents.map(agent => {
    if (agent) {
      return {
        value: agent._id,
        text: `${agent.last}, ${agent.name}`
      };
    } else {
      return {
        value: "999999999999999999999999",
        text: ""
      };
    }
  });

  const status = [
    {
      value: "Pending",
      text: "Pending"
    },
    {
      value: "Responded",
      text: "Responded"
    },
    {
      value: "Booked",
      text: "Booked"
    }
  ];
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  function handleCustomSelect(name, e) {
    switch (name) {
      case "agent":
        if (e.target.value !== "999999999999999999999999")
          setAssignedOn(Date.now());
        setSelectedAgent(e.target.value);

        break;
      case "status":
        setSelectedStatus(e.target.value);
        if (e.target.value === "Responded") setRespondedOn(Date.now());
        else if (e.target.value === "Booked" && responded !== true) {
          setResponded(Date.now());
          setBookedOn(Date.now());
        } else if (e.target.value === "Booked") setBookedOn(Date.now());
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <Modal
        isOpen={brModal}
        toggle={() => setBRmodal(false)}
        className={"modal-dialog modal-lg modal-dialog-centered"}
      >
        <ModalHeader toggle={() => setBRmodal(false)}>
          <div className="modal-title text-uppercase font-weight-bold">
            Booking Request
          </div>
          <p>
            {selectedRequest.inventory} -{" "}
            <span
              className={classnames("badge badge-pill", {
                "badge-warning": selectedRequest.status === "Pending",
                "badge-success": selectedRequest.status === "Booked",
                "badge-info": selectedRequest.status === "Requested"
              })}
            >
              {" "}
              {selectedRequest.status}
            </span>
          </p>
        </ModalHeader>

        <ModalBody>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Item
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Client
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Agent
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  Free Text
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <Card body>
                      <h3 className="mb-2">{selectedRequest.title}</h3>
                      <CardTitle>{selectedRequest.description}</CardTitle>
                      <CardText>
                        <span className="text-info">Travel Date: </span>
                        {moment(selectedRequest.date).format("MM-DD-YYYY")}
                      </CardText>

                      {selectedRequest.price > 0 && (
                        <div>
                          <CardTitle className="mb-0">
                            ${parseInt(selectedRequest.price)}
                          </CardTitle>
                          <p>{selectedRequest.priceDescription}</p>
                          <a href="#" className="mt-2">
                            View Item
                          </a>
                        </div>
                      )}
                    </Card>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="2">
                <Card body>
                  <CardTitle>Request</CardTitle>
                  <Form onSubmit={handleSubmit}>
                    <Row form>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="clientName">Client</Label>
                          <Input
                            type="text"
                            name="clientName"
                            id="clientName"
                            value={selectedRequest.email}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="phone1">Phone</Label>
                          <Input
                            type="text"
                            name="phone1"
                            id="phone1"
                            placeholder="Missing Phone"
                            value={selectedRequest.phone}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="email1">Email</Label>
                          <Input
                            type="text"
                            name="email1"
                            id="email1"
                            placeholder="Missing Email"
                            value={selectedRequest.email}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="message1">Message</Label>
                      <Input
                        type="text"
                        name="message1"
                        id="message1"
                        placeholder="No Message Left"
                        value={selectedRequest.message}
                        readOnly
                      />
                    </FormGroup>
                  </Form>
                </Card>
              </TabPane>

              <TabPane tabId="3">
                <Row>
                  <Col sm="4">
                    <Card body>
                      <CardTitle>Agent Assignment & Request Status</CardTitle>
                      <CardText>Assign clients to Agents</CardText>
                      <div className="form-group">
                        <CustomSelect
                          options={bookingAgentSelect}
                          label="Assign Agent..."
                          value={selectedAgent}
                          idName="agent"
                          handleCustomSelect={handleCustomSelect}
                        />
                      </div>
                      <CardText>Request Status</CardText>
                      <div className="form-group">
                        <CustomSelect
                          options={status}
                          value={selectedStatus}
                          idName="status"
                          handleCustomSelect={handleCustomSelect}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col sm="8">
                    <Card body>
                      <CardTitle>Add Free Text</CardTitle>
                      <div className="input-group">
                        <textarea
                          className="form-control"
                          rows="6"
                          aria-label="With textarea"
                          ref={freeTextRef}
                        ></textarea>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col sm="4"></Col>
                  <Col sm="8">
                    <Card body>
                      {" "}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Save changes
                      </button>
                    </Card>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="4">
                <Row>
                  <Col sm="12">
                    <Card body>
                      <div className="docSearch-content ">
                        <ListGroup>
                          {itemFreeText.map(ftData => {
                            return (
                              <ListGroupItem key={ftData._id}>
                                <ListGroupItemHeading>
                                  {moment(ftData.created_on).format(
                                    "MM/DD/YYYY - HH:mm:ss"
                                  )}{" "}
                                  - [{ftData.user.last}, {ftData.user.name}]
                                </ListGroupItemHeading>
                                <ListGroupItemText>
                                  {ftData.freeText}
                                </ListGroupItemText>
                              </ListGroupItem>
                            );
                          })}
                        </ListGroup>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            <div className="clearfix">
              <div className="float-right">
                <h6 className="text-primary text-right">TRIP ID</h6>
                <h5 className="text-info">{selectedRecordId}</h5>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setBRmodal(false)}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BookingRequestModal;
