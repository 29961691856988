import React, { useRef } from "react";
import { toast } from "react-toastify";
import { sendUserMessage } from "../services/adminService";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Input,
  Label,
  FormGroup
} from "reactstrap";

const SendUserMessageModal = ({ modal, toggle, id }) => {
  const messageRef = useRef(null);
  const subjectRef = useRef(null);
  async function handleSubmit(e) {
    try {
      e.preventDefault();

      const response = await sendUserMessage(
        id,
        subjectRef.current.value,
        messageRef.current.value
      );

      toast.success(`${response}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      messageRef.current.value = "";
      toggle();
    } catch (ex) {
      toast.error(`Error: ${ex.response.data}`, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={() => toggle()}
        className={"modal-dialog modal-lg modal-dialog-centered"}
      >
        <ModalHeader
          className="modal-title text-uppercase font-weight-bold"
          toggle={() => toggle()}
        >
          Send User a Message
        </ModalHeader>
        <ModalBody>
          <form className="" onSubmit={handleSubmit}>
            <div className="form-row form-group">
              <Col>
                <FormGroup>
                  <Label for="subject">Subject</Label>
                  <Input
                    className="form-control bg-smoke"
                    type="text"
                    name="subject"
                    id="subject"
                    innerRef={subjectRef}
                    required={true}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="newMessage">Message</Label>
                  <Input
                    className="form-control bg-smoke"
                    type="textarea"
                    name="newMessage"
                    id="newMessage"
                    innerRef={messageRef}
                  />
                </FormGroup>
              </Col>
            </div>
            <Row>
              <Col>
                <button className="btn btn-success btn-block text-uppercase">
                  Send
                </button>
              </Col>
            </Row>
          </form>
        </ModalBody>
        <ModalFooter>
          <p className="mb-1">
            <button onClick={() => toggle()}>Close</button>
          </p>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SendUserMessageModal;
