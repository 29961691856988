import React from "react";
import CustomCruiseBanner from "./CustomCruiseBanner";
import ParalaxBanner from "../views/ParalaxBanner";


const Home = () => {
  return (
    <React.Fragment>
      <CustomCruiseBanner />

      <ParalaxBanner
        title={"Subscribe to reacive amazing offer and tips"}
        titleImg={"/assets/img/home-city/subscribs-bg.jpg"}
        color={"#ff891e"}
        opacity={".1"}
      />
    </React.Fragment>
  );
};

export default Home;
