import React from "react";
import { Switch, Route } from "react-router-dom";

import CruiseFeatured from "./cruiseFeatured";
import CustomCruise from "./customCruise1";

const CruiseWrapper = () => {
  return (
    <div className="main-wrapper cruise">
      <Switch>
        <Switch>
          <Route path={`${"/cruise/custom"}`}>
            <CustomCruise />
          </Route>
          <Route path={`${"/cruise"}`}>
            <CruiseFeatured />
          </Route>
        </Switch>
      </Switch>
    </div>
  );
};

export default CruiseWrapper;
