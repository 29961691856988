import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row
} from "reactstrap";
import { getUser, editUser } from "../services/userService";

const UserModal = ({ modal, toggle, id, view, editHandler, addHandler }) => {
  const readOnly = view === "view" ? true : false;
  const [name, setName] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("New");
  const [level, setLevel] = useState("Gold");

  React.useEffect(() => {
    if (id !== "") {
      getUser(id)
        .then(result => {
          return result.data;
        })
        .then(user => {
          setEmail(user.email);
          setLast(user.last);
          setLevel(user.level);
          setName(user.name);
          setStatus(user.status);
        });
    }
  }, [id]);

  async function saveEdit() {
    const user = {
      name,
      last,
      email,
      status,
      level
    };
    toggle();

    try {
      user._id = id;
      editHandler(id, user);
      await editUser(id, user);
    } catch (ex) {
      alert("Something went wrong...");
      //window.location("/dashboard/users");
    }
  }
  const handleSubmit = event => {
    event.preventDefault();

    saveEdit();
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={() => toggle()}
        className={"modal-dialog modal-lg modal-dialog-centered"}
      >
        <ModalHeader
          className="modal-title text-uppercase font-weight-bold"
          toggle={() => toggle()}
        >
          {`${view}`} User: #{`${id}`}
        </ModalHeader>
        <ModalBody>
          <form className="" onSubmit={handleSubmit}>
            <div className="form-row form-group">
              <Col>
                <div className="form-group">
                  <label htmlFor="userName">First Name</label>
                  <input
                    id="userName"
                    value={name}
                    type="text"
                    className="form-control bg-smoke"
                    required=""
                    autoComplete="off"
                    onChange={e => setName(e.target.value)}
                    readOnly={readOnly}
                  ></input>
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    id="lastName"
                    value={last}
                    type="text"
                    className="form-control bg-smoke"
                    required=""
                    autoComplete="off"
                    onChange={e => setLast(e.target.value)}
                    readOnly={readOnly}
                  ></input>
                </div>
              </Col>
            </div>
            <div className="form-row">
              <Col className="form-group">
                <label htmlFor="userEmail">Email</label>
                <input
                  id="userEmail"
                  value={email}
                  type="text"
                  className="form-control bg-smoke"
                  required=""
                  autoComplete="off"
                  onChange={e => setEmail(e.target.value)}
                  readOnly={readOnly}
                ></input>
              </Col>
              <div className="form-group col-md-3">
                <label htmlFor="userLevel">Level</label>
                <select
                  id="userLevel"
                  className="form-control"
                  value={level}
                  onChange={e =>
                    setLevel(readOnly === false ? e.target.value : level)
                  }
                  readOnly={readOnly}
                >
                  <option value="Gold">Gold</option>
                  <option value="Silver">Silver</option>
                  <option value="Sampler">Sampler</option>
                  <option value="Admin">Admin</option>
                  <option value="Agent">Agent</option>
                </select>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="userStatus">Status</label>
                <select
                  id="userStatus"
                  className="form-control"
                  value={status}
                  onChange={e =>
                    setStatus(readOnly === false ? e.target.value : status)
                  }
                  readOnly={readOnly}
                >
                  <option value="New">New</option>
                  <option value="Active">Active</option>
                  <option value="Suspended">Suspended</option>
                </select>
              </div>
            </div>

            <Row>
              {readOnly === false && (
                <Col>
                  <button className="btn btn-success btn-block text-uppercase">
                    Save
                  </button>
                </Col>
              )}
            </Row>
          </form>
        </ModalBody>
        <ModalFooter>
          <p className="mb-1">
            <button onClick={() => toggle()}>Close</button>
          </p>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserModal;
