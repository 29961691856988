import React, { useState } from "react";

const RegistryContext = React.createContext([{}, () => {}]);

const RegistryProvider = props => {
  const [state, setState] = useState({});
  return (
    <RegistryContext.Provider value={[state, setState]}>
      {props.children}
    </RegistryContext.Provider>
  );
};

export { RegistryContext, RegistryProvider };
