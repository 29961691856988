import React, { useState, useEffect } from "react";
import DashMessage from "../components/DashMessage";
import { getCurrentUser } from "../services/authService";
import cookie from "react-cookies";
import AdminControlPanel from "../adminComponents/AdminControlPanel";

const Dashboard = () => {
  const [user, setUser] = useState({
    last: "",
    email: "",
    level: "",
    name: ""
  });
  useEffect(() => {
    setUser(getCurrentUser());
    cookie.save("_usrobj", user, { path: "/" });
    // eslint-disable-next-line
  }, []);

  return (
    <section className="py-9 fade-in">
      <div className="container">
        {user.last !== "" && (
          <DashMessage
            lastname={`${user.last}`}
            message={"Welcome to Global Concepts"}
          />
        )}
        {user.isAdmin && <AdminControlPanel />}
      </div>
    </section>
  );
};

export default Dashboard;
