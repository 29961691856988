import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
//import DashMessage from "./DashMessage";
import { getUserFromDB } from "../services/authService";
import { sendBookingRequest } from "../services/userService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const InqueryModal = ({
  modal,
  toggle,
  inqueryStartDate,
  inqueryName,
  inqueryInventoryId,
  inqueryPrice,
  inqueryLevel,
  inqueryType,
  inquerySource
}) => {
  const [user, setUser] = useState({});
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();

  useEffect(() => {
    getUserFromDB().then(result => {
      setUser(result.data);
    });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    console.log(nameRef);

    const request = {
      request: {
        name: nameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        message: messageRef.current.value,
        ref: {
          startDate: inqueryStartDate,
          name: inqueryName,
          inventoryId: inqueryInventoryId,
          price: inqueryPrice,
          level: inqueryLevel,
          type: inqueryType,
          source: inquerySource
        }
      },
      user
    };
    console.log(request);
    sendBookingRequest(request).then(response => {
      toast.success(`${response}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      console.log("good");
      toggle();
    });
  }

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={() => toggle()}
        className={"modal-dialog modal-sm modal-dialog-centered"}
      >
        <ModalHeader toggle={() => toggle()}>
          <p className="modal-title text-uppercase font-weight-bold">
            Inquiry about a reservation
          </p>
        </ModalHeader>
        <ModalBody>
          <form className="" action="" method="post">
            <div className="form-group">
              <input
                type="text"
                className="form-control bg-smoke"
                required=""
                placeholder={`${user.name} ${user.last}`}
                ref={nameRef}
              ></input>
            </div>

            <div className="form-group">
              <input
                type="email"
                className="form-control bg-smoke"
                required=""
                placeholder={`${user.email}`}
                ref={emailRef}
              ></input>
            </div>

            <div className="form-group">
              <input
                type="number"
                className="form-control bg-smoke"
                required=""
                placeholder="Phone Number"
                ref={phoneRef}
              ></input>
            </div>

            <div className="form-group">
              <textarea
                className="form-control bg-smoke"
                rows="6"
                placeholder="Message"
                ref={messageRef}
                //value={`I would like to make a reservation...`}
              ></textarea>
            </div>

            <button
              type="submit"
              onClick={e => handleSubmit(e)}
              className="btn btn-primary text-uppercase"
            >
              Submit
            </button>
          </form>
        </ModalBody>
        <ModalFooter>
          <p className="mb-1">
            Don’t have an Account? <Link to="/contact">Sign up</Link>
          </p>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InqueryModal;
