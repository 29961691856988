import React from "react";
import { Link } from "react-router-dom";

const RegistryPageAd = props => {
  const { onHotLink } = props;

  return (
    <div className={props.className}>
      <div className="bg-dark-light media media-large shadow-lg {}">
        <div className="row no-gutters align-items-center">
          <div className="col-md-6 overflow-hidden">
            <a href="/registry">
              <img
                src="/assets/img/registry/destination.jpg"
                alt="destination"
              ></img>
            </a>
          </div>

          <div className="col-md-6">
            <div className="media-body">
              <h3 className="text-uppercase text-white font-weight-bold mb-xl-5">
                Choose <br />
                Your Destination
              </h3>
              <p className="mb-xl-5">
                Use the search bar above to search thousands of locations
                worldwide or click the button bellow to make a special request.
              </p>

              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <ul className="list-unstyled mb-xl-5">
                    <li className="media align-items-center mb-xl-2">
                      <div className="mr-2">
                        <i className="fa fa-minus"></i>
                      </div>
                      <div className="media-body">Asia</div>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Indonesia")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Indonesia</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Malaysia")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Malaysia</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Thailand")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Thailand</div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-6 col-lg-4">
                  <ul className="list-unstyled mb-xl-5">
                    <li className="media align-items-center mb-xl-2">
                      <div className="mr-2">
                        <i className="fa fa-minus"></i>
                      </div>
                      <div className="media-body">Europe</div>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Italy")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Italy</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("France")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">France</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Greece")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Greece</div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-6 col-lg-4">
                  <ul className="list-unstyled mb-xl-5">
                    <li className="media align-items-center mb-xl-2">
                      <div className="mr-2">
                        <i className="fa fa-minus"></i>
                      </div>
                      <div className="media-body">America</div>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("United States", "Hawaii")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Hawaii</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("United States", "New York")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">New York</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("United States", "Florida")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Florida</div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-6 col-lg-4">
                  <ul className="list-unstyled mb-xl-5">
                    <li className="media align-items-center mb-xl-2">
                      <div className="mr-2">
                        <i className="fa fa-minus"></i>
                      </div>
                      <div className="media-body">Central</div>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Costa Rica")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Costa Rica</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Panama")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Panama</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Colombia")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Colombia</div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-6 col-lg-4">
                  <ul className="list-unstyled mb-xl-5">
                    <li className="media align-items-center mb-xl-2">
                      <div className="mr-2">
                        <i className="fa fa-minus"></i>
                      </div>
                      <div className="media-body">Australia</div>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Australia", "Queensland")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Queensland</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Australia", "Victoria")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Victoria</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() =>
                          onHotLink("Australia", "Western Australia")
                        }
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Western</div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-6 col-lg-4">
                  <ul className="list-unstyled mb-xl-5">
                    <li className="media align-items-center mb-xl-2">
                      <div className="mr-2">
                        <i className="fa fa-minus"></i>
                      </div>
                      <div className="media-body">Caribbean</div>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Jamaica")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Jamaica</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Saint Lucia")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Saint Lucia</div>
                      </a>
                    </li>

                    <li className="">
                      <a
                        onClick={() => onHotLink("Aruba")}
                        className="media d-inline-flex align-items-center"
                      >
                        <i className="fa fa-square mr-2"></i>
                        <div className="media-body">Aruba</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="text-uppercase">
                <Link to={() => "/contact"}>
                  <a className="btn btn-sm btn-outline-white">
                    Special Request
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistryPageAd;
