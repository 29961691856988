import React from "react";
import { Switch, Route } from "react-router-dom";
//import { pageLogger } from "../services/logService";
import AdminHeader from "./AdminHeader";
import UserProfile from "../views/UserProfile";
import Dashboard from "../views/Dashboard";
//import UserBooking from "../views/UserBooking";
import UserSettings from "../views/UserSettings";
import AdminSettings from "../views/AdminSettings";
//import UserTable from "./UserTable";
import ClientTable from "./ClientTable";
import UserBookingMain from "../adminComponents/UserBookingDash/UserBookingMain";
import MailBox from "../userComponents/MailBox";

const AdminDashboard = ({
  notifyMenu,
  handleNotifyMenu,
  userMenu,
  handleUserMenu,
  handleDropDownMenu,
  setBookingType,
  bookingType
}) => {
  //const { pathname: path } = useLocation();
  //User Tracking - Un-comment to track AdminDashboard
  /////pageLogger(path);

  return (
    <React.Fragment>
      <section className="py-2 mb-8"></section>
      <section className="py-2">
        <AdminHeader
          notifyMenu={notifyMenu}
          handleNotifyMenu={handleNotifyMenu}
          userMenu={userMenu}
          handleUserMenu={handleUserMenu}
          handleDropDownMenu={handleDropDownMenu}
        />
        <div className="container">
          <Switch>
            <Switch>
              <Route path={`${"/dashboard"}/mail`}>
                <MailBox />
              </Route>
              <Route path={`${"/dashboard"}/users`}>
                <ClientTable />
              </Route>
              <Route path={`${"/dashboard"}/settings`}>
                <UserSettings />
              </Route>
              <Route path={`${"/dashboard"}/adminsettings`}>
                <AdminSettings />
              </Route>
              <Route path={`${"/dashboard"}/booking`}>
                <UserBookingMain
                  setBookingType={setBookingType}
                  bookingType={bookingType}
                  className="fade-in"
                />
              </Route>
              <Route path={`${"/dashboard"}/profile`}>
                <UserProfile />
              </Route>
              <Route path={"/dashboard"}>
                <Dashboard />
              </Route>
            </Switch>
          </Switch>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AdminDashboard;
