import React from "react";
import Benefits from "./Benefits";
//import GoogleMap from "../components/GoogleMap";
import GoogleMap from "../custom/GoogleMap";

const LocationMapTab = ({ resort, activeTab }) => {
  return (
    <div
      className="tab-pane"
      id="locationMap"
      role="tabpanel"
      aria-labelledby="locationMap-tab"
    >
      <div className="row mb-5">
        <div className="col-md-7 col-lg-8 animated fadeIn">
          <GoogleMap
            lat={parseFloat(resort.location.lattDegree)}
            lng={parseFloat(resort.location.longDegree)}
            apikey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          />
          <h3 className="text-uppercase mt-5 mb-5">Directions</h3>
          <p className="mb-5">{resort.directions}</p>
          <div className="mb-6 mb-md-0">
            <div className="row">
              <div className="col-lg-6">
                <ul className="list-unstyled mb-0"></ul>
              </div>

              <div className="col-lg-6">
                <ul className="list-unstyled mb-0"></ul>
              </div>
            </div>
          </div>
        </div>
        <Benefits resort={resort} />
      </div>
    </div>
  );
};

export default LocationMapTab;
