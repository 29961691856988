import React, { useRef, useState } from "react";
import PageTitle from "../components/PageTitle";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../services/authService";
import { sendContactRequest } from "../services/bookingService";

const ContactUsPage = () => {
  const [submitted, setSubmitted] = useState(false);
  const nameRef = useRef("");
  const emailRef = useRef("");
  const phoneRef = useRef("");
  const messageRef = useRef("");

  window.scrollTo(0, 0);

  const handleSubmit = event => {
    event.preventDefault();
    const currentUser = getCurrentUser();
    //FINISH THIS
    const contactData = {
      user: currentUser._id,
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      message: messageRef.current.value
    };

    if (contactData.user !== "") {
      sendContactRequest(contactData).then(contactResult => {
        if (contactResult === "Message Submitted") setSubmitted(true);
        else console.log("error");
      });
    }
  };

  return (
    <React.Fragment>
      <PageTitle
        title={"Contact Us"}
        titleImg={"/assets/img/pages/page-title-bg7.jpg"}
      />
      <section className="py-9 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5 col-xl-4">
              <div className="contact-info">
                <h2 className="text-uppercase font-weight-bold">
                  get in touch
                </h2>
                <p className="mb-5">Feel free to reach out to us </p>
              </div>

              <div className="media mb-4">
                <Link
                  to="/contact"
                  className="icon-contact-default bg-primary text-white mr-5 shadow-lg"
                >
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                </Link>

                <div className="media-body">
                  <h4 className="text-uppercase font-weight-medium">Address</h4>
                  <p className="mb-0">503 E Ramsey Rd San Antonio, TX 78216.</p>
                </div>
              </div>

              <div className="media mb-4">
                <Link
                  to="/contact"
                  className="icon-contact-default bg-primary text-white mr-5 shadow-lg"
                >
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </Link>

                <div className="media-body">
                  <h4 className="text-uppercase font-weight-medium">
                    Phone number
                  </h4>
                  <div className="">
                    <a
                      href="tel:+2104374966"
                      className="text-gray-color hover-text-primary"
                    >
                      210-437-4966
                    </a>
                  </div>

                  <div className="">
                    <a
                      href="tel:+2104374966"
                      className="text-gray-color hover-text-primary"
                    >
                      210-437-4966
                    </a>
                  </div>
                </div>
              </div>

              <div className="media mb-6 mb-md-0">
                <button className="icon-contact-default bg-primary text-white mr-5 shadow-lg">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </button>

                <div className="media-body">
                  <h4 className="text-uppercase font-weight-medium">
                    Email us
                  </h4>
                  <div className="">
                    <a
                      href="mailto:globalconcepts210@gmail.com"
                      className="text-gray-color hover-text-primary"
                    >
                      globalconcepts210@gmail.com
                    </a>
                  </div>

                  <div className="">
                    <a
                      href="mailto:globalconcepts211@gmail.com"
                      className="text-gray-color hover-text-primary"
                    >
                      globalconcepts211@gmail.com
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {submitted === false && (
              <div className="col-md-6 col-lg-7 col-xl-8">
                <form onSubmit={handleSubmit} className="form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control border-0 bg-smoke shadow-sm"
                      required={true}
                      placeholder="Your Name"
                      ref={nameRef}
                    ></input>
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control border-0 bg-smoke shadow-sm"
                      required={true}
                      placeholder="Your Email"
                      ref={emailRef}
                    ></input>
                  </div>

                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control border-0 bg-smoke shadow-sm"
                      required={true}
                      placeholder="Your Phone"
                      ref={phoneRef}
                    ></input>
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control border-0 bg-smoke shadow-sm"
                      rows="6"
                      placeholder="Your Message"
                      ref={messageRef}
                    ></textarea>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary text-uppercase shadow-lg"
                    >
                      send message
                    </button>
                  </div>
                </form>
              </div>
            )}
            {submitted === true && (
              <div className="col-md-6 col-lg-7 col-xl-8">
                <h1>Submitted</h1>
              </div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactUsPage;
