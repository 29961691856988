import React from "react";
import { Col, CustomInput } from "reactstrap";
//import { SingleDatePicker } from "react-dates";
//import moment from "moment";
import LazyHero from "react-lazy-hero";

const VipSearchHeader = ({
  setCountry,
  setRegion,
  setState,
  setCity,
  setResort,
  setSleeps,
  updateVipRequest,
  country,
  region,
  state,
  city,
  resort,
  sleeps,
  countryRef,
  regionRef,
  stateRef,
  cityRef,
  resortRef,
  sleepsRef,

  stateReadOnly,
  cityReadOnly,
  resortReadOnly,
  countries,
  regions,
  states,
  cities,
  resorts,
  unitSizes
}) => {
  const regionsList = regions.map(region => {
    if (region === null) return <option value={"other"}>{"Other"}</option>;
    return (
      <option key={region.$id} datatext={region.description} value={region.id}>
        {region.description}
      </option>
    );
  });
  const countriesList = countries.map(country => {
    if (country === null) return <option value={"other"}>{"Other"}</option>;
    return (
      <option
        key={country.$id}
        datatext={country.description}
        value={country.id}
      >
        {country.description}
      </option>
    );
  });
  const statesList = states.map(state => {
    if (state === null) return <option value={"other"}>{"Other"}</option>;
    return (
      <option key={state.$id} datatext={state.description} value={state.id}>
        {state.description}
      </option>
    );
  });
  const citiesList = cities.map(city => {
    if (city === null) return <option value={"other"}>{"Other"}</option>;
    return (
      <option key={city.$id} datatext={city.description} value={city.id}>
        {city.description}
      </option>
    );
  });
  const resortsList = resorts.map(resort => {
    if (resort === null) return <option value={"other"}>{"Other"}</option>;
    return (
      <option key={resort.$id} datatext={resort.name} value={resort.id}>
        {resort.name}
      </option>
    );
  });
  const unitSizesList = unitSizes.map(unit => {
    if (unit === null) return <option value={"other"}>{"Other"}</option>;
    return (
      <option key={unit.$id} datatext={unit.description} value={unit.id}>
        {unit.description}
      </option>
    );
  });
  return (
    <section>
      <LazyHero
        imageSrc="/assets/img/home-city/amazing-hotel-bg.jpg"
        color="#000"
        opacity={0.7}
        isCentered={true}
        transitionDuration={600}
        parallaxOffset={100}
        minHeight="450px"
      >
        {" "}
        <div className="container paralax-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="text-center mb-7">
                <h2 className="text-uppercase text-white font-weight-bold mb-2">
                  Search Compass VIP Destinations
                </h2>
                <h4 className="text-white">
                  We’ve pre-picked the best resort specials for compass VIPs
                </h4>
              </div>

              <form method="GET" action="index.html">
                <div className="form-row justify-content-center">
                  <div className="col-lg-6">
                    <div className="form-row">
                      <div className="col-md-6">
                        <Col>
                          <CustomInput
                            id="country"
                            className="mb-2"
                            type="select"
                            onChange={e => setCountry(e.target.value)}
                            value={country}
                            placeholder={""}
                            style={{
                              backgroundColor: "#0000",
                              color: "#FFF"
                            }}
                            innerRef={countryRef}
                          >
                            <option value="">SEARCH BY COUNTRY</option>
                            {countriesList}
                          </CustomInput>
                          <center>
                            <h3 style={{ color: "#fff" }}>- OR -</h3>
                          </center>
                          <CustomInput
                            id="regio2"
                            className="mt-2"
                            type="select"
                            onChange={e => setRegion(e.target.value)}
                            value={region}
                            placeholder={""}
                            style={{
                              backgroundColor: "#0000",
                              color: "#FFF"
                            }}
                            innerRef={regionRef}
                          >
                            <option value={0}>SEARCH BY REGION</option>
                            {regionsList}
                          </CustomInput>
                        </Col>
                      </div>

                      <div className="col-md-6">
                        <Col>
                          <CustomInput
                            id="statesearch"
                            disabled={stateReadOnly}
                            className="mb-2"
                            type="select"
                            onChange={e => setState(e.target.value)}
                            value={state}
                            style={{
                              backgroundColor: "#0000",
                              color: "#FFF"
                            }}
                            innerRef={stateRef}
                          >
                            <option value={0}>-- STATE --</option>
                            {statesList}
                          </CustomInput>
                          <CustomInput
                            id="city"
                            disabled={cityReadOnly}
                            className="mb-2"
                            type="select"
                            onChange={e => setCity(e.target.value)}
                            value={city}
                            style={{
                              backgroundColor: "#0000",
                              color: "#FFF"
                            }}
                            innerRef={cityRef}
                          >
                            <option value={0}>-- CITY --</option>
                            {citiesList}
                          </CustomInput>
                        </Col>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div className="form-row">
                      <div className="col-md-6 col-lg-7">
                        <div className="form-group">
                          <Col>
                            <CustomInput
                              id="resort"
                              disabled={resortReadOnly}
                              className="mb-2"
                              type="select"
                              onChange={e => setResort(e.target.value)}
                              value={resort}
                              style={{
                                backgroundColor: "#0000",
                                color: "#FFF"
                              }}
                              innerRef={resortRef}
                            >
                              <option value={0}>-- RESORT --</option>
                              {resortsList}
                            </CustomInput>
                            <CustomInput
                              id="sleeps"
                              className="mb-2"
                              type="select"
                              onChange={e => setSleeps(e.target.value)}
                              value={sleeps}
                              style={{
                                backgroundColor: "#0000",
                                color: "#FFF"
                              }}
                              innerRef={sleepsRef}
                            >
                              <option value={0}>-- SIZE/SLEEPS --</option>
                              {unitSizesList}
                            </CustomInput>
                          </Col>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-5">
                        <div className="form-group">
                          <button
                            className="btn btn-primary btn-block text-uppercase"
                            type="button"
                            onClick={() => updateVipRequest()}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </LazyHero>
    </section>
  );
};

export default VipSearchHeader;
