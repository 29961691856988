import React, { Component } from "react";

import PageTitle from "../components/PageTitle";
import { getHotweek } from "../services/serverCallsService";
import WeatherWidget from "../components/WeatherWidget";

import PoiSection from "../components/PoiSection";
import ResortTabs from "../components/ResortTabs";
import ResortCarousel from "../components/ResortCarousel";

class HotweeksResorts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      activeIndex: 0,
      loaded: false,
      regweek: {
        resortName: "",
        img: ["/assets/img/registry/gallery-single-slider-03.jpg"],
        price: 0,
        recreationOnsite: [],
        recreationOffsite: [],
        amenitiesOnsite: [],
        amenitiesOffsite: [],
        airportInfo: "",
        checkIn: "",
        checkOut: "",
        checkInDates: [],
        specialAssistInfo: [],
        area: {
          region: "",
          country: ""
        },
        availableUnits: [],
        resortUnitInfo: "",
        areaInfo: "",
        directions: "",
        location: {
          lattDegree: "",
          longDegree: ""
        }
      }
    };
  }

  async componentDidMount() {
    const regweek = await getHotweek(this.props.match.params.id);

    this.setState({ regweek, loaded: true });
  }
  render() {
    //const { toggleInquery } = this.props;
    //const { resortName } = this.state.regweek;

    return (
      <div className="main-wrapper regweeks">
        <PageTitle
          title="Compass Weeks"
          titleImg="/assets/img/pages/page-title-bg6.jpg"
        ></PageTitle>

        <section className="py-10">
          <div className="container mb-5">
            <button
              className="page-link "
              onClick={() =>
                this.props.history.push(
                  `/compass?country=${this.state.regweek.area.country}`
                )
              }
            >
              <i
                className="fa fa-long-arrow-left d-none d-md-inline-block mr-md-1"
                aria-hidden="true"
              ></i>{" "}
              Back To Resort List
            </button>
          </div>
          <div className="container">
            <div className="mb-6">
              <div
                className={`row fade-in ${
                  this.state.regweek.img.length > 1 ? "show" : ""
                }`}
              >
                <ResortCarousel regweek={this.state.regweek} />

                <div className="col-lg-4">
                  <WeatherWidget
                    resort={this.state.regweek}
                    id={this.props.match.params.id}
                  />
                </div>
              </div>
            </div>
            {this.state.loaded === true && (
              <ResortTabs resort={this.state.regweek} isRegistry={false} />
            )}
            <PoiSection id={this.props.match.params.id} />
          </div>
        </section>
      </div>
    );
  }
}

export default HotweeksResorts;
