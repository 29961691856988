import React from "react";

const DashMessage = ({ lastname, message }) => {
  return (
    <div className="mb-7">
      <h2 className="text-uppercase font-size-22 font-weight-semibold">
        Hi {lastname} family, Welcome to{" "}
        <span className="text-primary"> Global Concepts!</span>
      </h2>
      <p>{message}</p>
    </div>
  );
};

export default DashMessage;
