import React, { useState } from "react";
import { Input } from "reactstrap";
import { passwordReset } from "../services/authService";
import { toast } from "react-toastify";

const PasswordResetForm = props => {
  const [email, setEmail] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const { data: response } = await passwordReset(email);
      await toast.success(`${response}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      props.history.replace("/");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(`Error: ${ex.response.data}`, {
          position: toast.POSITION.TOP_CENTER
        });
        setEmail("");
      }
    }
  }

  return (
    <div className="container login">
      <div className="row">
        <div className="col-lg-7 col-xl-3 align-items-center"></div>
        <div className="col-lg-7 col-xl-6 align-items-center">
          <div className="content">
            <div className="tab-heading">
              <h2 className="text-uppercase ">
                <span className="text-primary font-weight-bold ">Reset</span>{" "}
                Password
              </h2>
            </div>

            <div className="tab-features ">
              <div className="tab-content px-6 py-7 " id="myTabContent">
                <div
                  className="tab-pane fade show active "
                  id="tours"
                  role="tabpanel"
                  aria-labelledby="tours-tab"
                >
                  <div className="container login ">
                    <form className="" action="" method="post">
                      <div className="row">
                        <div className="col-md-12">
                          <label for="exampleInputText">Enter Email</label>
                          <div className="form-group form-group-icon form-group-icon-default mb-6 ">
                            <i className="fa fa-user" aria-hidden="true"></i>
                            <Input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Email"
                              autoComplete="email"
                              value={email}
                              onChange={e => {
                                setEmail(e.target.value);
                              }}
                            ></Input>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2">
                        <button
                          type="submit"
                          onClick={e => handleSubmit(e)}
                          className="btn btn-lg btn-primary text-uppercase shadow-lg"
                        >
                          Reset Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetForm;
