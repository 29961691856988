import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const UserBookingsHeading = ({ activeTab, setActiveTab }) => {
  //useEffect(() => {}, [activeTab]);
  return (
    <div className="mb-6 mt-2 ">
      <ul className="list-unstyled d-md-flex bg-smoke mb-0 p-5 px-lg-6 rounded">
        <li className="mb-4 mb-md-0 px-md-3">
          <Link
            onClick={() => setActiveTab("All Types")}
            className={`text-uppercase ${
              activeTab === "All Types" ? "text-primary" : "text-dark"
            } hover-text-primary`}
          >
            All Types
          </Link>
        </li>

        <li className="mb-4 mb-md-0 px-md-3">
          <Link
            onClick={() => setActiveTab("Compass")}
            className={`text-uppercase ${
              activeTab === "Compass" ? "text-primary" : "text-dark"
            } hover-text-primary`}
          >
            Compass
          </Link>
        </li>

        <li className="mb-4 mb-md-0 px-md-3">
          <Link
            onClick={() => setActiveTab("Registry")}
            className={`text-uppercase ${
              activeTab === "Registry" ? "text-primary" : "text-dark"
            } hover-text-primary`}
          >
            Registry
          </Link>
        </li>

        <li className="mb-4 mb-md-0 px-md-3">
          <Link
            onClick={() => setActiveTab("Cruises")}
            className={`text-uppercase ${
              activeTab === "Cruises" ? "text-primary" : "text-dark"
            } hover-text-primary`}
          >
            Cruises
          </Link>
        </li>

        <li className="mb-4 mb-md-0 px-md-3">
          <Link
            onClick={() => setActiveTab("Vip Collection")}
            className={`text-uppercase ${
              activeTab === "Vip Collection" ? "text-primary" : "text-dark"
            } hover-text-primary`}
          >
            Vip Collection
          </Link>
        </li>

        <li className="mb-4 mb-md-0 px-md-3">
          <Link
            onClick={() => setActiveTab("Airlines")}
            className={`text-uppercase ${
              activeTab === "Airlines" ? "text-primary" : "text-dark"
            } hover-text-primary`}
          >
            Airlines
          </Link>
        </li>

        <li className="px-md-3">
          <Link
            onClick={() => setActiveTab("Requests")}
            className={`text-uppercase ${
              activeTab === "Requests" ? "text-primary" : "text-dark"
            } hover-text-primary`}
          >
            Requests
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default UserBookingsHeading;
